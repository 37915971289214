<template>



  <!-- Menu -->
<div class="bg-white text-left h-screen w-screen grid grid-cols-10">
  <div class="h-screen grid grid-cols-12 col-span-4 print:hidden">

    <div class="flex col-span-2 h-24 border-b-2">
      <a href="./" 
          title="New Jersey Conservation Blueprint" 
          class="m-auto">
        <img class="h-20"
              alt="Conservation Blueprint logo" 
              :src="logo_svg">
      </a>
    </div>
    <div class="flex border-b-2 text-2xl font-bold text-neutral-700 text-left
                h-24 col-span-10 col-start-3">
      <h1 class="my-auto">Google Analytics</h1>
    </div>
    <div class="col-span-11 col-start-2 mx-1 my-5 ">
      <div class="ml-3">
        <button class="bg-blue-500 hover:bg-blue-700 text-white 
                      font-bold py-2 px-4 rounded"
                @click="getData"
                tabindex="0"
                v-show="!working">
          Get Report
        </button>
        <img class="ml-4 w-16"
            alt="Working" 
            :src="dots_gif"
            v-show="working">
      </div>
    </div>
    <div class="overflow-y-auto col-span-12 ml-5 pb-36 h-full relative">
      <div class="col-span-11 col-start-2 mx-1 ">
        <h2 class="text-xl underline mb-1.5">Date Range</h2>
        <div class="ml-3">
          <label for="start">Start: </label>
          <input type="date" id="start" min="2023-08-01"
                v-model="user_start"/>
        </div>
        <div class="mt-1.5 ml-3">
          <label for="start">End: </label>
          <input type="date" id="end" tabindex="1" 
                v-model="user_end"/>
        </div>
      </div>

      <div class="col-span-11 col-start-2 mx-1 mt-5">
        <input type="checkbox" id="user-group" 
                  @click="selectGroup"/> 
        <h2 class="text-xl underline my-2 ml-1.5 inline ">Users</h2>
          <a target="_blank" class="inline"
            href="https://support.google.com/analytics/answer/9143382?hl\
=en&ref_topic=11151952&sjid=10101236417840857166-NA#user-metrics">
            <button class="ml-1 w-4 h-4  bg-opacity-50  bg-gray-900
                          hover:bg-opacity-70 text-xs italic text-white 
                          text-center rounded-full cursor-pointer pb-1
                          align-top font-bold"
                    type="button" 
                    title="See more info on User">
              <span class="">i</span>
            </button>
          </a>

          <!-- Total Users -->
        <div class="ml-3 mt-3">
          <input type="checkbox" id="total_users" class="user-box"
                  v-model="show.total_users"/>
          <label for="total_users" class="ml-2">Total</label>
        </div>
          <!-- End Total User -->

          <!-- Active Users -->
        <div class="ml-3">
          <input type="checkbox" id="active_users" class="user-box" 
                v-model="show.active_users"/>
          <label for="active_users" class="ml-2">Active</label>
        </div>
          <!-- End active User -->

          <!-- Active One Day Users -->
        <div class="ml-3">
          <input type="checkbox" id="active_one_day_users" class="user-box" 
                v-model="show.active_one_day_users"/>
          <label for="active_one_day_users"
                  class="ml-2">1-Day Active</label>
        </div>
          <!-- Active One Day Users -->

          <!-- Active Seven Day Users -->
        <div class="ml-3">
          <input type="checkbox" id="active_seven_day_users" class="user-box" 
                v-model="show.active_seven_day_users"/>
          <label for="active_seven_day_users" 
                  class="ml-2">7-Day Active</label>
        </div>
          <!-- Active One Seven Users -->

          <!-- Active 28 Day Users -->
        <div class="ml-3">
          <input type="checkbox" id="active_28_day_users" class="user-box" 
                v-model="show.active_28_day_users"/>
          <label for="active_28_day_users"
                  class="ml-2">28-Day Active</label>
        </div>
          <!-- Active 28 Day Users -->

          <!-- Active One Day Seven Day Users-->
        <div class="ml-3">
          <input type="checkbox" id="one_day_seven_day_active_users"
                 class="user-box" 
                 v-model="show.one_day_seven_day_active_users"/>
          <label for="one_day_seven_day_active_users"
                  class="ml-2">1-Day/7 Day
            Active</label>
        </div>
          <!-- End Active One Day Seven Day Users -->

          <!-- Active One Day Monthly Users  -->
        <div class="ml-3">
          <input type="checkbox" id="one_day_monthly_active_users"
                 class="user-box" 
                 v-model="show.one_day_monthly_active_users"/>
          <label for="one_day_monthly_active_users"
                  class="ml-2">1-Day/Monthly
            Active</label>
        </div>
          <!-- End Active One Day Monthly Users -->

          <!-- Active Weekly Monthly Users - -->
        <div class="ml-3">
          <input type="checkbox" id="weekly_monthly_active_users" 
                 class="user-box" 
                 v-model="show.weekly_monthly_active_users"/>
          <label for="weekly_monthly_active_users"
                  class="ml-2">Weekly-Monthly 
            Active</label>
        </div>
          <!-- End Active Weekly Monthly Users -->

          <!-- New/Returning Users - -->
        <div class="ml-3">
          <input type="checkbox" id="new_returning_users" class="user-box" 
                v-model="show.new_returning_users"/>
          <label for="new_returning_users"
                  class="ml-2">New/Returning</label>
        </div>
          <!-- New/Returning Users -->

        <!-- Country Region City -->
        <div class="ml-3">
          <input type="checkbox" id="country_region_city" class="user-box" 
                v-model="show.country_region_city"/>
          <label for="country_region_city" class="ml-2">Country/Region/City
          </label>
        </div>
        <!-- Country Region City -->

        <!-- First User Source Medium -->
        <div class="ml-3">
          <input type="checkbox" id="first_user_source_medium" class="user-box" 
                v-model="show.first_user_source_medium"/>
          <label for="first_user_source_medium" class="ml-2">User Source</label>
        </div>
        <!-- First User Source Medium -->

          <!-- User Age Bracket - -->
        <!-- <div class="ml-3">
          <input type="checkbox" id="user_age_bracket" class="user-box" 
                v-model="show.user_age_bracket"/>
          <label for="user_age_bracket"
                  class="ml-2">User Age Bracket</label>
        </div> -->
          <!-- User Age Bracket -->

          <!-- User Gender - -->
        <!-- <div class="ml-3">
          <input type="checkbox" id="user_gender" class="user-box" 
                v-model="show.user_gender"/>
          <label for="user_gender" 
                  class="ml-2">User Gender</label>
        </div> -->
        <!-- User Gender -->

      </div> 

      <div class="col-span-11 col-start-2 mx-1 mt-5">
        <input type="checkbox" id="engagement-group" 
                  @click="selectGroup"/> 
        <h2 class="text-xl underline ml-1.5 my-2 inline ">Engagement</h2>
        <a target="_blank" class="inline"
        href="https://developers.google.com/analytics/devguides/reporting/data\
v1/api-schema#metrics">
          <button class="ml-1 w-4 h-4  bg-opacity-50 bg-gray-900
                          hover:bg-opacity-70 text-xs italic text-white 
                          text-center rounded-full cursor-pointer pb-1 pr-0.5
                          align-top font-bold"
                    type="button" 
                    title="See more info on User">
            <span class="">i</span>
          </button>
        </a>
      </div>

      <!-- Sessions -->
      <div class="ml-3">
      <input type="checkbox" id="sessions" class="engagement-box" 
            v-model="show.sessions"/>
      <label for="sessions" class="ml-2">Sessions</label>
      </div>
      <!-- Sessions -->                   

      <!-- Bounce Rate -->
      <div class="ml-3">
      <input type="checkbox" id="bounce_rate" class="engagement-box"  
            v-model="show.bounce_rate"/>
      <label for="bounce_rate" 
            class="ml-2">Bounce Rate</label>
      </div>
      <!-- Bounce Rate --> 

      <!-- Sessions Per User -->
      <div class="ml-3">
      <input type="checkbox" id="sessions_per_user" class="engagement-box"  
            v-model="show.sessions_per_user"/>
      <label for="sessions_per_user" 
            class="ml-2">Sessions Per User</label>
      </div>
      <!-- Sessions Per User -->                

      <!-- Engaged Sessions -->
      <div class="ml-3">
      <input type="checkbox" id="engaged_sessions" class="engagement-box"  
            v-model="show.engaged_sessions"/>
      <label for="engaged_sessions" 
            class="ml-2">Engaged Sessions</label>
      </div>
      <!-- Engaged Sessions -->              

      <!-- Engagement Rate -->
      <div class="ml-3">
      <input type="checkbox" id="engagement_rate" class="engagement-box"  
            v-model="show.engagement_rate"/>
      <label for="engagement_rate" 
            class="ml-2">Engagement Rate</label>
      </div>
      <!-- Engagement Rate -->                         

      <!-- User Engagement Duration -->
      <div class="ml-3">
      <input type="checkbox" id="user_engagement_duration" 
             class="engagement-box"  
             v-model="show.user_engagement_duration"/>
      <label for="user_engagement_duration" 
            class="ml-2">User Engagement Duration</label>
      </div>
      <!-- User Engagement Duration -->                    

      <!-- Average Session Duration -->
      <div class="ml-3">
      <input type="checkbox" id="average_session_duration" 
             class="engagement-box"  
             v-model="show.average_session_duration"/>
      <label for="average_session_duration" 
            class="ml-2">Average Session Duration</label>
      </div>
      <!-- Average Session Duration -->        

      <!-- Events Per Session -->
      <div class="ml-3">
      <input type="checkbox" id="events_per_session" class="engagement-box"
            v-model="show.events_per_session"/>
      <label for="events_per_session" 
            class="ml-2">Events Per Session</label>
      </div>
      <!-- Events Per Session -->

      <div class="col-span-11 col-start-2 mx-1 mt-5">
        <input type="checkbox" id="event-group" 
                  @click="selectGroup"/> 
        <h2 class="text-xl underline ml-1.5 my-2 inline ">Events</h2>
        <a target="_blank" class="inline"
        href="https://support.google.com/analytics/answer/9322688\
?hl=en#zippy=%2Crealtime-report%2Cdebugview-report">
          <button class="ml-1 w-4 h-4  bg-opacity-50 bg-gray-900
                          hover:bg-opacity-70 text-xs italic text-white 
                          text-center rounded-full cursor-pointer pb-1 pr-0.5
                          align-top font-bold"
                    type="button" 
                    title="See more info on User">
            <span class="">i</span>
          </button>
        </a>
      </div>                

      <!-- Models -->
      <div class="ml-3">
      <input type="checkbox" id="models" class="event-box"
            v-model="show.models"/>
      <label for="models" 
            class="ml-2">Model Selections</label>
      </div>
      <!-- Models -->       

      <!-- Layer Selection -->
      <div class="ml-3">
      <input type="checkbox" id="layers" class="event-box" 
            v-model="show.layers"/>
      <label for="layers" 
            class="ml-2">Layer Selections</label>
      </div>
      <!-- Layer Selection -->         

      <!-- Filter Selection -->
      <div class="ml-3">
      <input type="checkbox" id="filters" class="event-box" 
            v-model="show.filters"/>
      <label for="filters" 
            class="ml-2">Filter Selections</label>
      </div>
      <!-- Filter Selection -->        

      <!-- Reports Created -->
      <div class="ml-3">
      <input type="checkbox" id="pins" class="event-box" 
            v-model="show.pins"/>
      <label for="pins" 
            class="ml-2">Reports Created</label>
      </div>
      <!-- Reports Created -->         

      <!-- Parcel Searches -->
      <div class="ml-3">
      <input type="checkbox" id="parcel_search" class="event-box" 
            v-model="show.parcel_search"/>
      <label for="parcel_search" 
            class="ml-2">Parcel Searches</label>
      </div>
      <!-- Parcel Searches -->          

      <!-- Parcels Saved -->
      <div class="ml-3">
      <input type="checkbox" id="saved_parcels" class="event-box" 
            v-model="show.saved_parcels"/>
      <label for="saved_parcels" 
            class="ml-2">Parcels Saved</label>
      </div>
      <!-- Parcels Saved -->         

      <!-- Map Shares -->
      <div class="ml-3">
      <input type="checkbox" id="shares" class="event-box" 
            v-model="show.shares"/>
      <label for="shares" 
            class="ml-2">Map Shares</label>
      </div>
      <!-- Map Shares -->          

      <!-- Blueprint Original Clicks -->
      <div class="ml-3">
      <input type="checkbox" id="njmap_url" class="event-box" 
            v-model="show.njmap_url"/>
      <label for="njmap_url" 
            class="ml-2">Blueprint Original Clicks</label>
      </div>
      <!-- Blueprint Original Clicks --> 

      <!-- Tax Link Clicks -->
      <div class="ml-3">
      <input type="checkbox" id="tax_links" class="event-box" 
            v-model="show.tax_links"/>
      <label for="tax_links" 
            class="ml-2">Tax Link Clicks</label>
      </div>
      <!-- Tax Link Clicks -->   

      <!-- Settings Changes -->
      <div class="ml-3">
      <input type="checkbox" id="settings" class="event-box" 
            v-model="show.settings"/>
      <label for="settings" 
            class="ml-2">Settings Changes</label>
      </div>
      <!-- Settings Changes --> 

      <div class="col-span-11 col-start-2 mx-1 mt-5">
        <input type="checkbox" id="client-group" 
                  @click="selectGroup"/> 
        <h2 class="text-xl underline ml-1.5 my-2 inline ">Client</h2>
        <a target="_blank" class="inline"
        href="https://developers.google.com/analytics/devguides/reporting\
data/v1/api-schema">
          <button class="ml-1 w-4 h-4  bg-opacity-50 bg-gray-900
                          hover:bg-opacity-70 text-xs italic text-white 
                          text-center rounded-full cursor-pointer pb-1 pr-0.5
                          align-top font-bold"
                    type="button" 
                    title="See more info on User">
            <span class="">i</span>
          </button>
        </a>
      </div>   

      <!-- Browser -->
      <div class="ml-3">
      <input type="checkbox" id="browser" class="client-box" 
            v-model="show.browser"/>
      <label for="browser" 
            class="ml-2">Browser</label>
      </div>
      <!-- Browser --> 

      <!-- Device -->
      <div class="ml-3">
      <input type="checkbox" id="device" class="client-box" 
            v-model="show.device"/>
      <label for="device" 
            class="ml-2">Device</label>
      </div>
      <!-- Device --> 

      <!-- Operating System With Version -->
      <div class="ml-3">
      <input type="checkbox" id="operating_system_with_version" 
             class="client-box" 
             v-model="show.operating_system_with_version"/>
      <label for="operating_system_with_version" 
            class="ml-2">Operating System</label>
      </div>
      <!-- Operating System With Version  -->

      <!-- Screen Resolution -->
      <div class="ml-3">
      <input type="checkbox" id="screen_resolution" class="client-box" 
            v-model="show.screen_resolution"/>
      <label for="screen_resolution" 
            class="ml-2">Screen Resolution</label>
      </div>
      <!-- Screen Resolution --> 

      <!-- Mobile Device Marketing Name -->
      <div class="ml-3">
      <input type="checkbox" id="mobile_device_marketing_name" 
             class="client-box" 
             v-model="show.mobile_device_marketing_name"/>
      <label for="mobile_device_marketing_name" 
            class="ml-2">Mobile Device</label>
      </div>
      <!-- Mobile Device Marketing Name -->                                   

    </div>
  </div>
  <!-- Menu End -->

  <!-- Results -->
    
  <div class="h-screen grid grid-cols-12 col-span-6 border-l-2 
              print:col-span-12">

    <div class="flex text-2xl font-bold text-neutral-700 text-left h-24 
                print:h-0 col-span-12 border-b-2 print:hidden">
      <h1 class="my-auto pl-10 ">Report</h1>
      <img :src="print_svg" @click="print" v-show="results"
            class="my-auto ml-2 w-5 h-5">
    </div>
    <div class="flex col-span-2 h-0 print:h-24 mb-6 border-b-2 invisible 
                print:visible">
      <a href="./" 
          title="New Jersey Conservation Blueprint" 
          class="m-auto">
        <img class="h-20"
              alt="Conservation Blueprint logo" 
              :src="logo_svg">
      </a>
    </div>
    <div class="flex border-b-2 text-2xl font-bold text-neutral-700 text-left
                h-0 print:h-24 mb-6 col-span-10 col-start-3 invisible 
                print:visible">
      <h1 class="my-auto">Google Analyticse Report</h1>
    </div>
    <div class="max-h-screen pb-36 col-span-11 col-start-2 overflow-y-auto
                print:overflow-x-visible print:pb-0" 
          v-if="results">
      <!-- Dates -->
      <div class="col-span-10 col-start-2 mx-1 mb-5">
        <h2 class="text-lg">Date Range: {{ formatted_dates[0] }} to 
          {{ formatted_dates[1] }}</h2>
      </div>
      <!-- End Dates -->

      <!-- Total Users -->
      <div class="col-span-10 col-start-2 mx-1 my-2" 
           v-if="'total_users' in analytics">
        <h2 class="text-xl">Total Users: {{ analytics.total_users }}</h2>
        <p class="text-xs mx-2 my-2">The number of 
          <span class="font-bold italic">
          unique</span> visitors the site.</p>
      </div>
      <!-- End Total User -->

      <!-- Active Users -->
      <div class="col-span-10 col-start-2 mx-1 my-2" 
           v-if="'active_users' in analytics">
        <h2 class="text-xl">Active Users: {{ analytics.active_users}}</h2>
        <p class="text-xs mx-2 my-2">The number of 
          <span class="font-bold italic">
            distinct</span> visitors to the site.</p>
      </div>
      <!-- End Active User -->

      <!-- Active One Day Users -->
      <div class="col-span-10 col-start-2 mx-1 my-2"
           v-if="'active_one_day_users' in analytics">
        <h2 class="text-xl">1-Day Active Users:
          {{ analytics.active_one_day_users }}</h2>
        <p class="text-xs mx-2 my-2">The number of 
          <span class="font-bold italic">distinct</span> active users who
          visited on the last day of your date range. (Same as Active 
          Users).
        </p>
      </div>
      <!-- Active One Day Users -->

      <!-- Active Seven Day Users s -->
      <div class="col-span-10 col-start-2 mx-1 my-2" 
           v-if="'active_seven_day_users' in analytics">
        <h2 class="text-xl">7-Day Active Users:
          {{ analytics.active_seven_day_users }}</h2>
        <p class="text-xs mx-2 my-2">The number of 
          <span class="font-bold italic">distinct</span> active users who
          visited on the last seven days of your date range.</p>
      </div>
      <!-- Active Seven Day Users  -->

      <!-- Active 28 Day Users -->
      <div class="col-span-10 col-start-2 mx-1 my-2" 
           v-if="'active_28_day_users' in analytics">
        <h2 class="text-xl">28-Day Active Users:
          {{ analytics.active_28_day_users }}</h2>
        <p class="text-xs mx-2 my-2">The number of 
          <span class="font-bold italic">distinct</span> active users who 
          visited on the last 28 days of your date range.</p>
      </div>
      <!-- Active 28 Day Users -->

      <!-- Active One Day Seven Day Users -->
      <div class="col-span-10 col-start-2 mx-1 my-2" 
           v-if="'one_day_seven_day_active_users' in analytics">
        <h2 class="text-xl">1-Day/7-Day Active Users:
          {{ Math.round(10 * (100 * analytics
            .one_day_seven_day_active_users))/10 }}%</h2>
        <p class="text-xs mx-2 my-2">	The rolling percent of 7-day active
          users who are also 1-day active users. 
        </p>
      </div>
      <!-- Active One Day Seven Day Users -->

      <!-- Active One Day Monthly Users -->
      <div class="col-span-10 col-start-2 mx-1 my-2" 
           v-if="'one_day_monthly_active_users' in analytics">
        <h2 class="text-xl">1-Day/Monthly Active Users:
          {{ Math.round(10 * (100 * analytics
            .one_day_monthly_active_users))/10 }}%</h2>
        <p class="text-xs mx-2 my-2">The rolling percent of 30-day active 
          users who are also 1-day active users.</p>
      </div>
      <!-- Active One Day Monthly Users -->

      <!-- Active Weekly Monthly Users -->
      <div class="col-span-10 col-start-2 mx-1 my-2" 
           v-if="'weekly_monthly_active_users' in analytics">
        <h2 class="text-xl">Weekly/Monthly Active Users:
          {{ Math.round(10 * (100 * analytics
            .weekly_monthly_active_users))/10 }}%</h2>
        <p class="text-xs mx-2 my-2">The rolling percent of 30-day active 
          users who are also 7-day active users. </p>
      </div>
      <!-- Active Weekly Monthly Users -->

      <!-- New/Returning Users -->
      <div class="col-span-10 col-start-2 mx-1 my-2" 
            v-if="'new_returning_users' in this.analytics">
        <h2 class="text-xl mb-3">New/Returning Users:</h2>
        <p class="ml-3">Total: {{ isNaN(new_returning_user_totals[0])?
                                 0:new_returning_user_totals[0]+ 
                                 isNaN(new_returning_user_totals[1])?
                                 0:new_returning_user_totals[1] }}</p>
        <p class="ml-3">New: {{ isNaN(new_returning_user_totals[0])?
                                 0:new_returning_user_totals[0] }};</p>
        <p class="ml-3">Return: {{ isNaN(new_returning_user_totals[1])?
                                 0:new_returning_user_totals[1] }};</p>
        <table class="ml-2 mt-3">
          <tr>
            <th class="text-left font-normal">By Date:</th> 
            <th class="text-center font-normal pl-4">New</th> 
            <th class="text-center font-normal pl-2">Return</th>
            <th class="text-center font-normal pl-2">Total</th>
          </tr>
      <tr else v-for="(value, key) in this.analytics.new_returning_users">
            <td colspan=4 v-if="Object.keys(value)[0]=='No Data'">No Data</td>
            <td class="text-center pl-4" 
                v-if="Object.keys(value)[0]!='No Data'">
              {{ key }}</td>
            <td class="text-center pl-4" 
                v-if="Object.keys(value)[0]!='No Data'">
              {{ value[0] }}</td>
            <td class="text-center pl-2"
                v-if="Object.keys(value)[0]!='No Data'">
              {{ value[1] }} </td>
            <td class="text-center pl-2"
                v-if="Object.keys(value)[0]!='No Data'">{{parseInt(value[0])  + 
              parseInt(value[1]) }}</td>
          </tr>
        </table>
        <p class="text-xs mx-2 my-2">New users have 0 previous sessions,
          and returning users have 1 or more previous sessions </p>
      </div>
      <!-- New/Returning Users -->

      <!-- Country City Region -->
      <div class="col-span-10 col-start-2 mx-1" 
           v-if="'country_region_city' in analytics">
        <h2 class="text-xl">Country/City/Region</h2>
        <p class="text-xs mx-2 my-2">Count of users by location.</p>
        <ul id="country_region_city" class="text-base ml-3">
          <li v-for="(value, key) in analytics.country_region_city">
            <p v-for="(value, key) in value">
              <span v-if="value == 'No Data'">No Data</span>
              <span v-else>{{ key }}: {{ value }}</span>
            </p>
          </li>
        </ul>
      </div>
      <!-- Country City Region -->

      <!-- First User source Medium -->
      <div class="col-span-10 col-start-2 mx-1" 
           v-if="'first_user_source_medium' in analytics">
        <h2 class="text-xl">User Source</h2>
        <p class="text-xs mx-2 my-2">Source of users first visit.</p>
        <ul id="first_user_source_medium" class="text-base ml-3">
          <li v-for="(value, key) in analytics.first_user_source_medium">
            <p v-for="(value, key) in value">
              <span v-if="value == 'No Data'">No Data</span>
              <span v-else>{{ key }}: {{ value }}</span>
            </p>
          </li>
        </ul>
      </div>
      <!-- First User source Mediumn -->

      <!-- User Age Bracket -->
      <div class="col-span-10 col-start-2 mx-1" 
           v-if="'user_age_bracket' in analytics">
        <h2 class="text-xl">User Age Brackets</h2>
        <p class="text-xs mx-2 my-2">Count of users by age.</p>
        <ul id="user_age_bracket" class="text-base ml-3">
          <li v-for="(value, key) in analytics.user_age_bracket">
            <p v-for="(value, key) in value">
              <span v-if="value == 'No Data'">No Data</span>
              <span v-else>{{ key }}: {{ value }}</span>
            </p>
          </li>
        </ul>
      </div>
      <!-- User Age Bracket -->

      <!-- User Gender -->
      <div class="col-span-10 col-start-2 mx-1"
           v-if="'user_gender' in analytics">
        <h2 class="text-xl">User Gender</h2>
        <p class="text-xs mx-2 my-2">Count of users by gender.</p>
        <ul id="user_gender" class="text-base ml-3">
          <li v-for="(value, key) in analytics.user_gender">
            <p v-for="(value, key) in value">
              <span v-if="value == 'No Data'">No Data</span>
              <span v-else>{{ key }}: {{ value }}</span>
            </p>
          </li>
        </ul>
      </div>
      <!-- User Gender -->

      <!-- Sessions -->
      <div class="col-span-10 col-start-2 mx-1 my-2"
          v-if="'sessions' in analytics"> 
        <h2 class="text-xl">Sessions: {{ analytics.sessions }}</h2>
        <p class="text-xs mx-2 my-2">The number of sessions.</p>
      </div>
      <!-- Sessions -->

      <!-- Bounce Rate -->
      <div class="col-span-10 col-start-2 mx-1 my-2"
          v-if="'bounce_rate' in analytics"> 
        <h2 class="text-xl">Bounce Rate: 
          {{ Math.round(10 * 100 * analytics.bounce_rate)/10}}
        </h2>
        <p class="text-xs mx-2 my-2">The percentage of sessions that were not
          engaged.</p>
      </div>
      <!-- Bounce Rate -->

      <!-- Sessions Per User -->
      <div class="col-span-10 col-start-2 mx-1 my-2"
          v-if="'sessions_per_user' in analytics"> 
        <h2 class="text-xl">Sessions Per User: 
          {{ Math.round(10 * analytics.sessions_per_user)/10}}
        </h2>
        <p class="text-xs mx-2 my-2">The average number of sessions per user.</p>
      </div>
      <!-- Sessions Per User -->

      <!-- Engaged Sessions -->
      <div class="col-span-10 col-start-2 mx-1 my-2"
          v-if="'engaged_sessions' in analytics"> 
        <h2 class="text-xl">Engaged Sessions: 
          {{ Math.round(10 * analytics.engaged_sessions)/10}}
        </h2>
        <p class="text-xs mx-2 my-2">The number of sessions that lasted longer
           than 10 seconds.</p>
      </div>
      <!-- Engaged Sessions -->

      <!-- Engagement Rate -->
      <div class="col-span-10 col-start-2 mx-1 my-2"
          v-if="'engagement_rate' in analytics"> 
        <h2 class="text-xl">Engagement Rate: 
          {{ Math.round(10 * 100 * analytics.engagement_rate)/10}}
        </h2>
        <p class="text-xs mx-2 my-2"> The percentage of engaged sessions.</p>
      </div>
      <!-- Engagement Rate --> 

      <!-- User Engagement Duration -->
      <div class="col-span-10 col-start-2 mx-1 my-2"
          v-if="'user_engagement_duration' in analytics"> 
        <h2 class="text-xl">User Engagement Duration: 
          {{ Math.round(10 * analytics.user_engagement_duration)/10}} secs.
        </h2>
        <p class="text-xs mx-2 my-2">The amount of time a user spent with the site
                                    in focus on their screen.</p>
      </div>
      <!-- User Engagement Duration -->

      <!-- Average Session Duration -->
      <div class="col-span-10 col-start-2 mx-1 my-2"
          v-if="'average_session_duration' in analytics"> 
        <h2 class="text-xl">Average Session Duration: 
          {{ Math.round(analytics.average_session_duration)}} secs.
        </h2>
        <p class="text-xs mx-2 my-2">The average length of all sessions.</p>
      </div>
      <!-- Average Session Duration -->

      <!-- Events Per Session -->
      <div class="col-span-10 col-start-2 mx-1 my-2"
          v-if="'events_per_session' in analytics"> 
        <h2 class="text-xl">Events Per Session: 
          {{ Math.round(10 * analytics.events_per_session)/10}}
        </h2>
        <p class="text-xs mx-2 my-2">The average number of user interactions with 
          the site per session.</p>
      </div>
      <!-- Events Per Session --> 

      <!-- Model Selection -->
      <div class="col-span-10 col-start-2 mx-1" 
       v-if="'models' in analytics">
        <h2 class="text-xl">Model Selections</h2>
        <p class="text-xs mx-2 my-2">The number of times a model was viewed
          after the initial page load. (Note: The ecological model is on by  
          default when the page is first loaded.  Those views are not included
          in the count).</p>
        <ul id="models" class="text-base ml-3">
          <li v-for="model in analytics.models">
            <span v-for="(key, value) in model">{{ value }}: 
              {{ key }}</span>
          </li>
        </ul>
      </div>
      <!-- End Model Selection -->
      
      <!-- Layer Selection -->
      <div class="col-span-10 col-start-2 mx-1 mt-5" 
          v-if="'layers' in analytics">
        <h2 class="text-xl">Layer Selections</h2>
        <p class="text-xs mx-2 my-2">The number of times a layer was 
          selected.</p>
        <ul id="layers" class="text-base ml-3">
          <li v-for="layer in analytics.layers">
            <span v-for="(key, value) in layer">{{ formatLayer(value) }}: 
              {{ key }}</span>
          </li>
        </ul>
      </div>
      <!-- End Layer Selection -->
      
      <!-- Filter Selection -->
      <div class="col-span-10 col-start-2 mx-1 mt-5" 
           v-if="'filters' in analytics">
        <h2 class="text-xl">Filter Selections</h2>
        <p class="text-xs mx-2 my-2">The number of times a filter was 
          selected.</p>
        <ul id="filters" class="text-base ml-3">
          <li v-for="filter in analytics.filters">
            <span v-for="(key, value) in filter">{{ formatFilter(value) }}: 
              {{ key }}</span>
          </li>
        </ul>
      </div>
      <!-- End Filter Selection -->
      
      <!-- Reports Created -->
      <div class="col-span-10 col-start-2 mx-1 mt-5" 
           v-if="'pins' in analytics">
        <h2 class="text-xl">Reports Created</h2>
        <p class="text-xs mx-2 my-2">Type of report and pams pin(s) of parcel.
        </p>
        <div class="flex m-1"
             v-show="!('No Data' in analytics.pins)"
             v-for="pin in analytics.pins">
          <div class="flex-none w-1/4 border-b-2"  v-for="(key, value) in pin">
            {{ value.length == 1?'':value }}
          </div>
          <div class="flex-none w-1/2 border-b-2" v-for="(key, value) in pin">
            <span v-for="pin in key[0].split(',')">
              {{ pin }}<br>
              </span>
          </div>
        </div>
      </div>
      <!-- End Reports Created-->
      
      <!-- Parcel Searches -->
      <div class="col-span-10 col-start-2 mx-1 mt-5" 
           v-if="'parcel_search' in analytics">
        <h2 class="text-xl">Parcel Searches</h2>
        <p class="text-xs mx-2 my-2">Pams pins of searched parcels.</p>
        <ul id="parcel_search" class="text-base ml-3">
          <li v-for="parcel in analytics.parcel_search">
            <span v-for="(key, value) in parcel">{{ value }}</span>
          </li>
        </ul>
      </div>
      <!-- End Parcel Searches -->
      
      <!-- Parcels Saved -->
      <div class="col-span-10 col-start-2 mx-1 mt-5" 
           v-if="'saved_parcels' in analytics">
        <h2 class="text-xl">Parcels Saved</h2>
        <p class="text-xs mx-2 my-2">Pams pins of saved parcels.</p>
        <ul id="saved_parcels" class="text-base ml-3">
          <li v-for="parcel in analytics.saved_parcels">
            <span v-for="(key, value) in parcel">{{ value }}</span>
          </li>
        </ul>
      </div>
      <!-- End Parcels Saved -->
      
      <!-- Map Shares -->
      <div class="col-span-10 col-start-2 mx-1 mt-5" 
           v-if="'shares' in analytics">
        <h2 class="text-xl">Map Shares</h2>
        <p class="text-xs mx-2 my-2">The URL of shared maps.</p>
        <ul id="shares" class="text-sm text-blue-500 cursor-pointer ml-3">
          <li v-for="share in analytics.shares">
            <a v-for="(key, value) in share"
               v-bind:href='`${ value }`' target="_blank">{{ value }}</a>
          </li>
        </ul>
      </div>
      <!-- End Map Shares -->
      
      <!-- Blueprint Original Clicks -->
      <div class="col-span-10 col-start-2 mx-1 mt-5" 
           v-if="'njmap_url' in analytics">
        <h2 class="text-xl">Blueprint Original Clicks</h2>
        <p class="text-xs mx-2 my-2">The URL of followed Blueprint original 
          clicks.</p>
        <ul id="njmap_url" class="text-sm text-blue-500 cursor-pointer ml-3">
          <li v-for="url in Object.keys(analytics.njmap_url)">
            <a v-bind:href='`${ url }`' 
                target="_blank">{{ url=='0'?'No Data':url }}</a>
          </li>
        </ul>
      </div>
      <!-- Blueprint Original Clicks -->
      
      <!-- Tax Link Clicks -->
      <div class="col-span-10 col-start-2 mx-1 mt-5" 
           v-if="'tax_links' in analytics">
        <h2 class="text-xl">Tax Link Clicks</h2>
        <p class="text-xs mx-2 my-2">The URL of followed tax links.</p>
        <ul id="tax_links" class="text-sm text-blue-500 cursor-pointer  ml-3">
          <li v-for="obj in analytics.tax_links">
            <a v-for="(key, value) in obj" 
                v-bind:href='`${ value }`' target="_blank">{{ value }}</a>
          </li>
        </ul>
      </div>
      <!-- End Tax Link Clicks -->
      
      <!-- Settings Changes -->
      <div class="col-span-10 col-start-2 mx-1 mt-5" 
           v-if="'settings' in analytics">
        <h2 class="text-xl">Settings Changes</h2>
        <p class="text-xs mx-2 my-2">The number of times a setting was 
          selected.</p>
        <ul id="settings" class="text-base ml-3">
          <li v-for="setting in analytics.settings">
            <span v-for="(key, value) in setting">{{ value }}: 
              {{ key }}</span>
          </li>
        </ul>
      </div>
      <!-- End Settings Changes -->
      
      <!-- Browser -->
      <div class="col-span-10 col-start-2 mx-1 mt-5" 
           v-if="'browser' in analytics">
        <h2 class="text-xl">Browser</h2>
        <p class="text-xs mx-2 my-2">Session Count</p>
        <ul id="browser" class="text-base ml-3">
          <li v-for="browser in analytics.browser">
            <span v-for="(key, value) in browser">{{ value }}: 
              {{ key }}</span>
          </li>
        </ul>
      </div>
      <!-- Browsers -->
      
      <!-- Operating System With Version -->
      <div class="col-span-10 col-start-2 mx-1 mt-5" 
           v-if="'operating_system_with_version' in analytics">
        <h2 class="text-xl">Operating System</h2>
        <p class="text-xs mx-2 my-2">Session Count</p>
        <ul id="operating_system_with_version" class="text-base ml-3">
          <li v-for="os in analytics.operating_system_with_version">
            <span v-for="(key, value) in os">{{ value }}: 
              {{ key }}</span>
          </li>
        </ul>
      </div>
      <!-- Operating System With Version -->
      
      <!-- Device -->
      <div class="col-span-10 col-start-2 mx-1 mt-5" 
           v-if="'device' in analytics">
        <h2 class="text-xl">Device</h2>
        <p class="text-xs mx-2 my-2">Session Count</p>
        <ul id="device" class="text-base ml-3">
          <li v-for="item in analytics.device">
            <span v-for="(key, value) in item">{{ value }}: 
              {{ key }}</span>
          </li>
        </ul>
      </div>
      <!-- Device -->
      
      <!-- Mobile Device Marketing Name -->
      <div class="col-span-10 col-start-2 mx-1 mt-5" 
           v-if="'mobile_device_marketing_name' in analytics">
        <h2 class="text-xl">Mobile Device</h2>
        <p class="text-xs mx-2 my-2">Session Count</p>
        <ul id="mobile_device_marketing_name" class="text-base ml-3">
          <li v-for="item in analytics.mobile_device_marketing_name">
            <span v-for="(key, value) in item">{{ value }}: 
              {{ key }}</span>
          </li>
        </ul>
      </div>
      <!-- Mobile Device Marketing Name -->
      
      <!-- Screen Resolution -->
      <div class="col-span-10 col-start-2 mx-1 mt-5" 
           v-if="'screen_resolution' in analytics">
        <h2 class="text-xl">Screen Resolution</h2>
        <p class="text-xs mx-2 my-2">Session Count</p>
        <ul id="screen_resolution" class="text-base ml-3">
          <li v-for="item in analytics.screen_resolution">
            <span v-for="(key, value) in item">{{ value }}: 
              {{ key }}</span>
          </li>
        </ul>
      </div>
      <!-- Device -->

    </div>
  </div>
    <div class="col-span-2 col-start-10 h-8 bg-slate-100 sticky bottom-0">
      <button class="border-0 pt-0.5 bg-slate-100 rounded-md w-48 
                     cursor-pointer float-right transition-colors 
                     text-slate-600 hover:bg-slate-200"
              type="submit"
              @click="handleLogout"
        >Logout
      </button>
    </div>
</div>
  <!-- Results End -->
<!-- Medium End -->

</template>

<script>
  
  /**
   * Component for creating Google Analytic Reports
   * 
   * @author Mark E. Corbalis <markcorbalis@gmail.com>
   * @copyright Mark E. Corbalis 2023
   * @version 1.1.0.1
   * @displayName Bluepint+ Google Analytic Reports
   */

   import axios from 'axios';
   import logo_svg 
                           from "../assets/img/logos/blueprint_logo.png"
   import dots_gif 
                           from "../assets/img/misc/dots.gif"
   import print_svg from "../assets/img/icons/print.svg"

   export default {

      name: 'GoogleAnalytics',

      data() {
        return {

          analytics: {},
          dots_gif,
          logo_svg,
          print_svg,
          show: {
            "menu": true,
            "total_users": false,
            "active_users": false,
            "active_one_day_users": false,
            "active_seven_day_users": false,
            "active_28_day_users": false,
            "one_day_seven_day_active_users": false,
            "one_day_monthly_active_users": false,
            "weekly_monthly_active_users": false,
            "new_returning_users": false,
            "first_user_source_medium": false,
            "country_region_city": false,
            "user_age_bracket": false,
            "user_gender": false,
            "bounce_rate": false,
            "sessions": false,
            "sessions_per_user": false,
            "average_session_duration": false,
            "engaged_sessions": false,
            "engagement_rate": false,
            "user_engagement_duration": false,
            "events_per_session": false,
            "device": false,
            "mobile_device_marketing_name": false,
            "operating_system_with_version": false,
            "screen_resolution": false,
            "browser": false,
            "models": false,
            "layers": false,
            "filters": false,
            "pins": false,
            "parcel_search": false,
            "saved_parcels": false,
            "shares": false,
            "njmap_url": false,
            "tax_links": false,
            "settings": false
          },
          user_start: null,
          user_end: null,
          working: false
        }
      },

      computed: {

        end: function(){
          const date = new Date();
          const year = new Intl.DateTimeFormat('en', { year: 'numeric' })
                            .format(date);
          const month = new Intl.DateTimeFormat('en', { month: '2-digit' })
                            .format(date);
          const day = new Intl.DateTimeFormat('en', { day: '2-digit' })
                            .format(date);
          
          return`${year}-${month}-${day}`;
        },
        
        formatted_dates: function(){
          let start = this.user_start?this.user_start:this.start;
          let end = this.user_end?this.user_end:this.end;
          
          start = new Date(start);
          end = new Date(end);
          
          start.setDate(start.getDate() + 1);
          end.setDate(end.getDate() + 1);
        
          return [start.toLocaleDateString(), end.toLocaleDateString()];
        },

        new_returning_user_totals: function(){

          if (!this.results){ return; }
          if (!('new_returning_users' in this.analytics)){ return; }

          let totals = [0,0]

          for (const [key, value] of 
                      Object.entries(this.analytics.new_returning_users)) {
            totals[0] += value[0];
            totals[1] += value[1];
          }

          return totals
        },

        parameter_string: function(){
          let str = '';

          for (const [key, value] of Object.entries(this.show)) {
            if(value)
              str += `&${key}=${value}`;
          }
          return str;
        }, 

        results: function(){
          return Object.keys(this.analytics).length > 0;
        },

        start: function () {
          let date = new Date();

          if(date.getDate() == 1){
            date.setMonth(date.getMonth(), -1);
          }
          const first = new Date(date.getFullYear(), date.getMonth(), 1  );
          const year = new Intl.DateTimeFormat('en', { year: 'numeric' })
                            .format(first);
          const month = new Intl.DateTimeFormat('en', { month: '2-digit' })
                            .format(first);
          const day = new Intl.DateTimeFormat('en', { day: '2-digit' })
                            .format(first);
          
          return`${year}-${month}-${day}`;
        }
      },

      methods:{

        formatLayer(str) {
            str = str.toLowerCase().split('-');
            for (var i = 0; i < str.length; i++) {
              str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1); 
            }
            return str.join(' ');
        },

        formatFilter(str) {
            str = str.toLowerCase().split(' ');
            for (var i = 0; i < str.length; i++) {
              str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1); 
            }
            return str.join(' ');
        },

        getData(){
          const start = this.user_start?this.user_start:this.start;
          const end = this.user_end?this.user_end:this.end;

          this.working = true;

          let url = '';
          url += 'https://'; 
          url += '/7xhbzqwc4e5qopoothlafjgopi0kdjae';
          url += '.lambda-url.us-east-1.on.aws/';
          url += `?start=${start}&end=${end}`
          url += `${this.parameter_string}`;

          axios
          .post(url)
          .then(response => {
            this.show.menu = false;
              this.analytics = response.data;
          })
          .catch(error => {
              this.show.menu = true;
              this.analytics = '';
          })
          .then(() => {
            this.working = false;
              // this.pushToAnalytics('share',
              //   {'share_url': this.short_link});        
          });
        },

        handleLogout(){
        return this.$auth0.logout({ logoutParams: 
                                    { returnTo: window.location.origin } });
        },

        print(){
          window.print();
        },

        restoreMenu(){
          this.results = false;
          this.show.menu = true;
          this.analytics = '';
        },

        selectGroup($event){
          const target_class = $event.target.id.replace('group', 'box');
          const els = document.getElementsByClassName(target_class);

          for(const el of els){
            el.click();
          }
        }
      },

      mounted(){
        this.user_start = this.start;
        this.user_end = this.end;
      },
   }
   
   </script>
   