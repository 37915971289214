<template>

  <button class="border-2 border-blue-700 py-1 px-4 ml-2 bg-white
          text-blue-600 rounded mr-2" 
          aria-disabled="false" 
          role="button" 
          tabindex="0"
          id="report_download-csv"
          v-show="link">
  </button>

</template>

<script>

    export default {
        name: 'ParcelReportCSV',

        props: ['multi_feature'],

        data() {
            return {
                link: '',
                report_data: {} 
            }
        },

        methods: {

            buildCsv(){

              const std_headers = ["feature_id", "type",
                              "eco_score","ag_score", "wa_score", "acreage"];
              const  modiv_headers = ['address', 'assessed_value', 'assessment', 
                  'description', 'owner', 'owner_address_1', 'owner_address_2', 
                  'property_class', 'taxes', 'town', 'zoning'];
              let rec = [];
              let headers = std_headers.slice();
              let data = {};

              const length = 
                      this.multi_feature?Object.keys(this.report_data).length:1;

              if(length > 1){
                 data = this.report_data;
              }else{
                data['item'] = this.report_data;
              }
              
              let i = 0;
              for (const value of Object.values(data)) {
                  rec[i] = [];
                  for (const header of std_headers){
                    rec[i].push(value[header]);
                  }

                  if(value["parcel_count"] > 0){
                    if(i == 0){
                    headers.push("parcel_count");
                    }
                    rec[i].push(value["parcel_count"]);
                  }

                  if(value["parcel_acreage"] > 0){
                    if(i == 0){
                      headers.push("parcel_acreage");
                    }
                    rec[i].push(value["parcel_acreage"]);
                  }

                  if(value["open_space_parcel_count"] > 0){
                    if(i == 0){
                      headers.push("open_space_parcel_count");
                    }
                    rec[i].push(value["open_space_parcel_count"]);
                  }

                  if(value["muni"] > 0){
                    if(i == 0){
                      headers.push("muni");
                    }
                    rec[i].push(value["muni"]);
                  }

                  if(value["block"] > 0){
                    if(i == 0){
                      headers.push("block");
                    }
                    rec[i].push(value["block"]);
                  }

                  if(value["lot"] > 0){
                    if(i == 0){
                      headers.push("lot");
                    }
                    rec[i].push(value["lot"]);
                  }

                  if(value["qualifier"] > 0){
                    if(i == 0){
                      headers.push("qualifier");
                    }
                    rec[i].push(value["qualifier"]);
                  }

                  if(value.hasOwnProperty('modiv')){
                    if(i == 0){
                      headers.push(modiv_headers);
                    }
                    const modiv = value.modiv;
                    const has_data = Object.keys(modiv).length > 1?true:false;

                    for(const header of modiv_headers){
                      if(has_data){
                        rec[i].push(String(modiv[header]).includes(',')?
                                          '"' + modiv[header] +'"':modiv[header]
                        );
                      }else{
                        rec[i].push('N/A');
                      }
                    }
                  }

                  if(value.hasOwnProperty('response_data')){
                    const blueprint = 
                            Object.assign({}, value.response_data);

                    if(blueprint.hasOwnProperty('eco')){
                        const eco = Object.assign({}, blueprint.eco);
                        for (const [key, value] of Object.entries(eco)){
                          if(i == 0){
                            headers.push(key);
                          }
                          rec[i].push(String(value).includes(',')?
                                                    '"' + value +'"':value
                          );
                        }
                    }

                    if(blueprint.hasOwnProperty('ag')){
                        const ag = Object.assign({}, blueprint.ag);
                        for (const [key, value] of Object.entries(ag)){
                          if(i == 0){
                            headers.push(key);
                          }
                          rec[i].push(String(value).includes(',')?
                                                    '"' + value +'"':value
                          );
                        }
                    }

                    if(blueprint.hasOwnProperty('land_use')){
                        const lu = blueprint.land_use.slice();
                        for (const use of lu){
                          if(i == 0){
                            headers.push(
                              use.type15[0].toUpperCase() + 
                              use.type15.substr(1).toLowerCase() +
                              ' %');
                          }
                          rec[i].push(Math.round(use.pct * 10 / 10, 1)
                            .toFixed(1));                            
                        }
                    }

                    if(blueprint.hasOwnProperty('watersheds')){
                        const sheds = blueprint.watersheds.slice();
                        for (const shed of sheds){
                          if(i == 0){
                            headers.push(
                              'Watershed: ' + shed.name +' %');
                          }
                          rec[i].push(Math.round(shed.pct * 10 / 10, 1)
                          .toFixed(1));                            
                        }
                    }

                    if(blueprint.hasOwnProperty('sewers')){
                        const sewers = blueprint.sewers.slice();
                        for (const sewer of sewers){
                          if(i == 0){
                            headers.push(
                              'Sewer: ' + sewer.name + ' (' + sewer.permit +
                              ') %');
                          }
                          rec[i].push(Math.round(sewer.pct * 10 / 10, 1)
                          .toFixed(1));                            
                        }
                    }
                  }
                i++;
              }

              const csvData = []
              csvData.push(headers);
              for(const item of rec){
                csvData.push(item);
              }

              let csvContent = ''
              csvData.forEach(row => {
                csvContent += row.join(',') + '\n'
              })

              const blob = new Blob([csvContent], 
                                          { type: 'text/csv;charset=utf-8,' });
              const url = URL.createObjectURL(blob);

              this.link = document.createElement('a');
              this.link.classList.add('w-full');
              this.link.classList.add('h-full');
              this.link.setAttribute('href', url);
              this.link.setAttribute('id', 'csv-link');
              this.link.setAttribute('download', 'cb-' + Date.now() + '.csv');

              document.getElementById('report_download-csv').append(this.link);
              document.getElementById('csv-link').innerHTML = 'Download CSV';
          },

            closeReport(){
                window.close();
            },

            filterData(data){
                const keys = Object.keys(data);

                for(const key in keys){
                    const lc_key = keys[key].toLowerCase();

                    if(lc_key == 'score' || 
                       lc_key == 'total_parcel_acreage' || 
                       lc_key.includes('raw') ||
                       lc_key.includes('normalized') || 
                       lc_key.includes('message'))
                    {
                        delete data[keys[key]];
                    }
                }
                return data;
            },

            print(){
                window.print();
            },
        },

        mounted(){
          this.report_data = JSON.parse(this.$route.query.data);
          this.buildCsv();
        },
    }

</script>

<style scoped>
    @media print {
      .noprint {visibility: hidden;}
      .page-break{ page-break-inside:avoid; page-break-before: always; }
    }
</style>