<template>

  <div class="leading-normal tracking-normal">
    <div class="relative w-full h-fit">

        <div class="relative w-full bg-white
                    text-sm md:text-base"
              v-show="!showing">
          <a href="#video" title="watch video">
            <img class="w-full object-cover}"
                  :src="start_image_jpg">
            <div class="absolute bottom-0 w-full xl:w-[672px] px-0 lg:px-5 py-3
                        bg-cbp_carousel/90 text-center text-white
                         text-xs md:text-base"
              >Click to watch our video
            </div>
          </a>
        </div>
        <div class="relative w-full hidden slide" v-show="showing"
             v-for="(item, index) in images" :key="item.pathShort">          
            <div v-if="index==0">
              <a href="#video" title="watch video">
                <img class="e w-full object-cover}"
                      :src="start_image_jpg">
                <div class="absolute bottom-0 w-full px-0 lg:px-5 py-3
                            bg-cbp_carousel/90 text-center text-white
                            text-xs md:text-base"
                  >Click to watch our video
                </div>
              </a>
            </div>
            <div v-else>
              <img class="w-full object-cover}"
                  :src="images[index]['pathLong']">
              <div class="absolute bottom-0 w-full px-5 py-3 
                        bg-cbp_carousel/90 text-center text-white
                        text-xs md:text-base"
              >{{captions[index]}}
              </div>
            </div>
        </div>

        <div class="flex justify-center items-center space-x-5">
          <span class="font-extrabold text-4xl"><a class=" h-4 -translate-y-1/2
                       text-neutral-300
                       hover:text-white cursor-pointer"
              @click="moveSlide(-1)">&#8592;</a></span>
           <span class="dot w-3 h-3 rounded-full cursor-pointer inline 
                       bg-neutral-300"
              v-for="(item, index) in images" :key="item.pathShort"
              onclick="currentSlide(index)"
              >
          </span>
          <span class="font-extrabold text-4xl"><a class=" h-4 -translate-y-1/2
                       text-neutral-300
                       hover:text-white cursor-pointer"
              @click="moveSlide(1)">&#8594;</a></span>
        </div>
    </div>

    <!-- The dots -->
  </div>

</template>

<script>

  import start_image_jpg from "../../assets/img/blueprint/carousel-0.jpg"
 
  export default {

    name: 'CarouselComponent',

    data() {
      return {
      captions:[
        'Click to watch our video.',
        'Ocean County, New Jersey. Photo courtesy of Shore Points Mom.',
        'Spring Lake Beach, New Jersey. Photo courtesy of Nick Harris.',
        'Aerial View of Delaware Riverfront. Gloucester, New Jersey.',
        'Kayaking the Maurice River. Photo courtesy of Amy Deputy.',
        'Red fox at Island Beach State Park. Photo courtesy of Jessica Kirstey.',
        'Beemerville, Sussex County New Jersey. Photo courtesy of Eric Olsen.',
        'Explore our multi-award winning tool.' 
      ],
        dots:[],
        images:[],
        interval: 5000,
        showing: false,
        slides: [],
        slideIndex: 1,
        start_image_jpg,
        switcher: null,
      }
    },

    computed:{
      has_dots: function(){
        return  document.getElementsByClassName('dot').length;
      }
    },


    watch:{
      has_dots: function(){        
        if(this.has_dots>0){
          this.slides = document.getElementsByClassName("slide");
          this.dots = document.getElementsByClassName('dot');
          this.syncDots();
        }
      },
    },
    
    methods:{

      currentSlide(n) {
        showSlide(this.slideIndex  = n);
      },

      importAll(r) {
        r.keys().forEach(key => (this.images.push({ pathLong: r(key), 
                                                    pathShort: key })));
      },

      moveSlide(moveStep){
          this.showSlide(this.slideIndex)
          this.slideIndex = this.slideIndex==this.slides.length?1:this.slideIndex+1;
      },

      showSlide(n) {
        let i;          
        this.slides = document.getElementsByClassName("slide");
        this.dots = document.getElementsByClassName('dot');
        
        if(this.slides.length==0){
          return;
        }
        
        for (i = 0; i < this.slides.length; i++) {
          this.slides[i].classList.add('hidden');
        }
        for (i = 0; i < this.dots.length; i++) {
          this.dots[i].classList.remove('bg-white');
          this.dots[i].classList.add('bg-neutral-300');
        }

        this.slides[this.slideIndex - 1].classList.remove('hidden');
        this.syncDots();
        this.showing = true;
      },

      runShow(){
        this.moveSlide(this.slideIndex);
      },

      syncDots(){
        this.dots[this.slideIndex  - 1].classList.remove('bg-neutral-300');
        this.dots[this.slideIndex  - 1].classList.add('bg-white');
      },

      setInitialStyle(){

      }
    },

    mounted(){
      this.importAll(require.context(
          '../../assets/img/blueprint/', true, /(carousel-)\d+\S{4}$/));
      this.switcher = setInterval(this.runShow, this.interval);
    }
  }


 
</script>

<style>

        
</style>