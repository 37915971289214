<template>

<div class="w-screen max-w-print max-h-print h-screen z-51 overflow-auto
                print:overflow-visible mx-auto font-serif">
                    
      <div class="absolute w-screen top-0 left-0 text-center noprint">
          <button class="border-2 border-blue-700 py-1 px-4 bg-white
                  text-blue-600 rounded mr-2" 
                  aria-disabled="false" 
                  role="button" 
                  tabindex="0"
                  id="print-close"
                  @click="closeReport">
              Close
          </button>
          <button class="border-2 border-gray-100 py-1 px-5 
                        bg-blue-700 rounded-md text-white"
                  aria-disabled="false" 
                  role="button" 
                  tabindex="0"
                  id="report_report_multiparcel_print"
                  @click="print">
              Print
          </button>
          <csv-report
            v-bind:multi_feature = true>
          </csv-report>
          <div class="text-center mx-auto bg-white ml-14 mt-2"
                v-if="has_image">
            <input type="checkbox"
                    id="report_report_show_image" 
                    v-model="include_image">
            <label for="report_report_show_image" 
                   class="text-xs pl-2 mr-6">include 
              images</label>
            <input type="checkbox" class="ml-6"
                    id="report_report_show_components" 
                    v-model="include_appendices">
            <label for="report_report_show_components" 
                   class="text-xs pl-2">include appendices
              (model component data for parcels)
            </label>
          </div>
      </div>
    
      <div class="grid grid-cols-10 w-full max-h-fit mt-8 print:mt-0">
          <div class="col-span-1 col-start-2 mt-12">
            <img class="w-full mx-auto my-auto float-right"
                alt="Conservation Blueprint logo" :src="plus_svg">
          </div>
          <div class="col-span-8 align-bottom text-left pb-6 text-3xl ml-4
                      mt-14">
            Conservation Blueprint<img :src="plus_black_svg" 
            class="h-3 mr-1.5 inline mb-8">
              <br>Multi-Parcel Summary
              <div class="text-left pl-2 text-sm">
                  {{stamp }}
              </div>
          </div>
          <div class="col-span-10 grid grid-cols-10 w-full">
              <div class="col-span-10 text-lg underline pt-4 mb-2 ml-16">
                  Parcels:
              </div>
              <div class="col-span-10 ml-16 mb-2"
                  v-for="feature in report_data">
                  <div class="px-1 ml-5">
                      {{ feature.muni }}
                  </div>
                  <div>
                    <span class="pl-12 pr-1 text-gray-500"
                          v-if="feature.block ">
                        Block:
                    </span>
                    <span class="px-1"
                          v-if="feature.block ">
                        {{ feature.block }}
                    </span>
                    <span class="pl-2 pr-1 text-gray-500"
                          v-if="feature.lot">
                        Lot:
                    </span>
                    <span class="px-1"
                          v-if="feature.lot">
                        {{ feature.lot }}
                    </span>
                    <span class="pl-2 pr-1 text-gray-500"
                          v-if="feature.qualifier">
                        Qualifier:
                    </span>
                    <span class="px-1"
                          v-if="feature.qualifier">
                        {{ feature.qualifier }}
                    </span>
                  </div>
                  <div>
                    <span class="pl-12 pr-1 text-gray-500"
                          v-if="feature.acreage">
                        Acres:
                    </span>
                    <span class="px-1"
                          v-if="feature.acreage">
                        {{(Math.round(10 * parseFloat(feature.acreage)) / 10)
                           .toFixed(1) }}
                    </span>
                    <span class="pl-2 pr-1 text-gray-500"
                          v-if="feature.acreage">
                        Preserved:
                    </span>
                    <span class="px-1"
                          v-if="feature.acreage">
                        {{ feature.response_data.os?'Yes':'No' }}
                    </span>
                  </div>
              </div>
              <div class="col-span-9 col-start-2 pl-3 pt-6 text-left">
                Total Area: {{Math.round(parseFloat(total_acres) * 10) / 10}} 
                acres
              </div>
          </div>
      </div>

      <div class="grid grid-cols-10 text-lg w-full">
        <div class="col-span-10 text-left underline ml-16 mt-8">
            Summary of Model Rankings
        </div>
        <div class="flex flex-col col-span-9 col-start-1">
          <div class="mx-8">
            <div class="pb-2 inline-block min-w-full px-8">
              <div class="overflow-hidden">
                <table class="min-w-full">
                  <thead class="bg-white border-b">
                    <tr>
                      <th scope="col" 
                          class="font-medium text-gray-900 
                                px-6 py-4 text-left">
                        &nbsp;
                      </th>
                      <th scope="col" 
                          class="font-medium text-gray-900 
                                px-6 py-4 text-left">
                        <div>Average</div>
                        <div class="text-xs text-gray-600 font-normal"
                          >(Area weighted)
                        </div>
                      </th>
                      <th scope="col" 
                          class=" font-medium text-gray-900 
                                px-6 py-4 text-left">
                        Minimum
                      </th>
                      <th scope="col" 
                          class="font-medium text-gray-900 
                                px-6 py-4 text-left">
                        Maximum
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="bg-white border-b transition duration-300 
                               ease-in-out hover:bg-gray-100">
                      <td class="px-6 py-4 whitespace-nowrap font-medium 
                                text-gray-900">
                        Agriculture</td>
                      <td class="text-gray-900 font-light px-6 py-4 
                                   whitespace-nowrap">
                      {{(Math.round((parseFloat(avg_ag)) * 10)/ 10).toFixed(1)}}
                      </td>
                      <td class="text-gray-900 font-light px-6 py-4 
                                   whitespace-nowrap">
                      {{(Math.round((parseFloat(min_ag)) * 10)/ 10).toFixed(1)}}
                      </td>
                      <td class="text-gray-900 font-light px-6 py-4 
                                   whitespace-nowrap">
                      {{(Math.round((parseFloat(max_ag)) * 10)/ 10).toFixed(1)}}
                      </td>
                    </tr>
                    <tr class="bg-white border-b transition duration-300 
                               ease-in-out hover:bg-gray-100">
                      <td class="px-6 py-4 whitespace-nowrap font-medium 
                                text-gray-900">
                        Ecological</td>
                      <td class="text-gray-900 font-light px-6 py-4 
                                   whitespace-nowrap">
                    {{(Math.round((parseFloat(avg_eco)) * 10)/ 10).toFixed(1)}}
                      </td>
                      <td class="text-gray-900 font-light px-6 py-4 
                                   whitespace-nowrap">
                    {{(Math.round((parseFloat(min_eco)) * 10)/ 10).toFixed(1)}}
                      </td>
                      <td class="text-gray-900 font-light px-6 py-4 
                                   whitespace-nowrap">
                    {{(Math.round((parseFloat(max_eco)) * 10)/ 10).toFixed(1)}}
                      </td>
                    </tr>
                    <tr class="bg-white border-b transition duration-300 
                               ease-in-out hover:bg-gray-100">
                      <td class="px-6 py-4 whitespace-nowrap font-medium 
                                text-gray-900">
                        Water</td>
                      <td class="text-gray-900 font-light px-6 py-4 
                                   whitespace-nowrap">
                      {{(Math.round((parseFloat(avg_wa)) * 10)/ 10).toFixed(1)}}
                      </td>
                      <td class="text-gray-900 font-light px-6 py-4 
                                   whitespace-nowrap">
                      {{(Math.round((parseFloat(min_wa)) * 10)/ 10).toFixed(1)}}
                      </td>
                      <td class="text-gray-900 font-light px-6 py-4 
                                   whitespace-nowrap">
                      {{(Math.round((parseFloat(max_wa)) * 10)/ 10).toFixed(1)}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
    </div>

    <div class="col-span-10 min-h-max clear-both"
        v-for="(item) in report_data">
          <item-report
              v-bind:include_appendices = "include_appendices"
              v-bind:include_image = "include_image"
              v-bind:item_data =  "item"
              @has_image="updateShowImage">
          </item-report>
    </div>

    <div id="disclaimer" 
        class="col-span-10 mt-4 mb-4 grid grid-cols-10 page-break"
        v-if="!multiparcel">
        <div class="col-span-10 text-xl underline underline-offset-1 py-4 
                    ml-8">
            Disclaimer
        </div>
    </div>
    <div class="col-span-8 col-start-2 text-base">
      <p class="">The Conservation Blueprint utilizes best available 
      data from a
      variety of sources. These sources can be made available so users
      may assess the integrity and objectivity of the data. While
      attention is given to present the most up-to-date information,
      The Conservation Blueprint Project Team, and its funders, assume
      no responsibility for the spatial accuracy, completeness or
      timeliness of data used, and expressly disclaim any and all
      responsibility for errors, misinterpretations, omissions or other
      inconsistencies depicted arising from or otherwise related to the
      maps maintained within this site.</p>

      <p class="mt-3">Maps and data sets found on this site are for planning 
        activities
      only and cannot and should not be used for any regulatory
      purposes - this applies to both the parcel and state-wide levels.
      The information on this website should be used only as a guide;
      an on-site investigation is the only true way to know which
      features exist on the ground.</p>

      <p class="mt-3">Priority Land rankings for each theme were determined 
      by The
      Conservation Blueprint Project Team in conjunction with the
      Conservation Blueprint Steering Committee and corresponding
      Advisory Committees. Priority Lands and Rankings reflect the
      collaborative input, deliberation and agreed upon determinations
      of the aforementioned groups. Users acknowledge that Blueprint
      rankings are not linked to any other state ranking system, and
      are not directly linked to any funding sources. Blueprint
      rankings do not replace a municipality or county’s own
      prioritization.</p>

      <p class="mt-3">Full methodology reports will be available on the 
      Conservation
      Blueprint website. Revisions to methodology will occur
      periodically based upon the inclusion of updated or new data
      sets, as well as feedback from the Conservation Blueprint
      Steering and Advisory Committees.</p>

      <p class="mt-3">The Conservation Blueprint Project Team would 
      appreciate feedback
      on any errors that are discovered when using this site by
      contacting us at conservationblueprint@gmail.com.</p>
    </div>

</div>

</template>

<script>

    import ParcelReportCSV from "./ParcelReportCSV.vue";
    import plus_black_svg from "../assets/img/logos/plus_black_no_text.svg";
    import plus_svg from "../assets/img/logos/plus.svg";
    import MultiParcelReportItem from './ParcelReport.vue'

    export default {
        name: 'MultiParcelReport',

        components: {
            "csv-report": ParcelReportCSV,
            "item-report": MultiParcelReportItem
        },

        data() {
            return {
              total_acres: 0,
              max_acres: 0,
              min_acres: 0,
              avg_acres: 0,
              max_ag: 0,
              min_ag: 0,
              avg_ag: 0,
              max_com: 0,
              min_com: 0,
              avg_com: 0,
              max_eco: 0,
              min_eco: 0,
              avg_eco: 0,
              max_wa: 0,
              min_wa: 0,
              avg_wa: 0,
              plus_black_svg,
              plus_svg,
              report_data: null,
              has_image: false,
              include_appendices: false,
              include_image: false,
              show_image: 0,
              stamp: new Date().toLocaleDateString('en-us', 
              {weekday:"long", year:"numeric", month:"short", day:"numeric"}) 
            }
        },

        methods: {

            closeReport(){
                window.close();
            },

            getIndex(index){
                return index + 1;
            },

            print(){
                window.print();
            },
            
            processData(){

              let items = [];
              let total_acres = 0;
              let max_acres = 0;
              let min_acres = null;
              let max_ag = 0;
              let min_ag = null;
              let avg_ag = 0;
              let max_com = 0;
              let min_com = null;
              let avg_com = 0;
              let max_eco = 0;
              let min_eco = null;
              let avg_eco = 0;
              let max_wa = 0;
              let min_wa = null;
              let avg_wa = 0;

              for(const item in this.report_data){
                let arr = [0, 0, 0, 0, 0];
                let data =  this.report_data[item];

                if (data.hasOwnProperty('acreage')){
                  arr[0] = parseFloat(data.acreage);
                  total_acres += arr[0];

                  if(arr[0] > max_acres){
                    max_acres = arr[0]
                  }
                  if(!min_acres){
                    min_acres = arr[0];
                  }else if(arr[0] < min_acres){
                    min_acres = arr[0];
                  }
                }
              
                if(data.hasOwnProperty('ag_score')){
                  arr[1] = data.ag_score;

                  if(arr[1] > max_ag){
                    max_ag = arr[1]
                  }
                  if(!min_ag){
                      min_ag = arr[1];
                  }else if(arr[1] < min_ag){
                      min_ag = arr[1];
                  }
                }
                
                if(data.hasOwnProperty('eco_score')){
                  arr[2] = data.eco_score;

                  if(arr[2] > max_eco){
                    max_eco = arr[2]
                  }
                  if(!min_eco){
                      min_eco = arr[2];
                  }else if(arr[2] < min_eco){
                      min_eco = arr[2];
                  }
                }
                
                if(data.hasOwnProperty('wa_score')){
                  arr[3] = data.wa_score;

                  if(arr[3] > max_wa){
                    max_wa = arr[3]
                  }
                  if(!min_wa){
                      min_wa = arr[3];
                  }else if(arr[3] < min_wa){
                      min_wa = arr[3];
                  }
                }
                
                if(data.hasOwnProperty('com_score')){
                  arr[4] = data.com_score;

                  if(arr[4] > max_com){
                    max_com = arr[4]
                  }
                  if(!min_com){
                      min_com = arr[4];
                  }else if(arr[4] < min_com){
                      min_com = arr[4];
                  }
                }
                items.push(arr);
              }
              
              for(const item of items){
                const pct = item[0]/total_acres;
                avg_ag += item[1] * pct;
                avg_eco += item[2] * pct;
                avg_wa += item[3] * pct;
                avg_com += item[4] * pct;
              }

              this.total_acres = total_acres;
              this.avg_acres = total_acres/items.length;
              this.max_acres = max_acres;
              this.min_acres = min_acres;
              this.max_ag = max_ag;
              this.min_ag = min_ag
              this.avg_ag = Math.round((avg_ag + Number.EPSILON) * 100) / 100;
              this.max_com = max_com;
              this.min_com = min_com;
              this.avg_com = Math.round((avg_com + Number.EPSILON) * 100) / 100;
              this.max_eco = max_eco
              this.min_eco = min_eco;
              this.avg_eco = Math.round((avg_eco + Number.EPSILON) * 100) / 100;
              this.max_wa = max_wa;
              this.min_wa = min_wa;
              this.avg_wa = Math.round((avg_wa + Number.EPSILON) * 100) / 100;
            },

            updateShowImage(image_status){
              if(image_status == true){
                this.show_image = this.show_image ? this.show_image + 1 : 1;
              }else{
                this.show_image = this.show_image ? this.show_image - 1 : 0;
              }
              if(this.show_image){
                this.include_image = true;
                this.has_image = true;
              }
            }
        },

        mounted(){
            this.report_data = JSON.parse(this.$route.query.data);
            this.processData(this.report_data);
        },
    }
</script>

<style >
    @media print {
        .noprint {
         visibility: hidden;
        }
        .page-break {page-break-before: always;}
    }
    
</style>