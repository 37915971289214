<template> 

  <div id="map"
      class="absolute w-full h-full">
  </div>

  <share-link v-bind:map="map" 
              v-bind:share_link="share_link"
              v-bind:short_link="short_link"
              @share_control_added = "addControls"
              @create_link = "createShortLink"
              @delete_link = "deleteShortLink">
  </share-link>

  <div class='map-overlay absolute bottom-8 md:bottom-16 lg:bottom-10 h-auto 
              w-auto max-w-xs p-2 leading-4'
        :class="{'lg:bottom-10': !show_content, 
                 'lg:bottom-5': show_content,
                 'md:bottom-5': show_content,
                 'md:bottom-16': !show_content}"
        v-bind:style="[map_left_margin ?{'margin-left':`${map_left_margin}px` }:
                                      {'margin-left': 'inherit'}]"
        v-show="has_legend">
      
    <div class="flex flex-wrap w-full mb-2">
      <div class="inline min-w-min my-auto bg-white rounded border-2
                  border-gray-300 py-1 shadow-lg"
            v-show="show_features"
            title="Select a Blueprint model">
        <select id="model-select" 
                class="text-center pl-1 text-gray-800 text-base
                      align-middle shadow-sm rounded appearance-none"
                @change="updateModelChoice">
            <option :value="key"
                    v-for="key in Object.keys(selection_codes)
                        .filter(value => parseInt(value) > 0)"
                    :key ="key">
                &nbsp;&nbsp;{{selection_codes[key][0]}}&nbsp;(
                {{ selection_codes[key][2] }})&nbsp;&nbsp;
            </option> 
        </select>
        <span class="pr-2  text-lg">&#8597;</span>
      </div>
    </div>
    <div id='legend-title' 
        class="p-1 w-full  text-black bg-white rounded border-2 border-gray-300"
        v-show="show_features">  
      <span v-show="!show_legend_details" id='legend'
            class="w-full h-7">
      </span>
    </div>

</div>

</template>

<script>
    import 'mapbox-gl/dist/mapbox-gl.css';
    import "mapbox-gl-style-switcher/styles.css";
    import { MapboxStyleSwitcherControl } from "mapbox-gl-style-switcher";
    // import {FillStyleExtension} from '@deck.gl/extensions';
    import {MapboxLayer} from '@deck.gl/mapbox';
    import {MVTLayer} from '@deck.gl/geo-layers';
    import axios from 'axios';
    import BlueprintClassicLink from './BlueprintClassicLink.vue';
    import {RasterFeatures} from "../assets/js/RasterFeaturesClass"
    import ShareControl from './ShareControl.vue';
    import mapboxgl from 'mapbox-gl';
    import OpacityControl from './OpacityControl.vue';
    import pattern_png from "../assets/img/fills/pattern.png";
    import pattern_map from "../assets/json/pattern_map.json";

    export default {
                
        name: 'MapPanel',

        components: {
            "share-link": ShareControl
        },

        props:[ 
            'all_layers_loaded',
            'break_points',
            'cb_min',
            'change',
            'default_style',
            'default_view',
            'feature_id',
            'show_features',
            'feature_opacity',
            'filters',
            'has_legend',
            'info_marker_coords',
            'info_panel',
            'map_bounds',
            'map_opacity',
            'mapbox_token',
            'municipality',
            'openspace_status',
            'overlays',
            'parcel_size_min',
            'parcel_click',
            'pin',
            'saved_feature_ids',
            'selected',
            'selection_codes',
            'show_content',
            'small_screen',
            'state_to_restore'
        ], 

        data(){
            return{
                advanced_filter: false,
                blank_style: {
                  "version": 8,
                  "name": "blank",
                  "sources": {
                    "openmaptiles": {
                      "type": "vector",
                      "url": ""
                    }
                  },
                  "layers": [{
                    "id": "background",
                    "type": "background",
                    "paint": {
                      "background-color": "#ffffff"
                    }
                  }]
                },
                BlueprintClassicLink,
                clickedFeatureID: null,
                classicLink: null,
                control_list: [
                    [new MapboxStyleSwitcherControl(null,{eventListeners: 
                      {onChange: ()=>this.handleBasemapChange()}}), 
                                                  'top-right', 'xs', 'style'],
                    [new mapboxgl.NavigationControl(),
                                              'top-right', 'md', 'navigation'],
                    [new mapboxgl.AttributionControl({
                        customAttribution: 
                          `<span 
                          title="Site Designed and Created by Mark Corbalis">
                  &nbsp;&nbsp;&nbsp;
                  <a href="https://www.linkedin.com/in/mark-corbalis-325b87292"
                      target="_blank">Mark Corbalis</a>&nbsp;</span>`
                      }),'bottom-right', 'xs', 'attribution'],
                    [new mapboxgl.FullscreenControl(
                            {container: document.querySelector('body')}),
                                              'top-right', 'md', 'fullscreen'],
                    [new mapboxgl.GeolocateControl(
                              {positionOptions: {enableHighAccuracy: true}}),
                                              'top-right', 'xs', 'geolocate'],
                    
                    [new BlueprintClassicLink('https://njmap2.com'), 
                                                    'top-right', 'xs','njmap'],
                    [new OpacityControl(), 'bottom-right', 'xs', 'opacity'],
                    [new mapboxgl.ScaleControl({unit: 'imperial'}),
                                                'bottom-right', 'md', 'scale'],
                ],
                controls:{},
                features: null,
                label_layer_id: null, 
                layer: {},
                legend: {
                    values: []
                },
                map: null,
                map_loaded: false,
                min_score: 0,
                OpacityControl,
                parcel_boundaries: null,
                pattern_map,
                pattern_png,
                patterns: ['dots', 'solid', 'hatch-2x', 'hatch-cross'],
                raster_features: null,
                share_link: null,
                short_link: null,
                show_legend_details: false,
                state_restored: false,
                map_left_margin: null,
                zoom_threshold_met: false
            }
        },
        
        emits: [
            'add_marker',
            'basemap_changed',
            'feature_opacity',
            'features',
            'label_layer_id',
            'loading_features', 
            'map',
            'map_loaded',
            'zoom_threshold_met',
            'model_change',
            'style_change',
            'update_legend',
            'zoom'
        ],

        computed: {
          legend_title: function () {
              return this.selection_codes[this.selected][0] + 'Rank';
          },

          filter_count: function () {
            return this.filters.filter(e => e).length;
          },

          os: function(){
            return this.filters?(this.filters.os?true:false):false;
          }
        },

        watch:{
            all_layers_loaded: function(){
              this.addStyleChangeListener();
            },

            feature_id: function(){
              this.getMapState();
            },

            show_features: function(){
              this.toggleOpacityControl();
              this.toggleBlueprintFeatures();
              this.getMapState();
            },

            feature_opacity: function(){
              this.setFeatureOpacity(this.feature_opacity);
              this.getMapState();
            },

            filters: {
              deep: true,
              handler(){
                this.getMapState();
              }
            },

            info_marker_coords: function(){
                this.getMapState();
            },

            map_bounds: function(){
                if(this.map_bounds){
                    this.setMapBounds(this.map_bounds);
                    this.getMapState();
                }
            },

            openspace_status: function(){
              this.toggleBlueprintFeatures();
            },

            overlays: { 
              deep: true,
              handler(){
                this.getMapState();
              }
            },

            saved_feature_ids: function(){
                this.getMapState();
            },

            selected: function(){
                this.getMapState();
            },

            show_content:{
              immediate: true,

              handler(){
                    const width = 
                        document.getElementById('content-container').offsetWidth
                if(this.small_screen == false && this.show_content){
                    this.map_left_margin = 1.02 * width;
                }else{
                    this.map_left_margin = 0;
                }
              }
            },

            state_to_restore: {
              deep: true,
              handler(){
                this.restoreState();
              }
            }
        },

        created(){
            mapboxgl.accessToken = this.mapbox_token;
        },

        methods:{

            addControls(){
                for (const control of this.control_list) {
                  if(window.innerWidth >= this.break_points[control[2]]){
                    this.map.addControl(control[0], control[1]);
                   }
                }
                document.getElementById('opacity-ctl').value = 
                     this.feature_opacity * 100;
                document.getElementsByClassName('mapboxgl-style-switcher')[0]
                    .setAttribute('title', 'Set background map');
                this.addClassicLinkListener();
            },
            
            addClassicLinkListener(){
              const el = document.getElementById('classic-link');

              if(el){
                el.addEventListener('click',
                  ()=>this.pushToAnalytics('njmap', 
                      {'njmap_url':this.classicLink}));
              }
            },

            addFeaturerLayers(){
              this.addRasterFeatures();
              this.addVectorFeatures();
              this.addEventListeners();
            },

            addRasterFeatures(){
              this.raster_features = 
                            new RasterFeatures(this.map, this.label_layer_id);

              this.raster_features
                        .addRasterLayer(this.selection_codes[this.selected][1],
                        this.openspace_status);
              // this.addRasterFeatureListener();
            },

            addRasterFeatureListener(){
              this.map.on('click', this.handleFeatureClick);
            },
            addEventListeners(){
              this.map.on('click', this.handleFeatureClick);
            },

            addVectorFeatures(){
                // const url = 'https://d7wi8peueokva.cloudfront.net/' +
                //              'blueprint_model_summary/{z}/{x}/{y}.pbf'
                const url = 'https://d7wi8peueokva.cloudfront.net/' +
                             'parcel_model/{z}/{x}/{y}.pbf'
                // const url = 'https://blueprint-explorer.s3.amazonaws.com/features/{z}/{x}/{y}.pbf';
                this.$emit('loading_features', true);
                this.loading = true;

                this.features = new MapboxLayer({
                    id: 'features',
                    type: MVTLayer,
                    data: url, 
                    minZoom: 12,
                    maxZoom: 14,
                    opacity: this.feature_opacity,
                    binary: true,
                    lineWidthMinPixels: 1,
                    getLineColor:  [255, 255, 255, 50],
                    getLineWidth:   f => this.adjustFeatureBorder(f),

                    pickable: true,

                    // Removed feature patterns 11/23/23 to lessen processing 
                    // needed to render layer.  Patterns were originally used to 
                    // identify openspace, but are no longer needed as openspace
                    // is now rendered as a seperate layer and symbolized by
                    // color. - mec

                    // fillPatternMask: true,
                    // fillPatternAtlas: this.pattern_png,
                    // fillPatternMapping: this.pattern_map,
                    // getFillPatternScale: 3,
                    // getFillPatternOffset: [0, 0],

                    // extensions: [new FillStyleExtension({pattern: true})],
                });

                this.map.on('idle', this.updateIdleMap);
                
                if(this.label_layer_id){
                    this.map.addLayer(this.features, this.label_layer_id);
                }else{
                    this.map.addLayer(this.features);
                }

                this.map.setLayoutProperty('features', 'visibility', 
                                                                    'visible');
                
                this.$emit('features', this.features);
            },

            addOpacityControlHandler(){
                const ctl = document.getElementById('opacity-ctl');

                if(ctl){
                  ctl.addEventListener('change', (e) => {
                    this.adjustFeatureOpacity(e);
                  });
                }
            },

            // addSkyLayer(){
            //     this.map.addLayer({
            //         'id': 'sky',
            //         'type': 'sky',
            //         'paint': {
            //         'sky-type': 'atmosphere',
            //         'sky-atmosphere-sun': [0.0, 0.0],
            //         'sky-atmosphere-sun-intensity': 15
            //         }
            //     });
            // },

            addStyleChangeListener(){
              this.map.on('styledata', () => {
                  this.getFirstLabelLayerId();
                  if(this.label_layer_id){

                    if(!this.map.getLayer('features') && this.features && 
                       this.show_features){
                        this.map.addLayer(this.features, 
                                                  this.label_layer_id);
                        this.map.setLayoutProperty('features', 'visibility', 
                                this.show_features?'visible':'none');
                    }

                    if(!this.map.getLayer('feature-raster') && 
                       this.raster_features && this.show_features){
                        this.addRasterFeatures();
                    }

                    this.$emit('style_change');
                    this.getMapState();
                  }
              })
            },

            adjustFeatureBorder(f){
                try{
                    const data = f.properties.data.split('|');

                    if(data[0] === this.feature_id){
                      return 100;
                    }else{
                        return 1;
                    }
                }catch (error){
                    return 1;
                }
            },

            adjustFeatureOpacity(e){
              const opacity = parseInt(e.target.value, 10) / 100;

              this.setFeatureOpacity(opacity);
              this.$emit('feature_opacity', opacity);
            },

            check_for_point_in_parcel(coordinates){

              return new Promise((resolve, reject) => {
                const url = 'https://gspr34zib0.execute-api.us-east-1.'
                                + 'amazonaws.com/default/data-retriever?action='
                                + 'check_point_in_parcel'
                                + `&lat=${coordinates[1]}`
                                + `&lng=${coordinates[0]}`;

                  axios
                  .get(url)
                  .then(response => {
                      if(response.data){
                        resolve(response.data);
                      }else{
                        reject(false);
                      }
                  })
                  .catch(function () {
                      reject(false);
                  });
              });
            },

            createMap(){
  
                this.map = new mapboxgl.Map({
                    container:  'map',
                    style: this.default_style,
                    antialias: true,
                    attributionControl: false,
                    ScaleControl: false,
                    preserveDrawingBuffer: true,
                });
                this.setDefaultMapView();

                this.map
                .on('load', () => {      
                    this.getFirstLabelLayerId();
                    this.addFeaturerLayers();
                    this.addOpacityControlHandler();
                    this.getMapState();

                    // Removed sky layer 11/23/23 to improve performance
                    // this.addSkyLayer();
                    this.updateMapLoaded();
                })
                .on('boxzoomend', () => { this.getMapState(); })
                .on('dragend', () => { this.getMapState(); })
                .on('moveend', () => { this.getMapState(); })
                .on('pitchend', () => { this.getMapState(); })
                .on('rotateend', () => { this.getMapState(); })
                .on('zoomend', () => { 
                    this.getMapState();
                    this.updateZoomThreshold();
                });
                this.map.touchPitch.enable();
                this.$emit('map', this.map);
            },

            createShortLink(){
                const str = this.share_link.split('?')[1]
                            .replaceAll('&','*')
                            .replaceAll(' ','');

                let url = '';
                url += 'https://'; 
                url += 'gspr34zib0.execute-api.us-east-1.amazonaws.com/';
                url += 'default/urlShortener?';
                url += `&str=${str}`;

                axios
                .post(url)
                .then(response => {
                    this.short_link = response.data;
                })
                .catch(function () {
                    this.short_link = this.share_link;
                })
                .then(() => {
                    this.pushToAnalytics('share',
                      {'share_url': this.short_link});        
                });
            },

            deleteShortLink(){
                this.short_link = null;
            },

            getFirstLabelLayerId(){
                const id = this.label_layer_id;
                try{
                    this.label_layer_id = this.map.getStyle().layers.find(
                    layer => layer.type === 'symbol').id;
                }catch{
                    this.label_layer_id = id;
                }
                this.$emit('label_layer_id', this.label_layer_id)
            },

            getMapState(){
                let style = this.style;
                try{
                  style = this.map.getStyle().metadata['mapbox:origin'];
                } catch { 
                  style = this.style;
                }
                const bearing = this.map.getBearing();
                const center = this.map.getCenter();
                const feature_id = this.feature_id;
                const features = this.saved_feature_ids;
                const show_features = this.show_features;
                const filters = this.filters?
                  (this.filters.filters?this.filters.filters.toString():''):'';
                const lat = center.lat;
                const lng = center.lng;
                const opacity = this.feature_opacity;
                const openspace = this.openspace_status;
                const overlays = this.overlays.join();
                const info_marker_coords = 
                            this.info_marker_coords?this.info_marker_coords:'';
                const info_panel  = this.info_panel?'t':'f';
                const page = 'plus';
                const parcel_click = this.parcel_click;
                const pitch = this.map.getPitch();
                const selected = this.selected;
                const zoom = this.map.getZoom();

                let url = `${location.origin}/#plus?b=${bearing}&`
                url += `f=${feature_id}&fi=${show_features}&`
                url += `fs=${features}&fl=${filters}&ip=${info_panel}&`;
                url += `la=${lat}&ln=${lng}&o=${openspace}&`
                url += `ols=${overlays}&op=${opacity}&p=${pitch}&`;
                url += `pc=${info_marker_coords}&pk=${parcel_click}&`;
                url += `s=${selected}&sty=${style}&z=${zoom}`;
 
                this.share_link = url;
                this.updateClassicLink(selected, center, zoom);
            },

            handleBasemapChange(){
              this.$emit('basemap_changed');
            },

            handleFeatureClick(e){

                if( !this.parcel_click ){
                  return;
                }
                if(!this.map.getLayer('features') && 
                   !this.map.getLayer('feature-raster')){
                  return; 
                }
                let source = 'map_click';

                let coordinates = null;
                if(e.hasOwnProperty('coordinate')){
                  coordinates = e.coordinate;
                }else if(e.hasOwnProperty('lngLat')){
                  if(e.lngLat){
                    coordinates = [e.lngLat.lng, e.lngLat.lat];
                  }
                }

                if(coordinates){
                  const stamp = Date.now();

                  this.$emit('add_marker',{"stamp": stamp, 
                                          "data": e, 
                                          "source": source});
                                          
                  // removed 7/29/23 - slows data retrieval too much

                  // this.check_for_point_in_parcel(coordinates)
                  //   .then( ()=>{
                  //     const stamp = Date.now();
                  //     this.$emit('add_marker',{"stamp": stamp, 
                  //                             "data": e, 
                  //                             "source": source});
                  //   })
                  //   .catch( ()=>{
                  //     return;
                  //   })
                  }
            },

            pushToAnalytics(type, params){
              
              switch (type) {
                case 'model':

                  dataLayer.push({
                    'event': 'model_change',
                    'model_selection': params['model_selection']
                  });
                  break;

                case 'share':

                  dataLayer.push({
                    'event': 'map_share',
                    'share_url': params['share_url']
                  });
                  break;

                  case 'njmap':

                    dataLayer.push({
                      'event': 'opened_njmap',
                      'njmap_url': params['njmap_url']
                    });
                    break;
              
                default:
                  break;
              }
            },

            removeMarker(id){
                if(this.markers.hasOwnProperty(id)){
                    this.markers[id].remove();
                    delete this.markers[id];
                }
            },

            restoreState(){

              if(this.state_restored){
                return;
              }

              if(this.state_to_restore.selected){
                this.setModelSelect(this.state_to_restore.selected);
              }

              if(this.state_to_restore.view){
                this.setMapView(this.state_to_restore.view);
              }

              this.state_restored = true;

            },

            setDefaultMapView(){
              this.setMapView(this.default_view);

              if(this.small_screen){
                return;
              }
              let lng_factor = 1;
              let lat_factor = 1;
              let zoom = 7;

              if(window.innerWidth < this.break_points['md']){
                lng_factor = 1;
                lat_factor = 1;
              }else if(window.innerWidth < this.break_points['lg']){
                lng_factor = 1.9;
                lat_factor = 6;
              }else if(window.innerWidth < this.break_points['xl']){
                lng_factor = 2.3;
                lat_factor = 14;
              }else if(window.innerWidth < this.break_points['xxl']){
                lng_factor = 2;
                lat_factor = 14;
                // zoom=8;
              }else{
                lng_factor = 2;
                lat_factor = 14;
                zoom=8;
              }

              this.map.setZoom(zoom);
              const bounds = this.map.getBounds().toArray();
              const lng_diff = (this.default_view.lng - bounds[1][0])/-lng_factor;
              const lat_diff = (this.default_view.lat - bounds[1][1])/-lat_factor;

              const lng = this.default_view.lng - lng_diff;
              const lat = this.default_view.lat - lat_diff;
              this.map.setCenter([lng, lat]);
            },


            setFeatureOpacity(opacity){
              if(!this.features){ //occurs when loading map from a share link.
                return;
              }

              this.features.setProps(
                  {'opacity': opacity}
              );
              
              this.map.setPaintProperty('feature-raster','raster-opacity', 
              opacity);
            },

            setMapBounds(bounds){
                this.map.fitBounds(bounds);
            },

            setMapView(view){
              
                this.map.setZoom(view.zoom);
                this.map.setBearing(view.bearing);
                this.map.setPitch(view.pitch);
                if(this.small_screen){
                  this.map.setCenter([view.lng_small_screen, 
                                                      view.lat_small_screen]);
                }else{
                  this.map.setCenter([view.lng, view.lat]);
                }
            },
            
            setModelSelect(value){
                const val = value?value:this.selected;

                document.getElementById('model-select').value = val;
            },

            toggleOpacityControl(){
              const el = document.getElementById('blueprint-opacity-control');

              if(!el){
                return;
              }

              if(this.show_features){
                el.classList.remove('hidden');
                el.classList.add('block');
              }else{
                el.classList.remove('block');
                el.classList.add('hidden');
              }
            },

            toggleBlueprintFeatures(){
              this.raster_features.toggleRasterLayer(this.show_features, 
                this.selection_codes[this.selected][1], this.openspace_status);
              
              if(this.show_features){
                this.map.setLayoutProperty('features', 'visibility', 'visible');
              }else{
                this.map.setLayoutProperty('features', 'visibility', 'none');
              }
            },

            updateClassicLink(selected, center, zoom){
              let el = document.getElementById('classic-link');

              if(!el){
                return;
              }
              const id = parseInt(selected) === 6 ? 4: selected;
              const model = this.selection_codes[id][0].toLowerCase();
              const z = Math.round(zoom);
              let url = 'https://www.njmap2.com/blueprint/';
              
              url += `${model}/?override=1&zoom=${z}&lat=${center.lat}`;
              url += `&lng=${center.lng}`;

              el.href = url;
              this.classicLink = url;
            },

            updateIdleMap(){
                this.$emit('update_legend');
                this.$emit('loading_features', false);
                if(this.map.getZoom< 11){
                    this.map.setZoom(11);
                }
            },

            updateMapLoaded(){
              this.map_loaded = true;
              this.$emit('map_loaded', true);
            },

            updateModelChoice(e){
              let selection = this.selected;

              if(e.target){
                selection  = e.target.value;
              }

              this.$emit('model_change', e);
              if(this.show_features){
                this.raster_features
                        .addRasterLayer(this.selection_codes[selection][1],
                        this.openspace_status);
              }

              this.pushToAnalytics('model',
              {'model_selection': this.selection_codes[selection][0]});

            },

            updateZoomThreshold(){
                const zoom = this.map.getZoom();

                if(zoom < 11.5){
                    this.zoom_threshold_met = false;
                    this.$emit('zoom_threshold_met', false);
                    this.$emit('zoom', zoom);
                } else{
                    this.zoom_threshold_met = true;
                    this.$emit('zoom_threshold_met', true);
                    this.$emit('zoom', zoom);
                }
            }
        },

        mounted(){
            this.createMap();
            this.setModelSelect();
        }
    }

</script>

<style>
    .mapbox-improve-map {
      display: none;
    }

    .mapboxgl-ctrl-bottom-left {
      margin-left: 1rem;
    }

    @media screen and (min-width: 1024px){
      .mapboxgl-ctrl-top-right {
        margin-top: 0.5rem;
      }
    }

    @media screen and (min-width: 1400px){
      .mapboxgl-ctrl-top-right {
        margin-top: 2rem;
      }
    }

    @media screen and (max-width: 1023px) and (min-width: 768px){
      .mapboxgl-ctrl-bottom-left {
        margin-bottom: 2.25rem;
      }
    }

    @media screen and (max-width: 1023px) and (min-width: 640px){
      .mapboxgl-ctrl-bottom-right {
        font-size: 0.5rem;
        line-height: .75rem;
        background-color: transparent;
      }
    }

    @media screen and (max-width: 640px) {
      .mapboxgl-ctrl-bottom-right {
        font-size: 0.4rem;
        line-height: .75rem;
        background-color: transparent;
        padding: 0;
        height: 33px;
      }
    }
    .mapboxgl-ctrl-bottom-right mapboxgl-ctrl-attrib {
      background-color: rgba(255,255,255, 1);
      color: #A1A1AA;
    }
    .mapboxgl-popup-content {
      padding-top: 20px;
      border-radius: 10px;
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, 
                  rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    }
    .mapboxgl-popup-close-button{
      margin-top: 2px;
      margin-right: 10px;
    }
    .mapboxgl-style-switcher
    /* Vue/Mapbox/Deck upgrade on 11/22 overrode this style in the switcher 
       module.  BG Image corresponds to layer-stack.svg in icons folder.
    */
      { 
          background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTQuODQ5cHgiIGhlaWdodD0iNTQuODQ5cHgiIHZpZXdCb3g9IjAgMCA1NC44NDkgNTQuODQ5IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1NC44NDkgNTQuODQ5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PGc+PGc+PHBhdGggZD0iTTU0LjQ5NywzOS42MTRsLTEwLjM2My00LjQ5bC0xNC45MTcsNS45NjhjLTAuNTM3LDAuMjE0LTEuMTY1LDAuMzE5LTEuNzkzLDAuMzE5Yy0wLjYyNywwLTEuMjU0LTAuMTA0LTEuNzktMC4zMThsLTE0LjkyMS01Ljk2OEwwLjM1MSwzOS42MTRjLTAuNDcyLDAuMjAzLTAuNDY3LDAuNTI0LDAuMDEsMC43MTZMMjYuNTYsNTAuODFjMC40NzcsMC4xOTEsMS4yNTEsMC4xOTEsMS43MjksMEw1NC40ODgsNDAuMzNDNTQuOTY0LDQwLjEzOSw1NC45NjksMzkuODE3LDU0LjQ5NywzOS42MTR6Ii8+PHBhdGggZD0iTTU0LjQ5NywyNy41MTJsLTEwLjM2NC00LjQ5MWwtMTQuOTE2LDUuOTY2Yy0wLjUzNiwwLjIxNS0xLjE2NSwwLjMyMS0xLjc5MiwwLjMyMWMtMC42MjgsMC0xLjI1Ni0wLjEwNi0xLjc5My0wLjMyMWwtMTQuOTE4LTUuOTY2TDAuMzUxLDI3LjUxMmMtMC40NzIsMC4yMDMtMC40NjcsMC41MjMsMC4wMSwwLjcxNkwyNi41NiwzOC43MDZjMC40NzcsMC4xOSwxLjI1MSwwLjE5LDEuNzI5LDBsMjYuMTk5LTEwLjQ3OUM1NC45NjQsMjguMDM2LDU0Ljk2OSwyNy43MTYsNTQuNDk3LDI3LjUxMnoiLz48cGF0aCBkPSJNMC4zNjEsMTYuMTI1bDEzLjY2Miw1LjQ2NWwxMi41MzcsNS4wMTVjMC40NzcsMC4xOTEsMS4yNTEsMC4xOTEsMS43MjksMGwxMi41NDEtNS4wMTZsMTMuNjU4LTUuNDYzYzAuNDc3LTAuMTkxLDAuNDgtMC41MTEsMC4wMS0wLjcxNkwyOC4yNzcsNC4wNDhjLTAuNDcxLTAuMjA0LTEuMjM2LTAuMjA0LTEuNzA4LDBMMC4zNTEsMTUuNDFDLTAuMTIxLDE1LjYxNC0wLjExNiwxNS45MzUsMC4zNjEsMTYuMTI1eiIvPjwvZz48L2c+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjwvc3ZnPg==) !important;
          background-position: center;
          background-repeat: no-repeat;
          background-size: 70%;
      }

</style>
