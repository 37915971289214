
<template>

  <div class="h-full w-full bg-cbp font-Open+Sans fixed top-0"> 
    <header>
      <div class="bg-white w-full h-16 pt-1 xl:pt-0 sm:h-18 xl:pb-2 lg:h-28
                    xl:h-32 2xl:h-48 xl:mx-auto grid grid-cols-2">
        <div class="col-span-1 pl-2 pt-1 xl:pt-0 ml-1 md:ml-4 2xl:ml-5">
          <a href="./" title="New Jersey Conservation Blueprint">
            <img class="block lg:hidden h-12 md:h-14 md:-mt-0.5" 
               alt="Conservation Blueprint logo" 
                 :src="plus_logo_svg">
            <img class="hidden ml-2 mt-2 xl:pb-1 lg:inline-block h-20 xl:h-28
                        2xl:h-36 xl:mt-3 2xl:mt-6"
                 alt="Conservation Blueprint logo" 
                 :src="plus_logo_svg">
          </a>
        </div>
        <div class="col-span-1 flex items-center justify-end">
          <div class="flex items-center justify-center float-right bg-cbp px-2 
                      lg:px-4 xl:px-6 2xl:px-8 my-auto mr-4 sm:mr-6 md:mr-8
                      lg:mr-10 2xl:mr-2 h-12 lg:h-20 xl:h-24 2xl:h-32">
            <a href="#/plus?cr=original"
              class="text-white text-xs sm:text-base md:text-xl xl:text-4xl
                      2xl:text-6xl">
              Go to Blueprint<img 
                class="inline ml-0.5 h-3 md:h-4 2xl:h-7 mb-3 md:mb-5 2xl:mb-8" 
                alt="Conservation Blueprint logo" 
                :src="plus_logo_white_svg">
            </a>
          </div>
        </div>
        <div class="col-span-2 bg-white text-cbp font-bold text-right pr-10
                    pb-2 pt-1 text-base 2xl:text-lg hidden md:block">
          <ul>                
            <li class="inline">
              <a  href="#plus_overview" title="Maps Overview"
            >Plus Overview
              </a>
            </li>
            <li class="inline ml-3">
              <a  href="#about_us" title="About Us">About Us</a>
            </li>
            <li class="inline ml-3">
              <a  href="#join_us" 
                 title="Contact New Jersey Conservation Blueprint">Contact 
                 Us</a>
            </li>
            <li class="inline ml-3">
              <a  href="#user_guides">
                <i class="icon-download-icon"></i>User Guides</a>
            </li>
          </ul>
        </div>
      </div>
    </header>
    <section>
      <div class="grid grid-cols-12 min-w-full text-white text-left pt-4 
                  overflow-y-auto h-screen mt-4 md:mt-12">
        <div class="col-span-12 xl:col-span-6 px-6 text-sm lg:text-xl 
                    leading-snug">
          <h2 class="text-2xl lg:text-4xl xl:text-5xl leading-tight pb-6 mr-10
                    text-white font-bold w-3/4 lg:w-1/2">
          Protecting <br class="hidden xl:block">New Jersey's
          Land and Legacy.</h2>
          <p class="mb-3">In New Jersey, the most densely populated state in the 
            U.S., it is critically important to protect our healthy soils for 
            growing food and the natural lands that safeguard our drinking 
            water, clean our air, sustain wildlife and provide places for people 
            to enjoy the outdoors.</p>
            <p>The Conservation Blueprint team is excited to launch Blueprint+ 
              (Plus), a new addition to the Conservation Blueprint suite.  Using 
              existing Blueprint priority models, Plus offers advanced planning, 
              reporting, and filtering tools. </p>
            <!-- <p>NJ Conservation Blueprint is a data-driven, interactive mapping 
             tool made possible through a partnership of The Nature Conservancy,
             Rowan University, and the New Jersey Conservation Foundation, 
             together with a collective of 21 conservation-focused groups, both 
             governmental and non-profits.</p> -->
             <div class="w-full flex flex-row mt-10 mb-4">
              <img class="w-1/3 basis-1/3 inline mx-0.5" :src="logo_1_svg">
              <img class="basis-1/3 inline mx-0.5" :src="logo_2_svg">
              <img class="basis-1/3 inline mx-0.5" :src="logo_3_svg">
             </div>
        </div>
        <div class="col-span-12 xl:col-span-6 mr-0 xl:mr-4 mt-2">
          <slide-show></slide-show>
        </div>

        <div class="col-span-12 md:col-span-6 bg-gray-50 text-left 
                    text-4xl lg:text-6xl text-neutral-700 font-semibold
                    pt-14 xl:pt-20 pb-1 xl:pb-12 px-6 md:px-12 md:border-b
                    md:border-b-slate-300"
          >Our mission</div>
        <div class="col-span-12 md:col-span-6 bg-gray-50 text-left text-xl 
                    xl:text-3xl text-neutral-700 font-normal pt-6 xl:pt-24
                    pb-4 xl:pb-12 pl-6 pr-6 xl:pr-20 md:border-b
                    md:border-b-slate-300">Our mission is 
          to accelerate preservation of the most important remaining natural and
          cultural land resources that support healthy and vibrant communities 
          in New Jersey.</div>

         <div class="col-span-12 pt-12 pl-2 bg-gray-50 text-left text-xl 
                    text-neutral-700 hidden md:block"
              id="plus_overview">
          <img class="w-20 inline" :src="dash_svg">Plus Overview   
        </div> 
        <div class="col-span-12 bg-gray-50 text-left text-2xl
                    md:text-4xl lg:text-6xl text-neutral-700 
                    font-semibold pt-6 xl:pt-21 pb-1 xl:pb-8 px-6 md:px-12"
          >What is Blueprint<img :src="plus_logo_black_svg" 
            class="h-3 md:h-5 mr-1.5 inline mb-8">?</div>
          <div class="col-span-12 bg-gray-50 text-left text-sm 
                    lg:text-xl xl:text-2xl text-neutral-700 font-normal pt-6 
                    xl:pt-5 pb-4 xl:pb-12 px-6 md:px-12 xl:px-28"><p>Using the 
                same priority models as Blueprint, Plus offers a new way of 
                visualizing the Eco, Ag and Water models by displaying priority 
                rankings on a parcel-by-parcel basis. Users can easily view, 
                identify and compare conservation values across multiple 
                parcels. In addition, we’ve made it easier to view parcel 
                details, including data about the natural features on a parcel 
                that contribute to priority rankings. A new reporting tool 
                allows for single or multiple parcel reporting for sharing with 
                others. And advanced filtering tools allow you to change the 
                weight of model inputs based on your own priorities or easily 
                identify parcels with a particular set of features, e.g. show me
                parcels greater than 50 acres that rank 5 and above in the Eco 
                model.</p>
            <h3 class="font-semibold text-sm lg:text-xl xl:text-2xl 
                        pt-6 leading-3 md:leading-normal"
              >What are the similarities and differences between 
                Blueprint and Blueprint<img :src="plus_logo_black_svg" 
                class="h-3 mr-1.5 inline mb-5 nd:mb-8">?</h3>
            <ul class="list-disc ml-8 my-2 md:my-4">
              <li>The same priorities</li>
            </ul>
            <p class="ml-8">Blueprint and Blueprint+ use the exact same models, 
              methodologies and input datasets. Key differences are that in 
              Plus, priorities are displayed parcel-by-parcel, and the Community
              Greenspace model is not yet available since we are in the process
              of updating the model.</p>
            <ul class="list-disc ml-8  my-2 my-4">
              <li>The same devices</li>
            </ul>
            <p class="ml-8">Like Blueprint, Plus is designed to work on 
              multiple devices from desktops to cell phones.  Use Plus 
              in-office for planning and out in the field to navigate 
              and explore natural features.</p>
            <ul class="list-disc ml-8  my-2 my-4">
              <li>A new way to visualize priorities</li>
            </ul>
            <p class="ml-8">The most significant difference between 
              Blueprint and Plus is in the way priorities are displayed. 
              On Plus, priorities are visualized on a parcel-by-parcel 
              basis.  On Blueprint, priorities are displayed based on 
              the approximate mapped location of natural features on the 
              ground and without reference to parcel boundaries.</p>
            <div class="md:flex w-full m-4 md:items-center md:justify-center">
              <div class="md:w-4/12">
                <img id="ex_plus_image" :src="example_plus_png" 
                     class="w-3/4 md:w-full ml-8 md:ml-0">
                <label for="ex_plus_image" class="text-sm xl:text-base w-full
                            text-center pt-3 mx-auto mb-4">Plus:
                  Priorities displayed parcel-by-parcel</label>
              </div>
              <div class="w-full md:w-2/12">
                <img :src="example_legend_png" class="w-1/6 md:w-1/2 py-2 
                                                      mx-auto">
              </div>
              <div class="md:w-4/12">
                <img id="ex_njmap_image"  :src="example_njmap_png" 
                    class="w-3/4 md:w-full ml-8 md:ml-0">
                <label for="ex_njmap_image" class="text-sm xl:text-base w-full
                            text-center pt-3 mx-auto">Blueprint:  Priorities 
                            displayed according to the approximate location of 
                            natural features 	on the
                            ground</label>
              </div>
            </div>
          </div>
          <div class="col-span-12 bg-gray-50 text-left text-sm 
                  lg:text-xl xl:text-2xl text-neutral-700 font-normal pt-6 
                  xl:pt-5 pb-4 xl:pb-12 px-6 md:px-12 xl:px-28">
            <ul class="list-disc ml-8 my-2 md:my-4">
              <li>A new way to explore which natural features contribute to a
                  priority ranking</li>
            </ul>
            <img :src="example_parcel_png" 
                class="w-9/12 lg:w-1/2 ml-16 float-left">
          </div>
          <div class="col-span-12 bg-gray-50 text-left text-sm 
              lg:text-xl xl:text-2xl text-neutral-700 font-normal pt-6 
              xl:pt-5 pb-4 xl:pb-12 px-6 md:px-12 xl:px-28">
            <ul class="list-disc ml-8 my-2 md:my-4">
              <li>Create customized reports, including multiple parcel 
                  reports</li>
            </ul>
            <p class="ml-8">You can create single parcel or multiple 
              parcel reports with or without a map and with or without 
              details about the natural features that contribute to a 
              priority ranking.  You can also save the data as a csv 
              file.</p>
            <img :src="example_report_png" 
                class="w-9/12 lg:w-1/2 ml-16 float-left mt-6">
          </div> 
          <div class="col-span-12 bg-gray-50 text-left text-sm 
                  lg:text-xl xl:text-2xl text-neutral-700 font-normal pt-6 
                  xl:pt-5 pb-4 xl:pb-12 px-6 md:px-12 xl:px-28 md:border-b
                  md:border-b-slate-300">
            <ul class="list-disc ml-8 my-2 md:my-4">
              <li>Filter priorities and customize models</li>
            </ul>
            <p class="ml-8">Use advanced filtering to identify parcels based on
              your own needs and natural resource preferences.  For example:</p>

              <div class="flex flex-row w-full m-4 items-start justify-center">
              <div class=" basis-1/2 px-1">
                <img id="ex_filter_image_1" :src="example_filter_1_png" class="w-full">
                <label for="ex_plus_image" class="text-sm xl:text-base w-full
                            text-center pt-3 pb-6  mx-auto my-4">Plus:
                  Show me parcels 50+acres that are prioritized at 8 and above 
                  in the Ag model</label>
              </div>
              <div class=" basis-1/2 px-1">
                <img id="ex_filter_image_2"  :src="example_filter_2_png" 
                    class="w-full">
                <label for="ex_njmap_image" class="text-sm xl:text-base w-full
                            text-center pt-3 mx-auto">Now show me 50+ acre
                            parcels prioritized as 8 and above in the Ag model 
                            where prime soils make up 100% of the parcel</label>
              </div>
            </div>
          </div>
        <div class="hidden md:block col-span-5 pl-3 pt-2 bg-gray-50"
              v-show="overview_selection==1">
          <img :src="first_time_user_jpg" class="float-right">
        </div>
        <div class="hidden md:block col-span-5 pl-3 pt-2 bg-gray-50"
              v-show="overview_selection==2">
          <img :src="dirt_capital_png" class="float-right">
        </div>
        <div class="hidden md:block col-span-5 pl-3 bg-gray-50"
              v-show="overview_selection==3">
          <img :src="community_open_space_jpg" class="float-right">
        </div>
   
        <div class="col-span-12 md:col-span-7 pt-7 bg-white text-center
                    xl:text-left xl:pl-12 text-cbp border-gray-200 text-sm"
             id="first_time">
             <!-- <div class="w-fit ml-5 md:ml-12 lg:ml-1 mb-2 md:mb-0 lg:inline  
                        lg:px-2 lg:mr-2 lg:border-r-2 font-bold border-cbp
                       hover:text-cbp/40 cursor-pointer"
                :class="about_selection==1?'border-b-4':'border-b-2'"
                @click="overview_selection=1"
          >FIRST-TIME USER</div> -->
          <div class="w-fit ml-5 md:ml-12 lg:ml-1 mb-2 md:mb-0 lg:inline lg:px-2 
                      lg:mr-2 lg:border-r-2 font-bold border-cbp
                       hover:text-cbp/40 cursor-pointer"
                :class="overview_selection==1?'border-b-4':'border-b-2'"
                @click="overview_selection=1"
          >FARMLAND PRESERVATION</div>
          <div class="w-fit ml-5 md:ml-12 lg:ml-1 mb-2 md:mb-0 lg:inline lg:px-2 
                      lg:mr-2 lg:border-r-2 font-bold border-cbp
                       hover:text-cbp/40 cursor-pointer"
                :class="overview_selection==2?'border-b-4':'border-b-2'"
                @click="overview_selection=2"
          >OPEN SPACE PROTECTION</div>

          <!-- <div class="bg-white text-left pb-8"
               id="about-tab-1"
                v-show="overview_selection==1">
                <h3 class="text-3xl text-neutral-700 mt-5 font-bold pl-6 
                           md:pl-12 mb-0 md:mb-3"
            >First-Time User</h3>
           <p class="text-xl text-neutral-700 ml-5 md:ml-12 py-6 md:pt-12 
                     pr-6 md:pr-12">If you’re a new user of 
              the Conservation Blueprint, you’ll be impressed by its power, ease
              of use and speed. It is your go-to source to access reliable, 
              up-to-date, statewide information for your decision making.</p>
            <p class="text-xl text-neutral-700 ml-5 md:ml-12 py-6 md:pt-12 
                      pr-6 md:pr-12">The Blueprint’s 
              science-based prioritization models identify the most critical 
              open space, farmland, and community greenspaces to be preserved 
              (not developed) in New Jersey.</p>
            <p class="text-xl text-neutral-700 ml-5 md:ml-12 py-6 md:pt-12 
                      pr-6 md:pr-12">Use the Blueprint 
              to:</p>
            <ul class="text-xl list-disc text-neutral-700 px-16 lg:px-24 
                       pt-0.5">
              <li>Discover the conservation value of land and identify its 
                  natural and cultural features</li>
              <li>View on your smartphone or tablet, in a meeting or out in the 
                  field</li>
              <li>View data at a statewide, regional, hometown or parcel
                  scale</li>
            </ul>
          </div> -->
          <div class="col-span-12 md:col-span-6 bg-white text-left pb-8"
              id="about-tab-1"
                v-show="overview_selection==1">
            <h3 class="text-3xl text-neutral-700 mt-5 font-bold pl-6 md:pl-12 
                       mb-0 md:mb-3">Farmland Preservation</h3>
            <p class="text-xl text-neutral-700 ml-5 md:ml-12 py-6 md:pt-12 
                      pr-6 md:pr-12">Preserving existing 
              farmland and our farmland soils will help us secure the future of 
              New Jersey’s agricultural economy and heritage.</p>
            <p class="text-xl text-neutral-700 ml-5 md:ml-12 py-6 md:pt-12
                      pr-6 md:pr-12">The Conservation 
              Blueprint identifies the most critical agricultural features to 
              help focus preservation in the places of highest need to slow the 
              rate of farms lost to development.</p>
            <p class="text-xl text-neutral-700 ml-5 md:ml-12 py-6 md:pt-12  
                       pr-6 md:pr-12">We provide quick, 
              easy access to the most up to date data for soils, 
              agriculturally-active lands, and preserved farmland, as well as 
              parcel data to help with farmland preservation planning and 
              individual projects.
            </p>
          </div>
          <div class="col-span-12 md:col-span-6 bg-white text-left pb-8"
              id="about-tab-2"
                v-show="overview_selection==2">
                <h3 class="text-3xl text-neutral-700 mt-5 font-bold pl-6 
                           backdrop:md:pl-12 mb-0 md:mb-3"
            >Open Space Protection</h3>
           <p class="text-xl text-neutral-700 ml-5 md:ml-12 py-6 md:pt-12 
                      pr-6 md:pr-12">Protecting our natural 
              resources is vital to our health, our quality of life, and the 
              future of New Jersey.</p>
            <p class="text-xl text-neutral-700 ml-5 md:ml-12 py-6 md:pt-12 
                      pr-6 md:pr-12">The Conservation 
              Blueprint will help you make open space preservation decisions, 
              ensuring we use our precious resources intelligently.</p>
            <p class="text-xl text-neutral-700 ml-5 md:ml-12 py-6 md:pt-12 
                      pr-6 md:pr-12">Access the most up
              to date scientific, ecological priority models and open space 
              preservation data to evaluate the importance of a parcel to the 
              health of New Jersey’s natural resources.
            </p>
          </div>
        </div>

        <div class="col-span-12 py-12 pl-2 bg-gray-50 text-left text-xl 
                    text-neutral-700 hidden md:block "
              id="about_us">
          <img class="w-20 inline" 
              :src="dash_svg">ABOUT NJ CONSERVATION BLUEPRINT 
        </div>

        <div class="col-span-12 w-full bg-cover bg-no-repeat bg-white" 
             v-bind:style="{ 'background-image': 'url(' + about_us_png + ')' }">
          <div class="border-8 border-white/50 m-8 md:m-20 pb-5 pl-4 md:pl-12 
                      mr-4 md:pr-16 lg:pr-60 mt-12 xl:pt-28 text-white 
                      text-2xl md:text-5xl lg:text-7xl text-left">
            The choices we make today shape the communities we live in tomorrow.
          </div>
        </div>

        <div class="col-span-12 md:col-span-6 bg-[#282828] text-white  
                    text-2xl font-semibold ">
          <div class="flex items-center justify-center w-full mt-8 ">
            <img :src="water_mark_svg" 
               class="w-24 h-24 lg:w-32 lg:h-32 bg-transparent">
          </div>
          <p class="ml-10 mr-10 mt-1 md:mt-4 lg:mt-6 mb-6 text-base md:text-xl
                    lg:text-2xl">Since 1985, we’ve seen an increase of 350,000 
             acres of urban land (that’s 27 football fields per day or 30 
             Central Parks per month!). Each acre of new development results in 
             the loss of an acre of farmland, forest or wetlands.</p>
        </div>
        <div class="col-span-12 md:col-span-6 pt-7 bg-white text-center
                   text-cbp border-gray-200 border-b">
          <div class="w-fit ml-5 md:ml-12 lg:ml-1 mb-2 md:mb-0 lg:inline lg:px-2 
                      lg:mr-2 lg:border-r-2 font-bold border-cbp
                       hover:text-cbp/40 cursor-pointer"
                :class="about_selection==1?'border-b-4':'border-b-2'"
                @click="about_selection=1"
          >NJ IN 2050</div>
          <div class="w-fit ml-5 md:ml-12 lg:ml-1 mb-2 md:mb-0 lg:inline lg:px-2 
                      lg:mr-2 lg:border-r-2 font-bold border-cbp 
                       hover:text-cbp/40 cursor-pointer"
                :class="about_selection==2?'border-b-4':'border-b-2'"
                @click="about_selection=2"
          >BUILD OUT</div>
          <div class="w-fit ml-5 md:ml-12 lg:ml-1 mb-2 md:mb-0 lg:inline lg:px-2  
                      lg:mr-2 lg:border-r-2 font-bold border-cbp 
                       hover:text-cbp/40 cursor-pointer"
                :class="about_selection==3?'border-b-4':'border-b-2'"
                @click="about_selection=3"
          >SUCCESS STORIES</div>
          <div class="w-fit ml-5 md:ml-12 lg:ml-1 mb-2 md:mb-0 lg:inline lg:pl-2 
                       font-bold border-cbp 
                       hover:text-cbp/40 cursor-pointer"
                :class="about_selection==4?'border-b-4':'border-b-2'"
                @click="about_selection=4"
          >PRIORITIES</div>

          <div class="bg-white text-left pb-8"
               id="about-tab-1"
                v-show="about_selection==1">
            <p class="text-xl text-neutral-700 ml-5 md:ml-12 py-6 md:pt-12 
                      pr-6 md:pr-12">What will New Jersey 
              look like in 2050? The choices we make today shape the communities
              we live in tomorrow. The Conservation Blueprint is an interactive
              mapping tool that empowers users to identify land best suited for
              conservation. These areas are crucial within New Jersey’s
              landscape to ensure clean drinking water, a sustainable
              agricultural industry, and a healthy, vibrant place for us all to
              live, work and play.</p>
          </div>
          <div class="col-span-12 md:col-span-6 bg-white text-left pb-8"
              id="about-tab-2"
                v-show="about_selection==2">
            <p class="text-xl text-neutral-700 ml-5 md:ml-12 py-6 md:pt-12 
                      pr-6 md:pr-12">As the most densely 
              populated state in the U.S., wedged between New York and 
              Philadelphia, New Jersey is challenged with unprecedented 
              development pressure. Since 1985, we’ve seen an increase of 
              350,000 acres of urban land (that’s 27 football fields per day or 
              30 Central Parks per month!). Each acre of new development results
              in the loss of an acre of farmland, forest or wetlands.</p>
            <p class="text-xl text-neutral-700 ml-5 md:ml-12 py-6 md:pt-12 
                      pr-6 md:pr-12">Fortunately, New 
              Jersey's land conservation efforts have saved over 1.3 million
              acres of land in permanent protection. As the Garden State’s 
              remaining lands are either developed or preserved in the coming 
              years, New Jersey is on track to be the first state in the nation 
              to reach build-out, where all land is either developed or
              preserved, locking-in land use patterns. Now is our time to ensure
              a healthy New Jersey for generations to come.</p>
          </div>
          <div class="col-span-12 md:col-span-6 bg-white text-left pb-8"
              id="about-tab-3"
                v-show="about_selection==3">
            <p class="text-xl text-neutral-700  ml-5 md:ml-12 py-6 md:pt-12 
                      pr-6 md:pr-12">The landscape of 
              New Jersey–our natural lands, our parks, our farms–directly 
              influence our quality of life. Fortunately, our history is rich 
              in stories from communities successfully protecting natural lands,
              farmland and historic resources for future generations. It is 
              these narratives that inspire the work we do. The Blueprint
              empowers users with data and resources to help them write new
              conservation success stories for New Jersey. Please share your 
              story with us by emailing conservationblueprint@gmail.com or click
              on the link below.</p>
            <p class="text-xl text-blue-500 px-12 pt-5 hover:underline
                      cursor-pointer">
              <a href="mailto:conservationblueprint@gmail.com?subject=New Jersey
              Conservation Blueprint: Success Story" title="Contact New Jersey
              Conservation Blueprint" target="_blank">Share your success 
              story.</a>
            </p>
          </div>
          <div class="col-span-12 md:col-span-6 bg-white text-left pb-8"
              id="about-tab-4"
                v-show="about_selection==4">
            <p class="text-xl text-neutral-700 ml-5 md:ml-12  py-6 md:pt-12 
                      pr-6 md:pr-12">The Conservation 
              Blueprint has been developed by a statewide partnership, led by 
              The Nature Conservancy, New Jersey Conservation Foundation and 
              the Rowan University Geospatial Research Lab, that includes 21 
              conservation-focused organizations, both governmental and 
              non-profit. The blueprint presents four unique priority models 
              that characterize, and rank land based on unique considerations to
              identify the most important places for agriculture, ecology, water
              quality and people.
            </p>
          </div>
        </div>
        
        <div class="col-span-12 py-12 pl-2 bg-gray-50 text-left text-xl 
                    text-neutral-700 hidden md:block">
          <img class="w-20 inline" 
              :src="dash_svg">LAND AND LEGACY 
        </div>

        <div class="col-span-12 w-full bg-gray-50 text-neutral-700">
          <p class="w-full lg:w-5/6 px-8 lg:px-20 pt-8 md:pt-0 text-2xl
                    lg:text-6xl font-semibold">You found a place to 
              call home. Let’s protect it! Help New Jersey
              be a model for the rest of the country.</p>
        </div>

        <div class="col-span-12 lg:col-span-6 bg-gray-50 text-base lg:text-xl 
                    px-8 lg:px-20 lg:border-b lg:border-slate-300">
          <p class="text-neutral-700 pt-5 lg:pt-12 pb-3 lg:pb-8">A balance of 
            protected lands and intelligent growth that benefits all New 
            Jerseyans for generations is the legacy that can inspire action. 
            Knowing that you played a part in setting this in motion, that you 
            became part of something bigger than yourself, will pay dividends in 
            fulfillment.</p>
          <p class="text-[#77990f] lg:pb-4">When you’re out camping, hiking,
            or simply appreciating backyard nature–take a moment to hashtag a  
            social media photo with #njblueprint #njlandlegacy. Dr. Seuss put it  
            best in The Lorax: “Who speaks for the trees?” Use the power of 
            social media to use your voice. And maybe even change how New Jersey  
            is perceived across the country, one hashtagged image at a time.</p>
        </div>
        <div class="col-span-12 lg:col-span-6 bg-gray-50 py-12 h-96 lg:h-auto  
                    text-center lg:border-b lg:border-slate-300">
          <div class="text-3xl text-white font-semibold bg-[#868f57] h-full 
                      flex items-center justify-center">
            #njblueprint #njlandlegacy
          </div>
        </div>

        <div class="col-span-12 pt-12 pb-0 lg:pb-12 pl-2 bg-gray-50 text-left 
                    text-xl text-neutral-700 hidden md:block">
          <img class="w-20 inline" 
              :src="dash_svg">BLUEPRINT FOR A BETTER NEW JERSEY
        </div>

        <div class="col-span-12 w-full bg-gray-50 pb-4 border-b 
                   border-slate-300"
             id="video">
          <div class="w-full hidden -mt-20 lg:-mt-0 md:flex items-center 
                      justify-center">
            <iframe src="https://player.vimeo.com/video/330501685" width="1280" 
            height="720" frameborder="0" allow="autoplay; fullscreen">
            </iframe>
          </div>
          <div class="w-full flex md:hidden items-center justify-center px-20">
            <iframe src="https://player.vimeo.com/video/330501685" 
                    frameborder="0" allow="autoplay; fullscreen">
            </iframe>
          </div>
        </div>

        
        <div class="col-span-12 pt-12 pb-0 lg:pb-12 pl-2 bg-gray-50 text-left 
                    text-xl text-neutral-700 hidden md:block"
              id="join_us">
          <img class="w-20 inline" 
              :src="dash_svg">CONTACT US 
        </div>
        <div class="col-span-12 md:col-span-6 bg-gray-50 text-center text-4xl 
                    lg:text-6xl text-neutral-700 font-semibold pt-4 lg:pt-16 
                    pb-4 md:pb-12 md:border-b md:border-slate-300"
          >Join us today.</div>
        <div class="col-span-12 md:col-span-6 bg-gray-50 text-left text-xl 
                    lg:text-3xl text-neutral-700 font-normal pt-0 lg:pt-16  
                     pb-12 pl-5 pr-5 md:pr-24 border-b border-slate-300">Thank   
          you for your interest inNew Jersey’s conservation goals. We hope you 
          will be enriched bybeing part of our community.
          <p class="pt-5 text-center md:text-left cursor-pointer">
              <a class="border-b-2 hover:border-b-4 border-neutral-700"
              href="mailto:conservationblueprint@gmail.com?subject=New Jersey
              Conservation Blueprint: Join Us" title="Contact New Jersey
              Conservation Blueprint" target="_blank">Connect.</a>
            </p>
        </div>
        
        <div class="col-span-12 py-12 pl-2 bg-gray-50 text-left text-xl 
                    text-neutral-700 hidden md:block"
              id="user_guides">
          <img class="w-20 inline" 
              :src="dash_svg">USER GUIDES 
        </div>

        <div class="col-span-12 lg:col-span-5 bg-gray-50 text-2xl px-7  
                    lg:px-20 text-neutral-700">
          <div class="col-span-12 lg:col-span-9 lg:col-start-3 text-left pl-3 
                      lg:pl-0.5 mt-3">
            <div class="md:hidden text-left font-semibold pb-10">USER 
              GUIDES</div>
            <ul class="list-disc pb-12 md:pb-0">
              <li class="mb-2">
                <a href="/docs/About_Blueprint_Plus.pdf" 
                   target="_blank" id="about_pdf"
                   class="border-b-2 hover:border-b-4 border-neutral-700">
                About Plus<img 
                 class="inline-block h-2 mb-3"
                 alt="Conservation Blueprint logo" 
                 :src="plus_logo_white_svg"> - PDF
              </a></li>
              <li>
                <a href="/docs/Blueprint_Plus_Quick_Start_Guide.pdf" 
                target="_blank" id="quick_guide_pdf"
                   class="border-b-2 hover:border-b-4 border-neutral-700">
                Quick Start Guide - PDF
              </a></li>
            </ul>
          </div>     
        </div>
        <div class="col-span-12 lg:col-span-7 bg-gray-50">    
          <div class="col-span-10 col-start-2 lg:col-start-2 text-left pl-8 
                      lg:pl-0.5 pt-8 lg:pt-0 text-neutral-700 text-xl">
            Introduction to Blueprint<img 
                 class="inline-block h-2 mb-3"
                 alt="Conservation Blueprint logo" 
                 :src="plus_logo_white_svg"> - Video
          </div>         
          <div class="col-span-12 md:col-span-9 md:col-start-2 text-left my-2 
                      flex justify-center">
          <iframe width="560" height="315"
          src="https://www.youtube.com/embed/sjlc6fvKoxQ?si=tE1CmevlTK98vfqx" 
          title="YouTube video player" 
          frameborder="0" 
          allow="autoplay;clipboard-write;encrypted-media;picture-in-picture" 
          allowfullscreen></iframe>
          </div> 
          <div class="col-span-10 col-start-2 lg:col-start-2 text-left pl-8
                      lg:pl-0.5 pt-8 lg:pt-0 mt-3 text-neutral-700 text-xl">
            Find Habitat Connecting Parcels
          </div> 
          <div class="col-span-12 md:col-span-9 md:col-start-2 text-left my-2 
                      flex justify-center">
            <iframe width="560" height="315" 
            src="https://www.youtube.com/embed/5scHQyppbsM?si=1wMCbHPsVvAtJ35v"
            title="YouTube video player" 
            frameborder="0" 
            allow="autoplay;clipboard-write;encrypted-media;picture-in-picture" 
            allowfullscreen></iframe>
          </div>
          <div class="col-span-10 col-start-2 lg:col-start-2 text-left pl-8 
                      lg:pl-0.5 pt-8 lg:pt-0 mt-3 text-neutral-700 text-xl">
            Using Blueprint<img 
                 class="inline-block h-2 mb-3"
                 alt="Conservation Blueprint logo" 
                 :src="plus_logo_white_svg"> Search
          </div>         
          <div class="col-span-12 md:col-span-9 md:col-start-2 text-left my-2 
                      flex justify-center">
            <iframe width="560" height="315" 
            src="https://www.youtube.com/embed/fRDnTx8L3d0?si=rF9pQ9SCtgZIPxJN" 
            title="YouTube video player" 
            frameborder="0" 
            allow="autoplay;clipboard-write;encrypted-media;picture-in-picture" 
            allowfullscreen></iframe>
          </div>
        </div>
        <div class="col-span-12 w-full bg-gray-50 text-left text-xl 
                    md:text-6xltext-neutral-700 font-semibold py-16 pl-6 
                    md:pl-24 border-b border-slate-300 text-neutral-700">
          <p class="w-full">Disclaimer</p>
          <p class="w-full text-sm md:text-2xl font-normal pb-2 pr-6 md:pr-24 
                    pt-4 md:pt-12"
             >The Conservation 
            Blueprint utilizes best available data from a variety of sources. 
            These sources can be made available so users may assess the 
            integrity and objectivity of the data. While attention is given to 
            present the most up-to-date information, The Conservation Blueprint 
            Project Team, and its funders, assume no responsibility for the 
            spatial accuracy, completeness or timeliness of data used, and 
            expressly disclaim any and all responsibility for errors, 
            misinterpretations, omissions or other inconsistencies depicted 
            arising from or otherwise related to the maps maintained within 
            this site.</p>
            <p class="w-full text-sm md:text-2xl font-normal pb-2 pr-6 md:pr-24 
                    pt-4 md:pt-12">Maps and data 
            sets found on this site are for planning activities only and cannot 
            and should not be used for any regulatory purposes - this applies 
            to both the parcel and state-wide levels. The information on this 
            website should be used only as a guide; an on-site investigation is 
            the only true way to know which features exist on the ground.</p>
          <p class="w-full text-sm md:text-2xl font-normal pb-2 pr-6 md:pr-24 
                    pt-4 md:pt-12">Priority Land 
            rankings for each theme were determined by The Conservation 
            Blueprint Project Team in conjunction with the Conservation 
            Blueprint Steering Committee and corresponding Advisory Committees. 
            Priority Lands and Rankings reflect the collaborative input, 
            deliberation and agreed upon determinations of the aforementioned 
            groups. Users acknowledge that Blueprint rankings are not linked to 
            any other state ranking system, and are not directly linked to any 
            funding sources. Blueprint rankings do not replace a municipality or 
            county’s own prioritization.</p>
          <p class="w-full text-sm md:text-2xl font-normal pb-2 pr-6 md:pr-24 
                    pt-4 md:pt-12">Full methodology 
            reports will be available on the Conservation Blueprint website. 
            Revisions to methodology will occur periodically based upon the 
            inclusion of updated or new data sets, as well as feedback from the 
            Conservation Blueprint Steering and Advisory Committees.</p>
          <p class="w-full text-sm md:text-2xl font-normal pb-2 pr-6 md:pr-24 
                    pt-4 md:pt-12">The Conservation 
            Blueprint Project Team would appreciate feedback on any errors that 
            are discovered when using this site by contacting us at 
            <a href="mailto:conservationblueprint@gmail.com?subject=New Jersey
              Conservation Blueprint: Disclaimer" title="Contact New Jersey
              Conservation Blueprint" target="_blank" class="hover:underline">
              conservationblueprint@gmail.com</a>.</p>
        </div>
        <div class="col-span-12 py-12 pl-2 bg-gray-50 text-left text-xl 
                    text-neutral-700 hidden md:block">
          <img class="w-20 inline" 
              :src="dash_svg">STEERING COMMITTEE 
        </div>
        <div class="md:hidden font-semibold pt-10 pb-4 col-span-12
                    bg-gray-50 text-neutral-700 text-center"
        >STEERING COMMITTEE</div>
        <div class="col-span-12 w-full bg-gray-50 text-neutral-700">
          <p class="px-2 md:px-20 pb-5 md:pb-16 text-lg md:text-2xl 
                    font-semibold">The Conservation 
            Blueprint would not be possible without the creative, innovative 
            thinking from our dedicated steering committee and advisory 
            committees. Made up of federal, state and non-profit organizations, 
            New Jersey’s natural, agricultural and cultural resources are 
            improved daily by these results-focused organizations.</p>
        </div>

        <div class="col-span-12 w-full border-b border-slate-300 pb-4"
             v-bind:style="{ 
                  'background-image': 'url(' + steering_committee_png + ')' }">
          <div class="grid grid-cols-3 bg-white/80 mx-3 md:mx-16 px-8 py-12 
                     text-neutral-700 text-sm text-left">
            <div class="col-span-3 md:col-span-1">
              <h4 class="font-bold">Steering Committee</h4>
              <ul class="njcb-list">
                <li>Association of New Jersey Environmental Commissions 
                    (ANJEC)</li>
                <li>D&amp;R Greenway Land Trust</li>
                <li>Delaware Valley Regional Planning Commission</li>
                <li>Ducks Unlimited</li>
                <li>Highlands Council</li>
                <li>Isles</li>
                <li>Land Trust Alliance</li>
                <li>Monmouth Conservation Foundation</li>
                <li>Natural Lands</li>
                <li>New Jersey Conservation Foundation</li>
                <li>NJ Audubon</li>
                <li>NJDEP Green Acres Program</li>
                <li>NJDEP State Historic Preservation Office</li>
                <li>Rowan University</li>
                <li>Rutgers University</li>
                <li>State Agricultural Development Committee</li>
                <li>The Land Conservancy of NJ</li>
                <li>The Nature Conservancy</li>
                <li>The Watershed Institute</li>
                <li>Trust for Public Land</li>
                <li>U.S. Fish and Wildlife Service</li>
                <li>USDA Natural Resource Conservation Service</li>
              </ul>
            </div>
            <div class="col-span-3 md:col-span-1">

              <h4 class="font-bold">Agricultural Advisory Committee</h4>
              <ul class="njcb-list">
                <li>New Jersey Conservation Foundation</li>
                <li>Rowan University</li>
                <li>State Agricultural Development Committee</li>
                <li>USDA Natural Resource Conservation Service</li>

              </ul>
              <h4 class="font-bold">Community Greenspace Advisory Committee</h4>
              <ul class="njcb-list">
                <li>Association of New Jersey Environmental Commissions 
                    (ANJEC)</li>
                <li>D&amp;R Greenway Land Trust</li>
                <li>Delaware Valley Regional Planning Commission</li>
                <li>Isles</li>
                <li>New Jersey Conservation Foundation</li>
                <li>NJ DEP Environmental Health Division</li>
                <li>NJ DEP Green Acres Program</li>
                <li>NJDEP State Historic Preservation Office</li>
                <li>Rowan University</li>
                <li>The Nature Conservancy</li>
                <li>Trust for Public Land</li>
              </ul>
            </div>
            <div class="col-span-3 md:col-span-1">
              <h4 class="font-bold">Science Advisory Committee</h4>
              <ul class="njcb-list">
                <li>New Jersey Conservation Foundation</li>
                <li>NJ Audubon</li>
                <li>NJDEP Division of Fish&amp;Wildlife, Bureau of Land 
                    Management</li>
                <li>NJDEP Division of Fish and Wildlife Endangered and Nongame 
                    Species Program (ENSP)</li>
                <li>NJDEP Division of Science, Research and Environmental 
                    Health</li>
                <li>NJDEP Green Acres Program</li>
                <li>NJDEP Office of Environmental Review</li>
                <li>NJDEP Office of Natural Lands Management</li>
                <li>Raritan Headwaters</li>
                <li>Rowan University</li>
                <li>Stony Brook Millstone Watershed Association</li>
                <li>The Nature Conservancy</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-span-12 py-12 pl-2 bg-gray-50 text-left text-xl 
                    text-neutral-700 hidden md:block">
          <img class="w-20 inline" 
              :src="dash_svg">ABOUT NJ CONSERVATION BLUEPRINT 
        </div>

        <div class="col-span-12 w-full flex justify-center items-center pb-4
                   bg-gray-50 border-b border-gray-200">
          <div class="w-full lg:w-1/2 grid grid-cols-2 md:grid-cols-3">
            <div class="bg-white col-span-1 my-1 mx-2">
              <a href="https://www.grdodge.org/" target="_blank">
                <img :src="dodge_png">
              </a>
            </div>
            <div class="bg-white col-span-1 my-1 mx-2">
              <a href="https://academics.rowan.edu/see/departments/geography/gis/geolab/index.html" 
                 target="_blank">
                <img :src="geolab_png">
              </a>
            </div>
            <div class="bg-white col-span-1 my-1 mx-2">
              <a href="https://fmkirbyfoundation.org/" target="_blank">
                <img :src="kirby_png">
              </a>
            </div>
            <div class="bg-white col-span-1 my-1 mx-2">
              <a href="https://www.nature.org/en-us/" target="_blank">
                <img :src="tnc_png">
              </a>
            </div>
            <div class="bg-white col-span-1 my-1 mx-2">
              <a href="https://www.njconservation.org/" target="_blank">
                <img :src="njcf_png">
              </a>
            </div>
            <div class="bg-white col-span-1 my-1 mx-2">
              <a href="https://www.williampennfoundation.org/" target="_blank">
                <img :src="wpf_png">
              </a>
            </div>
            <div class="bg-white col-span-1 my-1 mx-2">
              <a href="https://www.state.nj.us/gspt/" target="_blank">
                <img :src="gspt_png">
              </a>
            </div>
            <div class="bg-white col-span-1 my-1 mx-2">
              <a href="https://www.njhi.org/" target="_blank">
                <img :src="njhi_png">
              </a>
            </div>
            <div class="bg-white col-span-1 my-1 mx-2">
              <a href="https://www.nj.gov/agriculture/sadc/" target="_blank">
                <img :src="sadc_png">
              </a>
            </div>
          </div>
        </div>

        <div class="col-span-12 py-12 pl-2 bg-gray-50 text-left text-xl 
                    text-neutral-700 hidden md:block">
          <img class="w-20 inline" 
              :src="dash_svg">WE ARE SOCIAL
        </div>
        <div class="col-span-12 w-full bg-gray-50 text-left text-3xl
                    lg:text-6xl text-neutral-700 font-semibold py-8 lg:py-16 
                    pl-4 md:pl-24 border-b border-slate-300">
          <p class="w-full lg:w-1/2">Sharing<br class="hidden lg:block">
            encouraged.</p>
          <p class="w-full lg:w-1/2 text-2xl font-normal py-2 pr-24">If you are 
             passionate about conservation and connecting people with nature, 
             please use the social media links below to join our growing 
             community.</p>
        </div>

        <div class="col-span-12 bg-[#282828] grid grid-cols-2 bg-cover bg-top  
                    bg-no-repeat pb-36" 
             v-bind:style="{'background-image': 'url(' + footer_bg_png + ')' }">
          <div class="col-span-2 lg:col-span-1 text-white text-xl 
                     font-semibold text-center">
            <div class="w-full flex justify-center">
              <img :src="water_mark_svg" 
                    class="mt-16 w-40 h-40">
            </div>
            <div class="mt-6 text-center">engage. protect. restore.
            </div>
          </div>
          <div class="col-span-2 lg:col-span-1 text-white text-xl 
                      font-semibold ">
            <div class="col-span-2 lg:col-span-1 text-center lg:text-left
                       mt-16 font-semibold">Start a conversation
              <p class="text-xl pt-5 cursor-pointer">
                <a class="border-b-2 hover:border-b-4 border-white"
                href="mailto:conservationblueprint@gmail.com?subject=New Jersey
                Conservation Blueprint: Start a conversation" 
                title="Contact New Jersey
                Conservation Blueprint" target="_blank"
                >conservationblueprint@gmail.com</a>
              </p>
            </div>
            <div class="col-span-2 lg:col-span-1 text-center lg:text-left
                        mt-16 pb-32 font-semibold">Social
              <ul class="text-xl pt-5 ">
                <li><a class="border-b-2 hover:border-b-4 border-white" 
                      href="https://www.instagram.com/njconservationblueprint/" 
                      title="Check out our Instagram" 
                      target="_blank">Instagram</a></li>
                <li><a class="border-b-2 hover:border-b-4 border-white" 
                      href="https://twitter.com/njblueprint" 
                      title="Visit us on Twitter" 
                      target="_blank">Twitter</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>  

    </section>
    <div class="w-full h-8 bg-white sticky bottom-0">
      <button class="border-0 pt-0.5 bg-white w-48 cursor-pointer float-right
                     transition-colors text-slate-400 hover:bg-slate-100"
              type="submit"
              @click="handleLogin"
        >Administrator
      </button>
    </div>
  </div>
</template>

<script>
import about_us_png from "../assets/img/blueprint/about-us.png"
import CarouselComponent from './splash/CarouselComponent.vue'
import community_open_space_jpg from 
                          "../assets/img/blueprint/community-open-space.jpg"
import dash_svg from "../assets/img/icons/dash.svg" 
import dirt_capital_png from "../assets/img/blueprint/dirt-capital.png"
import dodge_png from "../assets/img/blueprint/dodge.png"
import example_filter_1_png from "../assets/img/misc/filter_example_1.png"
import example_filter_2_png from "../assets/img/misc/filter_example_2.png"
import example_legend_png from "../assets/img/misc/legend_example.png"
import example_njmap_png from "../assets/img/misc/njmap_example.png"
import example_parcel_png from "../assets/img/misc/parcel_example.png"
import example_plus_png from "../assets/img/misc/plus_example.png"
import example_report_png from "../assets/img/misc/report_example.png"
import first_time_user_jpg from "../assets/img/blueprint/first-time-user.jpg"
import footer_bg_png from "../assets/img/blueprint/footer-bg.png"
import geolab_png from "../assets/img/blueprint/geolab.png"
import kirby_png from "../assets/img/blueprint/kirby.png"
import gspt_png from "../assets/img/blueprint/gspt-sm.png"
import logo_1_svg from "../assets/img/blueprint/logo-list-1.svg" 
import logo_2_svg from "../assets/img/blueprint/logo-list-2.svg" 
import logo_3_svg from "../assets/img/blueprint/logo-list-3.svg"
import njcf_png from "../assets/img/blueprint/new-jersey-conservation.png"
import njhi_png from "../assets/img/blueprint/njhi-square-b.png"
import plus_logo_black_svg from "../assets/img/logos/plus_black_no_text.svg" 
import plus_logo_white_svg from "../assets/img/logos/plus_white_no_text.svg" 
import plus_logo_svg from "../assets/img/logos/plus_full_logo.svg"
import sadc_png from "../assets/img/blueprint/sadc-sm.png"
import steering_committee_png from 
                          "../assets/img/blueprint/steering-committee-bg.png"
import tnc_png from "../assets/img/blueprint/nature-consevancy.png"
import water_mark_svg from 
                   "../assets/img/blueprint/water-mark-logo-tiles-about.svg" 
import wpf_png from "../assets/img/blueprint/wpf.png"                        

export default {

  name: "HomeComponent",
  
  components: {
    "slide-show": CarouselComponent
  },
  
    data(){
    return {
      about_us_png,
      about_selection: 1,
      community_open_space_jpg,
      dash_svg,
      dirt_capital_png,
      dodge_png,
      example_filter_1_png,
      example_filter_2_png,
      example_legend_png,
      example_njmap_png,
      example_parcel_png,
      example_plus_png,
      example_report_png,
      first_time_user_jpg,
      footer_bg_png,
      geolab_png,
      gspt_png,
      kirby_png,
      logo_1_svg,
      logo_2_svg,
      logo_3_svg,
      njcf_png,
      njhi_png,
      overview_selection: 1,
      plus_logo_black_svg,
      plus_logo_white_svg,
      plus_logo_svg,
      sadc_png,
      steering_committee_png,
      tnc_png,
      water_mark_svg,
      wpf_png
    }
  },

  methods: {
    handleLogin() {
      this.$auth0.loginWithRedirect({
        appState: {
          target: "/Admin",
        },
      });
    },
  }

}
</script>
