import { type } from "jquery";

class RasterFeatures {

  constructor(map, label_layer) {
    
    this.LABELS = label_layer;
    this.LAYER_ID = 'feature-raster';
    this.MAP = map;
    this.MAX_ZOOM = 12;
    this.MIN_ZOOM = 6;
    this.LAYER_TYPE = 'raster';
    this.SOURCE_ID = 'raster-feature-source';

    
    this.SOURCES =  {

      'ag-raster-os': {
        'type': 'raster',
        'tiles': [
          'https://dm83832tinp1c.cloudfront.net/' +
                'model_rasters/ag_os/{z}/{x}/{y}.png'
        ],
        'tileSize': 256
      },

      'ag-raster-no-os': {
        'type': 'raster',
        'tiles': [
          'https://dm83832tinp1c.cloudfront.net/' +
                'model_rasters/ag_no_os/{z}/{x}/{y}.png'
        ],
        'tileSize': 256
      },

      'eco-raster-os': {
        'type': 'raster',
        'tiles': [
          'https://dm83832tinp1c.cloudfront.net/' +
                'model_rasters/eco_os/{z}/{x}/{y}.png'
        ],
        'tileSize': 256
      },

      'eco-raster-no-os': {
        'type': 'raster',
        'tiles': [
          'https://dm83832tinp1c.cloudfront.net/' +
                'model_rasters/eco_no_os/{z}/{x}/{y}.png'
        ],
        'tileSize': 256
      },

      'wa-raster-os': {
        'type': 'raster',
        'tiles': [
          'https://dm83832tinp1c.cloudfront.net/' +
                'model_rasters/wa_os/{z}/{x}/{y}.png'
        ],
        'tileSize': 256
      },

      'wa-raster-no-os': {
        'type': 'raster',
        'tiles': [
          'https://dm83832tinp1c.cloudfront.net/' +
                'model_rasters/wa_no_os/{z}/{x}/{y}.png'
        ],
        'tileSize': 256
      }
    };
  }

  addRasterLayer(id, openspace){

    const source = this.getSourceId(id, openspace);
  
    const layer = {
      'id': this.LAYER_ID,
      'type': this.LAYER_TYPE,
      'source': this.SOURCE_ID,
      'minzoom': this.MIN_ZOOM,
      'maxzoom': this.MAX_ZOOM,
      
    }
    const before_layer = this.getBeforeLayer();

    this.removeRasterLayer();
    this.MAP.addSource(this.SOURCE_ID, this.SOURCES[source]);
    this.MAP.addLayer(layer, before_layer?before_layer:this.LABELS);
  }

  getBeforeLayer(){
    const layers = this.MAP.getStyle().layers;
    const idx = layers.findIndex(l => l.id === 'feature-raster');

    if(idx == -1){
      return this.LABELS;
    }else{
      return layers[idx + 1] && layers[idx + 1].id;
    }
  }

  getSourceId(id, openspace){
    if(openspace){
      return (id?id:'eco') + '-raster-os';
    }else{
      return (id?id:'eco') + '-raster-no-os';
    }
  }

  removeRasterLayer(){

    if(this.MAP.getLayer(this.LAYER_ID)){
      this.MAP.removeLayer(this.LAYER_ID);
    }

    if (this.MAP.getSource(this.SOURCE_ID)) {
      this.MAP.removeSource(this.SOURCE_ID);
    }
  }

  toggleRasterLayer(visible, selected, openspace){
    if(visible){
      this.addRasterLayer(selected, openspace);
    }else{
      this.removeRasterLayer();
    }
  }

  setOpacity(e){
    this.map.setPaintProperty(
      this.overlay.layer.id,
      'raster-opacity',
      parseInt(e.target.value, 10) / 100
    );
  } 
}

export {RasterFeatures};