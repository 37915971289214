<script>

    export default class OpacityControl{
        onAdd(map){

            this._map = map;

            this._container = document.createElement('div');
            this._container.id = 'blueprint-opacity-control';
            this._container.classList.add('block');
            this._container.classList.add('bg-white');
            this._container.classList.add('w-20');
            this._container.classList.add('lg:h-7');
            this._container.classList.add('md:h-6');
            this._container.classList.add('h-5');
            this._container.classList.add('py-0.5');
            this._container.classList.add('md:py-0');
            this._container.classList.add('ml-3');
            this._container.classList.add('border-2');
            this._container.classList.add('md:border-1');
            this._container.classList.add('border-gray-700');
            this._container.classList.add('md:border-gray-400');
            this._container.classList.add('sm:border');
            this._container.classList.add('rounded-lg');
            this._container.classList.add('shadow-lg');
            this._container.classList.add('drop-shadow-lg');
            this._container.classList.add('clear-both');
            this._container.classList.add('hover:bg-gray-100');
            this._container.classList.add('bg-opacity-90');
            this._container.classList.add('mapboxgl-ctrl');
            this._container.setAttribute('title', 'Opacity');
            this._feature_level = document.createElement('div');
            this._feature_level.id= 'feature-level-container';
            this._feature_level.classList.add('h-8');
            this._feature_level.classList.add('min-h-max');
            this._feature_level.classList.add('w-full');
            this._feature_level.classList.add('bg-transparent');
            this._input = document.createElement('input');
            this._input.type = 'range';
            this._input.min = 0;
            this._input.max = 100;
            this._input.step = 10;
            this._input.value = 80;
            this._input.classList.add('block');
            this._input.classList.add('ml-2');
            this._input.classList.add('-mt-0.5');
            this._input.classList.add('md:mt-0.5');
            this._input.classList.add('lg:mt-1');
            this._input.classList.add('w-14');
            this._input.setAttribute('id', 'opacity-ctl');
            this._input.setAttribute('title', 'Opacity');
            this._container.appendChild(this._input);
            this._container.appendChild(this._feature_level);

            return this._container;
        }
        onRemove(){
            this._container.parentNode.removeChild(this._container);
            this._map = undefined;
        }
    }

</script>  