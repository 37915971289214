<template>

<div class="w-full h-auto text-xs md:text-sm lg:text-base xl:text-xl 
              2xl:text-2xl pt-0.5 pb-1.5 2xl:my-2 rounded-[0.4375rem]"
       :class="{'bg-model_comp_bg_water_closed': !showing,
                'drop-shadow-md rounded bg-model_comp_bg_water_open': showing}">
    <div title="Overlay" class="mx-1 grid grid-cols-12 text-left"
         :class="{'mb-8': showing}">
      <div 
        class=" col-span-1 text-blue-700 text-center"
        :class="{'opacity-100': showing,
                'opacity-0': !showing,
                'text-gray-300': zoom < overlay.source.minzoom,
                'text-blue-700': showing && zoom >= overlay.source.minzoom,
                'text-blue-400': !showing && zoom >= overlay.source.minzoom}">
        &#10003;
      </div>
      <div 
        @mouseover="hover_title = true" 
        @mouseout="hover_title = false"
        title="Displays the Impervious Surface Overlay"
        class="col-span-9 text-sm md:text-sm lg:text-base xl:text-xl 
               2xl:text-2xl"
        :class="{'text-gray-300': zoom < overlay.source.minzoom,
                'text-blue-700': showing && zoom >= overlay.source.minzoom,
                'text-blue-400': !showing && zoom >= overlay.source.minzoom,
                'cursor-pointer': hover_title && zoom >= overlay.source.minzoom,
                'cursor-default': !hover_title,
                'underline': hover_title && (zoom >= overlay.source.minzoom ||
                                              showing)}"
        @click="toggleLayer">Impervious Surface 
      </div>
      <div class="text-xs xl:text-sm 2xl:text-lg -mt-0.5 ml-1 
                  col-span-9 col-start-2"
           :class="{'text-gray-300': zoom < overlay.source.minzoom,
                     'text-neutral-400': zoom >= overlay.source.minzoom}"
      ><a v-bind:href="source_data.source_info_url" 
          target="_blank"
          title="Information"
          v-if="source_data.source_info_url"
          id="source_link" class="hover:underline">(NJDEP, 2015)</a>
        <span v-else>(NJDEP, 2015)</span>
      </div>
      <div title="Opacity" 
           class="text-left col-span-2 mr-4 ml-1" 
           v-show="showing && zoom >= overlay.source.minzoom">
        <input type="range" value="80" 
              class="h-1 w-full bg-gray-200 rounded-lg appearance-none 
                     cursor-pointer range-sm dark:bg-gray-700"
              @change="setOpacity($event)">
      </div>
      <div class="col-span-1">&nbsp;</div>
      <div class="col-span-10 col-start-2 py-1 2xl:py-2 pr-5 text-xs md:text-sm 
                  lg:text-base 2xl:text-2xl text-gray-700"
           v-show="showing && zoom >= overlay.source.minzoom">
        {{ source_data.description }}
      </div>
      <div class="col-span-12 col-start-2 grid grid-cols-6 py-1 text-xs
                  md:text-base 2xl:text-2xl"
           v-show="showing && zoom >= overlay.source.minzoom">
        <!-- <span class="col-span-6 mb-0.5 text-xs md:text-sm lg:text-base
                     2xl:text-2xl text-gray-500">
          Downloaded {{source_data.updated}}
        </span>
        <a target="_blank" 
           :href="source_data.source_info_url"
          @mouseover="hover_source = true" 
          @mouseout="hover_source = false"
          class="col-span-3"
          :class="{'text-blue-700': hover_source,
                  'text-blue-500': !hover_source,
                  'cursor-pointer': hover_source,
                  'cursor-default': !hover_source,
                  'underline': hover_source}"
          id="source_impervious_surface">
          Source
        </a> -->
        <a target="_blank" 
           :href="source_data.metadata_url"
          @mouseover="hover_meta = true" 
          @mouseout="hover_meta = false"
          class="col-span-3"
          :class="{'text-blue-700': hover_meta,
                  'text-blue-500': !hover_meta,
                  'cursor-pointer': hover_meta,
                  'cursor-default': !hover_meta,
                  'underline': hover_meta}"
          id="metadata_impervious_surface">
           Metadata
        </a>
        <span class="col-span-2">&nbsp;</span>
      </div>
      <div class="col-span-1 col-start-2 bg-is_buildings h-4 mt-2 mx-1"
           v-show="showing && zoom >= overlay.source.minzoom"></div>
      <div class="h-4 mt-2 2xl:mt-0 pl-2 col-span-10 align-middle text-xs 
                  md:text-sm lg:text-sm xl:text-base 2xl:text-2xl"
           v-show="showing && zoom >= overlay.source.minzoom">
        Building
      </div>
      <div class="col-span-1 col-start-2 bg-is_roads bg-opacity-80 
                  h-4 mt-2 mx-1"
           v-show="showing && zoom >= overlay.source.minzoom">
      </div>
      <div class="h-4 mt-2 2xl:mt-0 pl-2 col-span-10 align-middle text-xs 
                  md:text-sm lg:text-sm xl:text-base 2xl:text-2xl"
           v-show="showing && zoom >= overlay.source.minzoom">
        Road
      </div>
      <div class="col-span-1 col-start-2 bg-is_other  bg-opacity-70
                  h-4 mt-2 mx-1"
           v-show="showing && zoom >= overlay.source.minzoom"></div>
      <div class="h-4 mt-2 2xl:mt-0 pl-2 col-span-10 align-middle text-xs 
                  md:text-sm lg:text-sm xl:text-base 2xl:text-2xl"
           v-show="showing && zoom >= overlay.source.minzoom">
        Other
      </div>
    </div>
  </div>
</template>

<script>

  import axios from 'axios';
import { timeThursdays } from 'd3';

  export default {

      name: 'IsComponent',

      data(){
        return{
            data_error: '',
            data_name: 'impervious_surface',
            hover_meta: false,
            hover_source: false,
            hover_title: false,
            label: 'Impervious Surface = New Jersey DEP 2015',
            overlay: {
              'source': {
                'name': 'is2015-raster',
                'type': 'raster',
                'tiles': [
                  'https://dm83832tinp1c.cloudfront.net/' +
                  'is15-rast/{z}/{x}/{y}.png'
                ],
                'tileSize': 256,
                'minzoom': 6,
                'maxzoom': 15,
              },
              'layer': {
                'id': 'lulc-impervious-surface-raster',
                'type': 'raster',
                'source': 'is2015-raster',
                'paint': {
                  'raster-opacity': .8
                }
              }
            },
            showing: false,
            source_data: {'metadata_url': '',
                          'source_url': '',
                          'description': ''}
        }
      },

      props:['label_layer_id', 'layers_to_load', 'map', 'map_loaded',  'reset',
              'style_change', 'zoom'],

       emits: ['add', 'mounted', 'remove', 'popup'],

      mounted(){
        this.getLayerInfo();
        this.$emit('mounted', this.overlay.layer.id);
      },

      watch:{
        
        map_loaded: function(){
          this.addSource();
          if(this.layers_to_load.includes(this.overlay.layer.id)){
            this.addLayer();
            this.showing = true;
          }
        },

        reset: function(){
          this.removeLayer();
        },

        style_change: function(){
          this.addSource();
          if(this.showing){
            this.addLayer();
          }
        }
      },

      methods:{

        addLayer(){
          if(this.map.getLayer(this.overlay.layer.id)){
            return;
          }
        
          this.map.addLayer({
            'id': this.overlay.layer.id,
            'type': this.overlay.layer.type,
            'source': this.overlay.source.name, 
            'paint': this.overlay.layer.paint
          }, this.label_layer_id);
          this.$emit('add', this.overlay.layer.id);
          this.showing = true;
        },

        addOverlay(){
          this.addSource();
          this.addLayer();
        },

        addSource(){
          if(this.map.getSource(this.overlay.source.name)){
              return;
          }
          
          this.map.addSource(this.overlay.source.name, this.overlay.source);
        },

        clearOverlay(){
          this.layer_id = 'none';
          this.removeOverlay();
        },

        getLayerInfo(){
          let url = 'https://gspr34zib0.execute-api.us-east-1.';
              url += 'amazonaws.com/default/data-retriever?action=';
              url += `get_data_source_summary&data_name=${this.data_name}`;

          axios.get(url)
              .then(response => {
                  if(response.data.hasOwnProperty('message')){
                      this.data_error = response.data.message;  
                  }else{
                      this.source_data = response.data;
                  }
              })
              .catch(error => {
                  this.data_error  = 'Feature request error.' 
                                  + ' Server said: ' + error?error:'Nothing';  
              });
        },

        removeLayer(){
          if (this.map.getLayer(this.overlay.layer.id)) {
            this.map.removeLayer(this.overlay.layer.id);
            this.$emit('remove', this.overlay.layer.id);
            this.showing = false;
          }
        },

        removeOverlay(){
          this.removeLayer();
          this.removeSource();
        },

        removeSource(){
          if (this.map.getSource(this.overlay.source.name)) {
              this.map.removeSource(this.overlay.source.name);
          }
        },

        setOpacity(e){
          this.map.setPaintProperty(
            this.overlay.layer.id,
            'raster-opacity',
            parseInt(e.target.value, 10) / 100
          );
        },

        toggleLayer(){
          if(this.showing){
            this.removeLayer();
          }else{
            if(this.zoom >= this.overlay.source.minzoom){
              this.addLayer();
            }
          }
        }
      },

    }
        
</script>