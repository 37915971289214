<template>
 
    <div class="w-full h-auto text-xs md:text-sm lg:text-base xl:text-xl 
                2xl:text-2xl pt-0.5 2xl:my-2 rounded-[0.4375rem] pl-0"
        :class="{'bg-ref_comp_bg_closed': !showing,
                'drop-shadow-md rounded bg-ref_comp_bg_open': showing}">
        <div title="Overlay" class="grid grid-cols-12 text-left py-2 rounded-[0.4375rem]"
            :class="{'bg-ref_comp_bg_open': showing,
                    'bg-ref_comp_bg_closed': !showing}">
            <div 
                class=" col-span-1 text-blue-700 text-center text-ref_text_min"
                :class="{'opacity-100': showing,
                        'opacity-0': !showing}">
                &#10003;
            </div>
            <div class="col-span-7 mr-0 shadow"> 
                <select
                    title="Display original Blueprint model raster imagery"
                    class="h-7 xl:h-9 2xl:h-12 w-full px-2 rounded border-gray-200 
                          text-sm xl:text-lg 2xl:text-xl border shadow-sm 
                          inline text-gray-400"
                    @change="updateLayerId($event)"
                    :disabled="zoom < overlay.source.minzoom"
                    v-model="layer_id" >
                    <option value="none" selected>
                        Select
                    </option>
                    <option  
                        v-for="(value, key) in layers" :key="key"
                        :value="value"
                        class="text-gray-700">
                        {{key}}
                    </option>
                </select>
            </div>
            <div class="col-span-3 col-start-9 text-xs text-ref_text_min hover:underline
                        pl-4 cursor-pointer"
                @click="clearOverlay"
                v-show="showing">
                <span class="align-text-bottom leading-6">Remove</span>
            </div>
            <div class="col-span-5 col-start-3 h-4 my-2 ml-1.5 text-lg 
                        text-gray-700"
                v-show="showing && zoom >= overlay.source.minzoom">Model Score
            </div>

            <!-- START OPACITY SLIDER -->
            <div title="Opacity" 
                class="text-left col-span-4 col-start-8 mr-4 ml-1 mt-3 " 
                v-show="showing && zoom >= overlay.source.minzoom">
                <input type="range" value="80"
                      class="h-1 w-full accent-ref_comp_bg rounded-lg appearance-none 
                            cursor-pointer range-sm dark:bg-gray-700 bg-ref_comp_bg_closed"
                      @change="setOpacity($event)">
            </div>
            <!-- END OPACITY SLIDER -->


            <div class="col-span-7 col-start-3 bg-gradient-to-r from-ag_old_0
                      via-ag_old_5 to-ag_old_10 h-4 mt-2 mx-1"
                v-show="showing && zoom >= overlay.source.minzoom && model == 3">
            </div>
            <div class="col-span-7 col-start-3 bg-gradient-to-r from-eco_old_0
                      via-eco_old_5 to-eco_old_10 h-4 mt-2 mx-1"
                v-show="showing && zoom >= overlay.source.minzoom && model == 4">
            </div>
            <div class="col-span-7 col-start-3 bg-gradient-to-r from-wa_old_0
                      via-wa_old_5 to-wa_old_10 h-4 mt-2 mx-1"
                v-show="showing && zoom >= overlay.source.minzoom && model == 5">
            </div>
            <div class="h-4 mt-2 pl-2 col-start-3 col-span-1 text-left text-xs"
                v-show="showing && zoom >= overlay.source.minzoom">
              0
            </div>
            <div class="h-4 mt-2 pl-2 col-start-9 col-span-1 text-right text-xs"
                v-show="showing && zoom >= overlay.source.minzoom">
              10
            </div>
        </div>
    </div>

</template>

<script>

  export default {

      name: "BlueprintRasters",

      data(){
        return{
          data_error: '',
          data_name: 'blueprint-rasters',
          hover_meta: false,
          hover_source: false,
          hover_title: false,
          label: 'Blueprint Raster Images',
          layer_id: 'none',
          overlay: {
            'source': {
              'name': 'blueprint-raster',
              'type': 'raster',
              'tiles': [
                'https://dm83832tinp1c.cloudfront.net/' +
                'eco-raster-06092020/{z}/{x}/{y}.png'
              ],
              'tileSize': 256,
              'minzoom': 7,
              'maxzoom': 15,
              'scheme': 'tms'
              },
            'layer': {
              'id': 'orig-blueprint-raster',
              'type': 'raster',
              'source': 'blueprint-raster',
              'paint': {
                'raster-opacity': .8
              }
            }
          },

          layers: {
            'Agriculture (Fall 2020)': 'ag-priority-mask-092820', 
            'Ecological (Spring 2020)': 'eco-rank-mask-06092020',
            'Water (Spring 2020)': 'freshwater-rank-2020',
          },
          showing: false
        }
      },

    props:['basemap_changed', 'label_layer_id', 'layers_to_load', 'map',
           'map_loaded', 'reset', 'style_change', 'zoom'],

    emits: ['add', 'remove'],

    watch:{

      basemap_changed: function(){
          this.clearOverlay();
      },

      map_loaded: function(){
        Object.values(this.layers).forEach(
          item => {
            if(this.layers_to_load.includes(item)){
              this.layer_id = item;
              this.createTileUrl();
              this.addOverlay();
              this.showing = true;
              return;
            }
          }
        )
      },

      reset: function(){
          this.clearOverlay();
      }
    },

    computed: {

      model: function () {
        if(this.layer_id.includes('ag')){
          return 3;
        }else if(this.layer_id.includes('eco')){
          return 4;
        }else if(this.layer_id.includes('wa')){
          return 5;
        }else{
          return 6;
        }
      }
    },

    methods:{

      addLayer(){
        if(this.map.getLayer(this.overlay.layer.id)){
          return;
        }

        this.map.addLayer({
          'id': this.overlay.layer.id,
          'type': this.overlay.layer.type,
          'source': this.overlay.source.name, 
          'paint': this.overlay.layer.paint
        }, this.label_layer_id);
      },

      addOverlay(){
        this.addSource();
        this.addLayer();
        this.showing = true;
        this.$emit('add', this.overlay.layer.id);
      },

      addSource(){
        if(this.map.getSource(this.overlay.source.name)){
            return;
        }

        this.map.addSource(this.overlay.source.name, this.overlay.source);
      },

      clearOverlay(){
        this.layer_id = 'none';
        this.removeOverlay();
      },

      createTileUrl(){
        const prefix = 'https://d7wi8peueokva.cloudfront.net/';
        const suffix = '/{z}/{x}/{y}.png';
        const url = prefix + this.layer_id + suffix;

        this.overlay.source.tiles[0] = url;
        
      },

      removeOverlay(){
        if (this.map.getLayer(this.overlay.layer.id)) {
          this.map.removeLayer(this.overlay.layer.id);
          this.$emit('remove', this.overlay.layer.id);
        }
        if (this.map.getSource(this.overlay.source.name)) {
          this.map.removeSource(this.overlay.source.name);
        }
        this.$emit('remove', this.overlay.layer.id);
        this.showing = false;
      },

      setOpacity(e){
        this.map.setPaintProperty(
          this.overlay.layer.id,
          'raster-opacity',
          parseInt(e.target.value, 10) / 100
        );
      },

      updateLayerId(e){
        this.removeOverlay();
        if(e.target.value != 'none'){
          this.layer_id = e.target.value;
          this.createTileUrl();
          this.addOverlay();
        }else{
          this.layer_id = 'none';
        }
      }
    }
  }

</script>
