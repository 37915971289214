<template>
  
  <div class="w-full h-auto text-xs md:text-sm lg:text-base xl:text-xl 
              2xl:text-2xl pt-0.5 pb-1.5 2xl:my-2 rounded-[0.4375rem]"
       :class="{'bg-model_comp_bg_water_closed': !showing,
                'drop-shadow-md rounded bg-model_comp_bg_water_open': showing}">
    <div title="Overlay" class="mx-1 grid grid-cols-12 text-left"
         :class="{'mb-8': showing}">
      <div 
        class=" col-span-1 text-blue-700 text-center"
        :class="{'opacity-100': showing,
                'opacity-0': !showing,
                'text-gray-300': zoom < overlay.fill.source.minzoom,
                'text-blue-700': showing && zoom >= 
                                                    overlay.fill.source.minzoom,
                'text-blue-400': !showing && zoom >= 
                                                  overlay.fill.source.minzoom}">
        &#10003;
      </div>
      <div 
        @mouseover="hover_title = true" 
        @mouseout="hover_title = false"
        title="Displays the NHDPlus Headwaters Overlay"
        class="col-span-9 text-sm md:text-sm lg:text-base xl:text-lg 
               2xl:text-xl"
        :class="{'text-gray-300': zoom < overlay.fill.source.minzoom,
                'text-blue-700': showing && zoom >= 
                                                    overlay.fill.source.minzoom,
                'text-blue-400': !showing && zoom >= 
                                                    overlay.fill.source.minzoom,
                'cursor-pointer': hover_title && zoom >= 
                                                    overlay.fill.source.minzoom,
                'cursor-default': !hover_title,
                'underline': hover_title && (zoom >= 
                                      overlay.fill.source.minzoom || showing)}"
        @click="toggleLayer">Headwaters
      </div>
      <div class="text-xs xl:text-sm 2xl:text-base -mt-0.5 ml-1 
                  col-span-9 col-start-2"
          :class="{'text-gray-300': zoom < overlay.fill.source.minzoom,
                    'text-neutral-400': zoom >= overlay.fill.source.minzoom}"
      ><a v-bind:href="source_data.source_info_url" 
          target="_blank"
          title="Information"
          v-if="source_data.source_info_url"
          id="source_link" class="hover:underline"
          >(EPA, USGS, 2018)</a>
        <span v-else>(EPA, USGS, 2018)</span>
      </div>
      <div title="Opacity" 
           class="text-left col-span-2 mr-4 ml-1" 
           v-show="showing && zoom >= overlay.fill.source.minzoom">
        <input type="range" value="40" 
              class="h-1 w-full bg-gray-200 rounded-lg appearance-none 
                     cursor-pointer range-sm dark:bg-gray-700"
              @change="setOpacity($event)">
      </div>
      <div class="col-span-1">&nbsp;</div>
      <div class="col-span-10 col-start-2 py-1 2xl:py-2 pr-5 text-xs md:text-sm 
                  lg:text-base 2xl:text-lg text-gray-700"
           v-show="showing && zoom >= overlay.fill.source.minzoom">
        {{ source_data.description }}
      </div>
      <div class="col-span-12 col-start-2 grid grid-cols-6 py-1 text-xs
                  md:text-base 2xl:text-lg"
           v-show="showing && zoom >= overlay.fill.source.minzoom">
        <!-- <span class="col-span-6 mb-0.5 text-xs md:text-sm lg:text-base
                     2xl:text-2xl text-gray-500">
          Downloaded {{source_data.updated}}
        </span>
        <a target="_blank" 
           :href="source_data.source_info_url"
          @mouseover="hover_source = true" 
          @mouseout="hover_source = false"
          class="col-span-3"
          :class="{'text-blue-700': hover_source,
                  'text-blue-500': !hover_source,
                  'cursor-pointer': hover_source,
                  'cursor-default': !hover_source,
                  'underline': hover_source}"
          id="source_headwaters">
          Source
        </a> -->
        <a target="_blank" 
           :href="source_data.metadata_url"
          @mouseover="hover_meta = true" 
          @mouseout="hover_meta = false"
          class="col-span-3"
          :class="{'text-blue-700': hover_meta,
                  'text-blue-500': !hover_meta,
                  'cursor-pointer': hover_meta,
                  'cursor-default': !hover_meta,
                  'underline': hover_meta}"
          id="metadata_headwaters">
           Metadata
        </a>
        <span class="col-span-2">&nbsp;</span>
      </div>
      <div class="col-span-1 col-start-2 bg-nhd_headwaters h-4 mt-2 mx-1"
           v-show="showing && zoom >= overlay.fill.source.minzoom">
      </div>
      <div class="h-4 mt-2 2xl:mt-0 pl-2 col-span-10 align-middle text-xs 
                  md:text-sm lg:text-sm xl:text-base 2xl:text-lg"
           v-show="showing && zoom >= overlay.fill.source.minzoom">
        Headwaters
      </div>
    </div>
  </div>

</template>

<script>

  import axios from 'axios';

  export default {

      name: "NHDCatchments",

      data(){
          return{
              data_error: '',
              data_name: 'headwaters',
              hover_meta: false,
              hover_source: false,
              hover_title: false,
              label: 'NHDPlus (National Hydrography Dataset Plus) Version 2 ' +
                     'Catchments',
              overlay: {
                fill:{
                  'source': {
                    'name': 'nhd-headwaters',
                    'type': 'vector',
                    'tiles': ['https://d7wi8peueokva.cloudfront.net/'
                              + 'nhd_headwaters/{z}/{x}/{y}.pbf'],
                    'minzoom': 6,
                    'maxzoom': 14,
                  },
                  'layer': {
                      'id': 'nhd2plus-headwaters',
                      'type': 'fill',
                      'source': 'nhd-headwaters',
                      'source_layer': 'tiles.headwaters',
                      'paint':  {
                        'fill-color': '#ffffbe',
                        'fill-opacity': .4
                      }
                  }
                }
              },
              showing: false,
              source_data: {'metadata_url': '',
                            'source_url': '',
                            'description': ''},
          }
      },

      props:['show_features', 'label_layer_id', 'layers_to_load', 
            'map', 'map_loaded', 'reset', 'style_change', 'zoom'],

       emits: ['add', 'mounted', 'remove', 'popup'],

      mounted(){
        this.getLayerInfo();
        this.$emit('mounted', this.overlay.fill.layer.id);
      },

      watch:{

        map_loaded: function(){
          this.addSource();
          if(this.layers_to_load.includes(this.overlay.fill.layer.id)){
            this.addLayer();
            this.showing = true;
          }
        },

        reset: function(){
          this.reset_layer();
        },

        style_change: function(){
          this.addSource();
          if(this.showing){
            this.addLayer();
          }
        }
      },

      methods:{

        addInteractions(){
          this.removeInteractions();

          this.map.on('click', 
                            this.overlay.fill.layer.id, this.handleLayerClick);

          this.map.on('mouseenter',  this.overlay.fill.layer.id, () => {
            this.map.getCanvas().style.cursor = 'pointer';
          });
          
          this.map.on('mouseleave',  this.overlay.fill.layer.id, () => {
            this.map.getCanvas().style.cursor = '';
          });


        },

        addLayer(){
          if(this.map.getLayer(this.overlay.fill.layer.id)){
            return;
          }
          this.map.addLayer({
            'id': this.overlay.fill.layer.id,
            'type': this.overlay.fill.layer.type,
            'source': this.overlay.fill.layer.source, 
            'source-layer': this.overlay.fill.layer.source_layer,
            'paint': this.overlay.fill.layer.paint
          }, this.label_layer_id);

          if(this.map.getLayer('feature-borders')){
            this.map.moveLayer('feature-borders',this.label_layer_id);
          }

          this.addInteractions();
        },

        addSource(){
            if(this.map.getSource(this.overlay.fill.source.name)){
                return;
            }
          
            this.map.addSource(this.overlay.fill.source.name, {
                'type': this.overlay.fill.source.type,
                'tiles': this.overlay.fill.source.tiles,
                'minzoom': this.overlay.fill.source.minzoom,
                'maxzoom': this.overlay.fill.source.maxzoom,
            });
        },

        formatData(area){
          return parseFloat(area).toLocaleString();
        },

        getLayerInfo(){
            let url = 'https://gspr34zib0.execute-api.us-east-1.';
                url += 'amazonaws.com/default/data-retriever?action=';
                url += `get_data_source_summary&data_name=${this.data_name}`;

            axios.get(url)
                .then(response => {
                    if(response.hasOwnProperty('data')){
                      if(response.data.hasOwnProperty('message')){
                          this.data_error = response.data.message;  
                      }else{
                          this.source_data = response.data;
                      }
                    }else{
                          this.data_error = 'Failed to get data from server.';  
                    }
                })
                .catch(error => {
                    this.data_error  = 'Feature request error.' 
                                    + ' Server said: ' + error?error:'Nothing';  
              });
        },

        handleLayerClick(e){
        
          const html = `<p class="font-semibold text-stone-900 drop-shadow-sm"> 
                      Headwater</p><hr class="mb-1.5">
                       <p class="text-left mb-4">
                        A catchment having the longest length of a 1st order 
                        stream.
                      </p`;

          this.$emit('popup', {'id': this.overlay.fill.layer.id, 'html': html,
                   'coords': e.lngLat});
        },

        removeLayer(){
          this.removeInteractions();
          this.map.removeLayer(this.overlay.fill.layer.id);
        },

        removeInteractions(){
          this.map.off('click', 
                            this.overlay.fill.layer.id, this.handleLayerClick);
          
          this.map.off('mouseenter',  this.overlay.fill.layer.id, () => {
            this.map.getCanvas().style.cursor = 'pointer';
          });
          
          this.map.off('mouseleave',  this.overlay.fill.layer.id, () => {
            this.map.getCanvas().style.cursor = '';
          });
        },

        reset_layer(){
          if(this.showing){
            this.removeLayer();
            this.$emit('remove', this.overlay.fill.layer.id);
            this.showing = false;
          }
        },
        
        setOpacity(e){
          let value = e.target.value;
          this.map.setPaintProperty(this.overlay.fill.layer.id, 
                                                  'fill-opacity', value/100);
        },

        toggleLayer(){
          if(this.showing){
            this.removeLayer();
            this.$emit('remove', this.overlay.fill.layer.id);
          }else{
            this.addLayer();
            this.$emit('add', this.overlay.fill.layer.id);
          }
          this.showing = !this.showing;
        }
      }
  }

</script>
