import axios from 'axios';

class FeatureGetter {
    constructor() {
        this.IDS = {3: 'ag', 4: 'eco', 5: 'wa',6: 'com'};

        this.feature = {
                    acreage: 0,
                    ag_score: null, 
                    block: '',
                    com_score: null,
                    coordinates: null,
                    eco_score: null,
                    error: '',
                    feature_id: null,
                    lot: '',
                    muni: '',
                    muni_code: '',
                    open_space_parcel_count: 0,
                    parcel_acreage: 0,
                    parcel_count: 0,
                    qualifier: '',
                    response_data: {},
                    wa_score: null,
                  };
    }

    getFeature(obj){ //feature_id, centroid){
        this.resetFeature();

        if(!obj.feature_id){ return; }

        let url = 'https://gspr34zib0.execute-api.us-east-1.'
                        + 'amazonaws.com/default/data-retriever?action=';

        if(obj.feature_id == -1){
                url += `get_model_summary_by_point&lat=`;
                url += `${obj.coords[1]}&lng=${obj.coords[0]}`;
        }else{
            if(obj.centroid){
                url += `get_model_summary_by_parcel_with_centroid`;
                url += `&pams_pin=${obj.feature_id}`;
            }else{
                url += `get_model_summary_by_parcel`;
                url += `&pams_pin=${obj.feature_id}`;
            }
        }

        return new Promise((resolve, reject) => {
            resolve( this.request(url) );
        });
    }

    request(url) {
        return new Promise((resolve, reject) => {
            axios.get(url)

                .then(response => {
                    if(response.hasOwnProperty('data')){
                        if(response.data){
                            if(response.data.hasOwnProperty('message')){
                                this.feature.error = response.data.message; 
                                this.setId(0); 
                            }else{
                                this.feature.response_data = response.data;
                                this.processData(response.data);
                            }
                        }else{
                            this.feature.error  = 
                                            'Unknown feature request error.';
                            this.setId(0); 
                        }
                    }else{
                         this.feature.error  = 'Unknown feature request error.';
                         this.setId(0);   
                    }
                })
                .catch( (error) => {
                    this.feature.error  = 'Feature request error.' 
                                    + ' Server said: ' + error?error:'Nothing';
                    this.setId(0);   
                })
                .then(()=>{
                    resolve(this.feature);
                });
        });
    }

    processData(){ 
        if(!this.feature.response_data){ return this.feature; }

        this.setId();

        if(!this.feature.feature_id){ return this.feature; }
        
        this.setBlockLotQual();
        this.setCoordinates();
        this.setScores();
        this.setAcreage();
        this.setMuni();
        this.setParcelCount();
        this.setParcelAcreage();
        this.setOpenSpaceParcelCount();

        return this.feature
    }

    setId(feature_id){
        if(feature_id){
            this.feature.feature_id = feature_id;
        }else if(Object.keys(this.feature.response_data).length > 0){
            this.feature.feature_id = this.feature.response_data.feature_id;
        }else{
            this.feature.feature_id = 0;
        }
    }

    setBlockLotQual(){
        const parts = this.feature.feature_id.split('_');
        const len = parts.length;

        this.feature.muni_code = len > 0? parts[0] : '';
        this.feature.block = len > 1? parts[1] : '';
        this.feature.lot = len > 2? parts[2] : '';
        this.feature.qualifier = len > 3? parts[3] : '';
    }

    setCoordinates(){
        if(this.feature.response_data.hasOwnProperty('best_centroid')){
            this.feature.coordinates = 
                        this.feature.response_data.best_centroid.coordinates;
        }
    }

    setScores(){
        let  model_ids = Object.values(this.IDS);

        for(const id in model_ids){
            switch(model_ids[id]){
                case 'ag':
                    if(this.feature.response_data.hasOwnProperty('ag_score')){
                        continue;
                    }

                    this.feature.ag_score = 
                                this.feature.response_data[model_ids[id]].score;
                    break;
                case 'eco':
                    if(this.feature.response_data.hasOwnProperty('eco_score')){
                        continue;
                    }

                    if(this.feature.response_data[model_ids[id]]
                            .hasOwnProperty('score')){
                                this.feature.eco_score = 
                                this.feature.response_data[model_ids[id]].score;
                    }else{
                        this.feature.eco_score = 
                                    this.feature.response_data[model_ids[id]]
                                         .normalized_ecological_score;    
                        delete this.feature.response_data[model_ids[id]]
                                         .normalized_ecological_score;  
                    }
                    break;
                case 'wa':
                    if(this.feature.response_data.hasOwnProperty('wa_score')){
                        continue;
                    }

                    if(this.feature.response_data[model_ids[id]]
                            .hasOwnProperty('score')){
                                this.feature.wa_score = 
                                this.feature.response_data[model_ids[id]].score;
                    }else{
                        this.feature.wa_score = 
                                    this.feature.response_data[model_ids[id]]
                                        .normalized_water_score;    
                    }
                    break;
            }
        }

        this.feature.com_score = 
                (((this.feature.ag_score +
                    this.feature.eco_score +
                    this.feature.wa_score)/3).toFixed(2))/1;
    }

    setAcreage(){
        let model_ids = Object.values(this.IDS);
        
        for(const id of model_ids){
            const data = this.feature.response_data[id];

            if(!data){
                continue;
            }

            if(this.feature.acreage === 0){
                if(this.feature.response_data.hasOwnProperty('acreage')){
                    this.feature.acreage = this.feature.response_data.acreage;
                }else{
                    this.feature.acreage = (parseFloat(data
                            .total_parcel_acreage)
                            .toFixed(2)/1).toLocaleString("en-US");
                }
                break;
            }
        }
    }

    setMuni(){
        this.feature.muni = this.feature.response_data.muni;
    }

    setParcelCount(){
        
        if(this.feature.response_data.hasOwnProperty('parcel_count')){
            this.feature.parcel_count = this.feature.response_data.parcel_count;
        }
    }

    setParcelAcreage(){
        if(this.feature.response_data.hasOwnProperty('parcel_acreage')){
            this.feature.parcel_acreage = 
                                    this.feature.response_data.parcel_acreage;
        }
    }

    setOpenSpaceParcelCount(){ 
        if(this.feature.response_data.hasOwnProperty('os')){
            this.feature.open_space_parcels = this.feature.response_data.os;
        }
    }

    resetFeature(){
        this.feature = {
            acreage: 0,
            ag_score: null, 
            block: '',
            com_score: null,
            coordinates: null,
            eco_score: null,
            error: '',
            feature_id: null,
            lot: '',
            muni: '',
            muni_code: '',
            open_space_parcel_count: 0,
            parcel_acreage: 0,
            parcel_count: 0,
            qualifier: '',
            response_data: {},
            wa_score: null,
        };
    }
}

export {FeatureGetter};