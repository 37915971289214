<template>

  <div class="w-full h-auto text-xs md:text-sm lg:text-base xl:text-xl 
              2xl:text-2xl pt-0.5 2xl:my-2 rounded-[0.4375rem]"
       :class="{'bg-ref_comp_bg_closed': !showing_full,
                'drop-shadow-md rounded bg-ref_comp_bg_open': showing_full}">
    <div class="w-full">
      <div title="Show Land Use/Land Cover - NJDEP 2015" 
          class="mx-1 grid grid-cols-12 text-left">
        <div 
          class=" col-span-1 text-blue-700 text-center"
          :class="{'opacity-100': showing_full,
                  'opacity-0': !showing_full,
                  'text-gray-300': zoom < overlay.source.minzoom,
                  'text-blue-700': showing_full && zoom >= 
                                                        overlay.source.minzoom,
                  'text-blue-400': !showing_full && zoom >= 
                                                      overlay.source.minzoom}">
          &#10003;
        </div>
        <div 
          @mouseover="hover_title = true" 
          @mouseout="hover_title = false"
          title="Displays the Land Use/Cover Overlay"
          class="col-span-9 text-sm md:text-sm lg:text-base xl:text-xl 
               2xl:text-2xl"
          :class="{'text-ref_comp_bg': showing_full,
                  'text-ref_text': !showing_full,
                  'cursor-pointer':hover_title && 
                                   zoom >= overlay.source.minzoom,
                  'cursor-default': !hover_title,
                  'underline': hover_title 
                               && (zoom >= overlay.source.minzoom 
                               || showing_full)}"
          @click="toggleLayer()">Land Use/Cover
        </div>
        <div class="text-xs xl:text-sm 2xl:text-lg -mt-0.5 ml-1 
                    col-span-9 col-start-2 text-ref_text_min"
        ><a v-bind:href="source_data.source_info_url" 
            target="_blank"
            title="Information"
            v-if="source_data.source_info_url"
            id="source_link" class="hover:underline">(NJDEP, 2015)</a>
          <span v-else>(NJDEP, 2015)</span>
        </div>
        <div title="Opacity" 
            class="text-left col-span-2 mr-4 ml-1" 
            v-show="showing_full && zoom >= overlay.source.minzoom">
          <input type="range" value="100" 
                class="h-1 w-full bg-gray-200 rounded-lg appearance-none 
                      cursor-pointer range-sm dark:bg-gray-700"
                @change="setFullLayerOpacity($event)">
        </div>
        <div class="col-span-1">&nbsp;</div>
        <div class="col-span-10 col-start-2 py-1 pr-5 text-xs lg:text-sm 
                  text-gray-700"
            v-show="showing_full && zoom >= overlay.source.minzoom">
          {{ source_data.description }}
        </div>
        <div class="col-span-12 col-start-2 grid grid-cols-6 py-1 text-xs
                  md:text-base 2xl:text-2xl"
            v-show="showing_full && zoom >= overlay.source.minzoom">
          <!-- <span class="col-span-6 mb-0.5 text-xs md:text-sm lg:text-base
                     2xl:text-2xl text-gray-500">
            Downloaded {{source_data.updated}}
          </span>
          <a target="_blank" 
            :href="source_data.source_info_url"
            @mouseover="hover_source = true" 
            @mouseout="hover_source = false"
            class="col-span-3"
            :class="{'text-blue-700': hover_source,
                    'text-blue-500': !hover_source,
                    'cursor-pointer': hover_source,
                    'cursor-default': !hover_source,
                    'underline': hover_source}"
            id="source_land_use_cover">
            Source
          </a> -->
          <a target="_blank" 
            :href="source_data.metadata_url"
            @mouseover="hover_meta = true" 
            @mouseout="hover_meta = false"
            class="col-span-3"
            :class="{'text-blue-700': hover_meta,
                    'text-blue-500': !hover_meta,
                    'cursor-pointer': hover_meta,
                    'cursor-default': !hover_meta,
                    'underline': hover_meta}"
            id="metadata_land_use_cover">
            Metadata
          </a>
        </div>
        <div class="col-span-12 col-start-2 text-gray-500 text-sm mt-4"
             v-show="showing_full && zoom >= overlay.source.minzoom">
          (Click legend to filter display) 
        </div>
        <div class="col-span-12 grid grid-cols-12 cursor-pointer" 
            data-type="urban"
            id="layer_land_use_cover_urban"
            @click="filterLayer($event)">
          <div class="col-span-1 col-start-2 bg-lu2015_urban h-4 mt-2 mx-1"
              v-show="showing_full && zoom >= overlay.source.minzoom">
          </div>
          <div class="h-4 mt-2 2xl:mt-0 pl-2 col-span-10 align-middle text-xs 
                    md:text-sm lg:text-sm xl:text-base 2xl:text-2xl"
              :class="{'text-blue-500': filters.urban,
                       'underline': filters.urban}"
              v-show="showing_full && zoom >= overlay.source.minzoom">
            Urban
          </div>
        </div>

        <div class="col-span-12 grid grid-cols-12 cursor-pointer" 
            data-type="agriculture"
            id="layer_land_use_cover_agriculture"
            @click="filterLayer($event)">
          <div class="col-span-1 col-start-2 bg-lu2015_ag h-4 mt-2 mx-1"
              v-show="showing_full && zoom >= overlay.source.minzoom">
          </div>
          <div class="h-4 mt-2 2xl:mt-0 pl-2 col-span-10 align-middle text-xs 
                    md:text-sm lg:text-sm xl:text-base 2xl:text-2xl"
              :class="{'text-blue-500': filters.agriculture,
                       'underline': filters.agriculture}"
              v-show="showing_full && zoom >= overlay.source.minzoom">
            Agriculture
          </div>
        </div>
        
        <div class="col-span-12 grid grid-cols-12 cursor-pointer" 
            data-type="wetlands"
            id="layer_land_use_cover_wetlands"
            @click="filterLayer($event)">
          <div class="col-span-1 col-start-2 bg-lu2015_wetlands h-4 mt-2 mx-1"
              v-show="showing_full && zoom >= overlay.source.minzoom">
          </div>
          <div class="h-4 mt-2 2xl:mt-0 pl-2 col-span-10 align-middle text-xs 
                    md:text-sm lg:text-sm xl:text-base 2xl:text-2xl"
              :class="{'text-blue-500': filters.wetlands,
                       'underline': filters.wetlands}"
              v-show="showing_full && zoom >= overlay.source.minzoom">
            Wetlands
          </div>
        </div>
        
        <div class="col-span-12 grid grid-cols-12 cursor-pointer" 
            data-type="water"
            id="layer_land_use_cover_water"
            @click="filterLayer($event)">
          <div class="col-span-1 col-start-2 bg-lu2015_water h-4 mt-2 mx-1"
              v-show="showing_full && zoom >= overlay.source.minzoom">
          </div>
          <div class="h-4 mt-2 2xl:mt-0 pl-2 col-span-10 align-middle text-xs 
                    md:text-sm lg:text-sm xl:text-base 2xl:text-2xl"
              :class="{'text-blue-500': filters.water,
                       'underline': filters.water}"
              v-show="showing_full && zoom >= overlay.source.minzoom">
            Water
          </div>
        </div>
        
        <div class="col-span-12 grid grid-cols-12 cursor-pointer" 
            data-type="forest"
            id="layer_land_use_cover_forest"
            @click="filterLayer($event)">
          <div class="col-span-1 col-start-2 bg-lu2015_forest h-4 mt-2 mx-1"
              v-show="showing_full && zoom >= overlay.source.minzoom">
          </div>
          <div class="h-4 mt-2 2xl:mt-0 pl-2 col-span-10 align-middle text-xs 
                    md:text-sm lg:text-sm xl:text-base 2xl:text-2xl"
              :class="{'text-blue-500': filters.forest,
                       'underline': filters.forest}"
              v-show="showing_full && zoom >= overlay.source.minzoom">
            Forest
          </div>
        </div>
        
        <div class="col-span-12 grid grid-cols-12 cursor-pointer" 
            data-type="barren"
            id="layer_land_use_cover_barren"
            @click="filterLayer($event)">
          <div class="col-span-1 col-start-2 bg-lu2015_barren h-4 mt-2 mx-1"
              v-show="showing_full && zoom >= overlay.source.minzoom">
          </div>
          <div class="h-4 mt-2 2xl:mt-0 pl-2 col-span-10 align-middle text-xs 
                    md:text-sm lg:text-sm xl:text-base 2xl:text-2xl"
              :class="{'text-blue-500': filters.barren,
                       'underline': filters.barren}"
              v-show="showing_full && zoom >= overlay.source.minzoom">
            Barren
          </div>
        </div>
      </div>
  </div>
</div>

</template>

<script>

  import axios from 'axios';

  export default {

      name: "LULC2015_level_1",

      data(){
          return{

            codes: {
              0: 'AGRICULTURE',
              1: 'BARREN LAND',
              2: 'FOREST',
              3: 'URBAN',
              4: 'WATER',
              5: 'WETLANDS'
            },

              data_error: '',
              data_name: 'land_use_2015_descriptions',
              filters:{'urban':false,
                       'agriculture':false,
                       'wetlands':false,
                       'water':false,
                       'forest':false,
                       'barren':false
                      },
              hover_meta: false,
              hover_source: false,
              hover_title: false,
              label: 'Land Use Land Cover, Level 1 - New Jersey DEP 2015',
              overlay: {
                'source': {
                  'name': 'lu2015_level_1',
                  'type': 'vector',
                  'tiles': ['https://d7wi8peueokva.cloudfront.net/'
                        + 'lu2015_level_1/{z}/{x}/{y}.pbf'],
                  'minzoom': 10,
                  'maxzoom': 14,
                },
                'layer': {
                    'id': 'lulc-level-1',
                    'type': 'fill',
                    'source': 'lu2015_level_1',
                    'source_layer': 'tiles.lu2015_level_1',
                   
                  }
              },
              showing_full: false,
              source_data: {'metadata_url': '',
                            'source_url': '',
                            'description': ''}
          }
      },

      props:['show_features', 'label_layer_id', 'layers_to_load', 
            'map', 'map_loaded', 'reset', 'style_change', 'zoom'],

       emits: ['add', 'mounted', 'remove', 'popup'],

      mounted(){
        this.getLayerInfo();
        this.$emit('mounted', this.overlay.layer.id);
      },

      watch:{

        map_loaded: function(){
          this.addSource();
          if(this.layers_to_load.includes(this.overlay.layer.id)){
            this.addFullLayer();
            this.showing = true;
          }
        },

        reset: function(){
          this.reset_layer();
        },

        style_change: function(){
          this.addSource();
        }
      },

      methods:{

        addFullLayer(){
          if(this.map.getLayer(this.overlay.layer.id)){
            return;
          }
        
          this.map.addLayer({
            'id': this.overlay.layer.id,
            'type': this.overlay.layer.type,
            'source': this.overlay.source.name, 
            'source-layer': this.overlay.layer.source_layer,
          }, this.label_layer_id);
          this.setDefaultPaintProperty();
          if(this.map.getLayer('feature-borders')){
            this.map.moveLayer('feature-borders',this.label_layer_id);
          }

          this.addFullLayerInteractions();
        },

        addFullLayerInteractions(){
          this.removeFullLayerInteractions();
          this.map.on('click', 
                        this.overlay.layer.id, this.handleFullLayerClick);
        },

        addSource(){
            if(this.map.getSource(this.overlay.source.name)){
                return;
            }
          
            this.map.addSource(this.overlay.source.name, {
                'type': this.overlay.source.type,
                'tiles': this.overlay.source.tiles,
                'minzoom': this.overlay.source.minzoom,
                'maxzoom': this.overlay.source.maxzoom,
            });
        },

        filterLayer(e){
          const type = e.currentTarget.dataset.type;

          switch(type){
            case 'urban':
              if(this.filters.urban){
                this.filters.urban = false;
                this.setDefaultPaintProperty();
              }else{
              this.filters = {};
              this.filters.urban = true;
              this.map.setPaintProperty('lulc-level-1', 'fill-color', 
                        ['match', ['get', 't'],
                          3, "#f22f00",
                          'transparent'
                        ],
                        {'fill-outline-color': 'transparent',
                        'fill-opacity': 1
                        }
                      )
              }
              break;
            
            case 'agriculture':
              if(this.filters.agriculture){
                this.filters.agriculture = false;
                this.setDefaultPaintProperty();
              }else{
              this.filters = {};
              this.filters.agriculture = true;
              this.map.setPaintProperty('lulc-level-1', 'fill-color', 
                        ['match', ['get', 't'],
                          0, "#ff4dc4",
                          'transparent'
                        ],
                        {'fill-outline-color': 'transparent',
                        'fill-opacity': 1
                        }
                      )
              }
              break;
            
            case 'wetlands':
              if(this.filters.wetlands){
                this.filters.wetlands = false;
                this.setDefaultPaintProperty();
              }else{
              this.filters = {};
              this.filters.wetlands = true;
              this.map.setPaintProperty('lulc-level-1', 'fill-color', 
                        ['match', ['get', 't'],
                          5, "#0040ff",
                          'transparent'
                        ],
                        {'fill-outline-color': 'transparent',
                        'fill-opacity': 1
                        }
                      )
              }
              break;
            
            case 'water':
              if(this.filters.water){
                this.filters.water = false;
                this.setDefaultPaintProperty();
              }else{
              this.filters = {};
              this.filters.water = true;
              this.map.setPaintProperty('lulc-level-1', 'fill-color', 
                        ['match', ['get', 't'],
                          4, "#26ffff",
                          'transparent'
                        ],
                        {'fill-outline-color': 'transparent',
                        'fill-opacity': 1
                        }
                      )
              }
              break;

            case 'forest':
              if(this.filters.forest){
                this.filters.forest = false;
                this.setDefaultPaintProperty();
              }else{
              this.filters = {};
              this.filters.forest = true;
              this.map.setPaintProperty('lulc-level-1', 'fill-color', 
                        ['match', ['get', 't'],
                          2, "#5ff500",
                          'transparent'
                        ],
                        {'fill-outline-color': 'transparent',
                        'fill-opacity': 1
                        }
                      )
              }
              break;
            
            case 'barren':
              if(this.filters.barren){
                this.filters.barren = false;
                this.setDefaultPaintProperty();
              }else{
              this.filters = {};
              this.filters.barren = true;
              this.map.setPaintProperty('lulc-level-1', 'fill-color', 
                        ['match', ['get', 't'],
                          1, "#ffdd00",
                          'transparent'
                        ],
                        {'fill-outline-color': 'transparent',
                        'fill-opacity': 1
                        }
                      )
              }
              break;
          }

        },

        handleFullLayerClick(e){
        
          const html = `<p class="font-semibold text-stone-900 drop-shadow-sm"> 
                      Land Use/Cover (NJDEP 2015)</p><hr class="mb-1.5">
                       <p class="text-center">
                       ${this.codes[e.features[0].properties.t]}
                       </p>`;

          this.$emit('popup', {'id': this.overlay.layer.id, 'html': html,
                   'coords': e.lngLat});
        },

        getLayerInfo(){
            let url = 'https://gspr34zib0.execute-api.us-east-1.';
                url += 'amazonaws.com/default/data-retriever?action=';
                url += `get_data_source_summary&data_name=${this.data_name}`;

            axios.get(url)
                .then(response => {
                    if(response.data.hasOwnProperty('message')){
                        this.data_error = response.data.message;  
                    }else{
                        this.source_data = response.data;
                    }
                })
                 .catch(error => {
                    this.feature.error  = 'Feature request error.' 
                                    + ' Server said: ' + error?error:'Nothing';  
                });
        },

        removeFullLayer(){
          this.removeFullLayerInteractions();
          this.map.removeLayer(this.overlay.layer.id);
        },

        removeFullLayerInteractions(){
            this.map.off('click', 
                        this.overlay.layer.id, this.handleFullLayerClick);
        },

        reset_layer(){
          if(this.showing_full){
            this.removeFullLayer();
            this.$emit('remove', this.overlay.layer.id);
            this.showing_full = false;
          }
        },

        setDefaultPaintProperty(){
              this.map.setPaintProperty('lulc-level-1', 'fill-color', 
                        ['match', ['get', 't'],
                          0, "#ff4dc4",
                          1, "#ffdd00",
                          2, "#5ff500",
                          3, "#f22f00",
                          4, "#26ffff",
                          5, "#0040ff",
                          'transparent'
                        ],
                        {'fill-outline-color': 'transparent',
                        'fill-opacity': 1
                        }
                      )
        },

        setFullLayerOpacity(e){
          let value = e.target.value;
          this.map.setPaintProperty(this.overlay.layer.id, 'fill-opacity', 
                                                                    value/100);
        },

        toggleLayer(){
            if(this.showing_full){
              this.removeFullLayer();
              this.$emit('remove', this.overlay.layer.id);
            }else{
              if(this.zoom >= this.overlay.source.minzoom){
                this.addFullLayer();
                this.$emit('add', this.overlay.layer.id)
              }
            }
              this.showing_full = !this.showing_full;
        }
      }
  }

</script>
