<template>
  
  <div class="w-full h-auto text-xs md:text-sm lg:text-base xl:text-xl 
              2xl:text-2xl pt-0.5 pb-1.5 2xl:my-2 rounded-[0.4375rem]"
       :class="{'bg-model_comp_bg_eco_closed': !showing,
                'drop-shadow-md rounded bg-model_comp_bg_eco_open': showing}">
    <div title="Overlay" class="mx-1 grid grid-cols-12 text-left"
         :class="{'mb-8': showing}">
      <div 
        class=" col-span-1 text-blue-700 text-center"
        :class="{'opacity-100': showing,
                'opacity-0': !showing,
                'text-gray-300': zoom < overlay.source.minzoom,
                'text-blue-700': showing && zoom >= overlay.source.minzoom,
                'text-blue-400': !showing && zoom >= overlay.source.minzoom}">
        &#10003;
      </div>
      <div 
        @mouseover="hover_title = true" 
        @mouseout="hover_title = false"
        title="Displays the Natural Heritage Priority Sites Overlay"
        class="col-span-9 text-sm md:text-sm lg:text-base xl:text-lg 2xl:text-xl"
        :class="{'text-gray-300': zoom < overlay.source.minzoom,
                'text-blue-700': showing && zoom >= overlay.source.minzoom,
                'text-blue-400': !showing && zoom >= overlay.source.minzoom,
                'cursor-pointer': hover_title && zoom >= overlay.source.minzoom,
                'cursor-default': !hover_title,
                'underline': hover_title && (zoom >= overlay.source.minzoom ||
                                              showing)}"
        @click="toggleLayer">Natural Heritage Priority Sites 
      </div>
      <div class="text-xs xl:text-sm 2xl:text-base -mt-0.5 ml-1 
                  col-span-9 col-start-2"
          :class="{'text-gray-300': zoom < overlay.source.minzoom,
                    'text-neutral-400': zoom >= overlay.source.minzoom}"
      ><a v-bind:href="source_data.source_info_url" 
          target="_blank"
          title="Information"
          v-if="source_data.source_info_url"
          id="source_link" class="hover:underline">(NJDEP, 2007)</a>
        <span v-else>(NJDEP, 2007)</span>
      </div>
      <div title="Opacity" 
           class="text-left col-span-2 mr-4 ml-1" 
           v-show="showing && zoom >= overlay.source.minzoom">
        <input type="range" value="80" 
              class="h-1 w-full bg-gray-200 rounded-lg appearance-none 
                     cursor-pointer range-sm dark:bg-gray-700"
              @change="setOpacity($event)">
        <label for="show-labels" 
               class="text-xs md:text-sm text-blue-700 text-font+Sans"
               @click="show_labels = !show_labels">Labels </label>
        <input type="checkbox" id="show-labels" v-model="show_labels">
      </div>
      <div class="col-span-1">&nbsp;</div>
      <div class="col-span-10 col-start-2 py-1 2xl:py-2 pr-5 text-xs md:text-sm 
                  lg:text-base 2xl:text-lg text-gray-700"
           v-show="showing && zoom >= overlay.source.minzoom">
        {{ source_data.description }}
      </div>
      <div class="col-span-12 col-start-2 grid grid-cols-6 py-1 text-xs
                  md:text-base 2xl:text-lg"
           v-show="showing && zoom >= overlay.source.minzoom">
        <!-- <span class="col-span-6 mb-0.5 text-xs md:text-sm lg:text-base
                     2xl:text-2xl text-gray-500">
          Downloaded {{source_data.updated}}
        </span>
        <a target="_blank" 
           :href="source_data.source_info_url"
          @mouseover="hover_source = true" 
          @mouseout="hover_source = false"
          class="col-span-3"
          :class="{'text-blue-700': hover_source,
                  'text-blue-500': !hover_source,
                  'cursor-pointer': hover_source,
                  'cursor-default': !hover_source,
                  'underline': hover_source}"
          id="source_natural_heritage_priority_sites">
          Source
        </a> -->
        <a target="_blank" 
           :href="source_data.metadata_url"
          @mouseover="hover_meta = true" 
          @mouseout="hover_meta = false"
          class="col-span-3"
          :class="{'text-blue-700': hover_meta,
                  'text-blue-500': !hover_meta,
                  'cursor-pointer': hover_meta,
                  'cursor-default': !hover_meta,
                  'underline': hover_meta}"
          id="metadata_natural_heritage_priority_sites">
           Metadata
        </a>
        <span class="col-span-2">&nbsp;</span>
      </div>
      <div class="col-span-1 col-start-2 bg-pink-300 h-4 mt-2 mx-1"
           v-show="showing && zoom >= overlay.source.minzoom">
      </div>
      <div class="h-4 mt-2 2xl:mt-0 pl-2 col-span-10 align-middle text-xs 
                  md:text-sm lg:text-sm xl:text-base 2xl:text-lg"
           v-show="showing && zoom >= overlay.source.minzoom">
        Natural Heritage Priority Sites
      </div>
    </div>
  </div>

</template>

<script>

  import axios from 'axios';
import { timeThursdays } from 'd3';

  export default {

    name: "NHPrioritySites",

    data(){
      return{
        data_error: '',
        data_name: 'priority_sites',
        hover_meta: false,
        hover_source: false,
        hover_title: false,
        label: `Natural Heritage Priority Sites in New Jersey, Edition 200703 
                (Envr_hab_prioritysite) (Web Mercator ArcGIS Online Service)`,
        labels:{
          'source':{
            'name': 'labels_nhps',
            'type': 'geojson',
            'data': 'https://blueprint-explorer.s3.amazonaws.com/labels/'
                    + 'nh_priority_sites.json'
          },
            'layer': {
            'id': 'labels_nhps',
            'type': 'symbol',
            'source': 'labels_nhps',
            'style': { 'font-scale': 0.8,
                        'text-color': '#d11d53',
                        'text-font': [
                          'literal',
                          ['DIN Offc Pro Italic', 
                          'Arial Unicode MS Regular']
                        ]},
            'label_column': 'name'
          }
        },

        overlay: {
          'source': {
            'name': 'nh-priority-sites',
            'type': 'vector',
            'tiles': ['https://d7wi8peueokva.cloudfront.net/'
                    + 'nh_priority_sites/{z}/{x}/{y}.pbf'],
            'minzoom': 6,
            'maxzoom': 16,
          },
          'layer': {
              'id': 'nh-priority-sites',
              'type': 'fill',
              'source': 'nh-priority-sites',
              'source_layer': 'default',
              'paint':  {
                'fill-color': '#F9A8D4',
                'fill-outline-color': '#d11d53',
                'fill-opacity': .8
              }
            }
        },
        showing: false,
        show_labels: false,
        source_data: {'metadata_url': '',
                      'source_url': '',
                      'description': ''},
      }
    },

    props:['show_features', 'label_layer_id', 'layers_to_load', 
           'map', 'map_loaded', 'reset', 'style_change', 'zoom'],

     emits: ['add', 'mounted', 'remove', 'popup'],

    mounted(){
      this.getLayerInfo();
      this.$emit('mounted', this.overlay.layer.id);
    },

    watch:{

      map_loaded: function(){
        this.addSources();
        if(this.layers_to_load.includes(this.overlay.layer.id)){
          this.addLayer();
          this.showing = true;
        }
      },

      reset: function(){
        this.reset_layer();
      },

      show_labels: function(){
        this.toggleLabels();
      },

      style_change: function(){
        this.addSources();
        if(this.showing){
          this.addLayer();
        }
      }
    },

    methods:{

      addInteractions(){
        this.removeInteractions();
        this.map.on('click', this.overlay.layer.id, this.handleLayerClick);
      },

      addLabels(){

        this.map.addLayer({
          'id': this.labels.layer.id,
          'type': this.labels.layer.type,
          'source': this.labels.source.name
        });

        this.map.setLayoutProperty(
          this.labels.layer.id,
          'text-field',
          ['format', 
            ['get', this.labels.layer.label_column], this.labels.layer.style
          ] 
        );
      },

      addLayer(){
        if(this.map.getLayer(this.overlay.layer.id)){
          return;
        }

        this.map.addLayer({
          'id': this.overlay.layer.id,
          'type': this.overlay.layer.type,
          'source': this.overlay.source.name, 
          'source-layer': this.overlay.layer.source_layer,
          'paint': this.overlay.layer.paint
        }, this.label_layer_id);

        if(this.map.getLayer('feature-borders')){
          this.map.moveLayer('feature-borders',this.label_layer_id);
        }

        this.addInteractions();
      },

      addSources(){
          if(this.map.getSource(this.overlay.source.name)){
              return;
          }
          
          this.map.addSource(this.overlay.source.name, {
              'type': this.overlay.source.type,
              'tiles': this.overlay.source.tiles,
              'minzoom': this.overlay.source.minzoom,
              'maxzoom': this.overlay.source.maxzoom,
          });

          this.map.addSource(this.labels.source.name, {
            'type': this.labels.source.type,
            'data': this.labels.source.data
          });
        },

      getLayerInfo(){
          let url = 'https://gspr34zib0.execute-api.us-east-1.';
              url += 'amazonaws.com/default/data-retriever?action=';
              url += `get_data_source_summary&data_name=${this.data_name}`;

          axios.get(url)
              .then(response => {
                  if(response.hasOwnProperty('data')){
                    if(response.data.hasOwnProperty('message')){
                        this.data_error = response.data.message;  
                    }else{
                        this.source_data = response.data;
                    }
                  }else{
                        this.data_error = 'Failed to get data from server.';  
                  }
              })
               .catch(error => {
                  this.data_error  = 'Data request error.' 
                                  + ' Server said: ' + error?error:'Nothing';  
              });
        },

      handleLayerClick(e){
        
        const html = `<p class="font-semibold text-stone-900 drop-shadow-sm"> 
                      Natural Heritage Priority Site</p><hr class="mb-1.5">
                       <p class="text-left">
                       ${e.features[0].properties.name}</p>
                      <p class="text-left">  
                      ${e.features[0].properties.description}</p>`;

        this.$emit('popup', {'id': this.overlay.layer.id, 'html': html,
                   'coords': e.lngLat});
      },

      removeLabels(){
        this.map.removeLayer(this.labels.layer.id)
      },

      removeLayer(){
        this.removeInteractions();
        this.map.removeLayer(this.overlay.layer.id);
      },

      removeInteractions(){
        this.map.off('click', this.overlay.layer.id, this.handleLayerClick);
      },

      reset_layer(){
        if(this.showing){
          this.removeLayer();
          this.$emit('remove', this.overlay.layer.id);
          this.showing = false;
        }
      },

      setOpacity(e){
        let value = e.target.value;
        this.map.setPaintProperty(this.overlay.layer.id, 'fill-opacity', 
                                                                  value/100);
      },

      toggleLabels(){
        if(!this.show_labels){
            this.removeLabels();
          }else{
            this.addLabels()
          }
      },

      toggleLayer(){
          if(this.showing){
            this.removeLayer();
            this.$emit('remove', this.overlay.layer.id);
          }else{
            if(this.zoom >= this.overlay.source.minzoom){
              this.addLayer();
              this.$emit('add', this.overlay.layer.id);
            }
          }
            this.showing = !this.showing;
      },
      
    }
  }

</script>
