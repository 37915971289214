<script>
    import small_logo from  "../assets/img/blueprint/logo.svg"

    export default class BlueprintClassicLink{

        constructor(url) {
          this.url = url;
          this.html =  `<div class="w-8 h-8 
               cursor-pointer rounded-md shadow-md bg-white justify-center i
               tems-center flex hover:bg-gray-50 border border-2 border-gray-200
               njmap-link"
               id="control_blueprint_classic" 
               title="Open location in Original Conservation Blueprint">
                <a id="classic-link" class="align-middle my-auto" 
                   href="${this.url}" target="_blank">
                  <img alt="Opens Conservation Blueprint on njmap.com" 
                       src="${small_logo}" class="block w-6">
                </a>
              </div>`;
        }

        onAdd(map) {
            this._map = map;
            this._container = document.createElement('div');
            this._container.className = 'mapboxgl-ctrl';
            this._container.innerHTML = this.html;
            return this._container;
        }

        onRemove() {
            this._container.parentNode.removeChild(this._container);
            this._map = undefined;
        }
    }

</script>  