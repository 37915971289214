<template>
   <div id="control_share-box"
        class="mapboxgl-ctrl mapboxgl-ctrl-icon mapboxgl-ctrl-group W-10 H-10
               cursor-pointer rounded-md shadow-md bg-white text-center 
               hover:bg-gray-50 share-control"
        title="Copy map link"
        @click="getLink" >
        <div class="relative top-0 right-0 w-200 h-7 mx-2 my-auto p-1 Open+Sans 
                    share-control font-semibold"
             v-show="short_link || getting_link || link_copied">
            {{short_link}}
            <span v-show="link_copied">Copied!</span>
            <img :src="copy_svg"  class="w-6 h-6 -mr-2 ml-2 -mt-0.5 float-right"
                 alt="Copies the shortened link to the clipboard"  
                 @click="copyLink">
            <img :src="dots_gif" v-show="getting_link && !short_link" 
                  alt="Animated dots"  
                 class="h-4 my-auto mx-0">
        </div>
        <img :src="share_svg"
              alt="Creates a shortened link to the current map"    
             v-show="!short_link && !getting_link  && !link_copied"
             class="w-5 h-5 m-1 share-control">
   </div>
</template>

<script>

  import share_svg from "../assets/img/icons/share.svg"
  import copy_svg from "../assets/img/icons/copy.svg"
  import dots_gif from "../assets/img/misc/dots.gif"

    export default {

        name: 'ShareControl',

        props: ['map', 'share_link', 'short_link'],

        emits:['share_control_added', 'create_link', 'delete_link'],

        data() {
            return {
                share_svg,
                copy_svg,
                dots_gif,
                getting_link: false,
                link_copied: false,
                ctl: null,
                show_link: false
            }
        },

        watch:{

            map: function(){
                this.map.addControl(this.ctl, "top-right");
                this.$emit('share_control_added');
            }
        },

        created(){

            class shareControl {
                onAdd(map) {
                    this._map = map;
                    this._container = document.getElementById(
                                                'control_share-box');
                    return this._container;
                }

                onRemove() {
                    this._container.parentNode.removeChild(this._container);
                    this._map = undefined;
                }
            }
                
            this.ctl = new shareControl();
        },

        methods:{

            abortLink(e){
                if(e.target){
                    if(e.target.classList.contains('share-control')){
                        return;
                    }
                }else{
                    return;
                }
                
                if(e.key){
                    if(e.key !== "Escape"){
                        return;
                    }
                }

                this.getting_link = false;
                this.link_copied = false;
                this.$emit('delete_link');
                this.removeAbortLinkEvent();
            },

            addAbortLinkEvent(){
                  document.addEventListener('keydown', this.abortLink);
                  document.addEventListener('click', this.abortLink);
              },

            copyLink(e){
                e.stopImmediatePropagation();
                
                navigator.clipboard.writeText(this.short_link)
                .then(() => {
                    this.$emit('delete_link');
                    this.getting_link = false;
                    this.link_copied = true;
                    setTimeout(()=>{this.link_copied = false;}, 1000);
                })
                .catch(err => {
                })
                .finally(() => {
                    this.removeAbortLinkEvent();
                });
            },

            getLink(){
                if(!this.share_link){
                    return;
                }
                this.getting_link = true;
                this.$emit('create_link');
                this.addAbortLinkEvent();
            },

            removeAbortLinkEvent(e){
                document.removeEventListener('keydown', this.abortLink);
                document.removeEventListener('click', this.abortLink);
            }
        }
    }

</script>