<template>
  
  <div class="md:ml-1.5 bg-white font-Open+Sans overflow-y-scroll 
              absolute top-14 xl:top-20 left-0 right-0 mt-0 pb-44"
      :style="{'height': `${panel_height}px`}">

      <!-- START PARCEL BUTTONS -->
      <div class="sticky top-0 mr-1.5 z-50 bg-white"> 
          <div class="flex justify-center">
              <div class="sticky flex-grow relative items-center 
                          text-sm md:text-base xl:text-lg 2xl:text-xl 
                          cursor-pointer text-blue-500 font-Open+Sans 
                          flex justify-center bg-white
                          p-2 rounded-t-xl rounded-[0.4375rem] 
                          hover:bg-blue-50 shadow-inner 
                          border-blue-200 border-l border-t border-r"
                    :class="{'font-semibold':parcel_on_click}" >
                  <label  
                          for="parcel-click"
                          @click="toggleParcelStatus">
                          {{parcel_select_text[parcel_click]}}
                  </label>

                  <input class="opacity-75 hover:opacity-100
                                ml-1 h-3 w-6 appearance-none rounded-[0.375rem] 
                                before:pointer-events-none before:absolute 
                                before:h-3 before:w-3 before:rounded-full 
                                before:bg-transparent before:content-[''] after:absolute 
                                after:z-[2] after:-mt-[0.125rem] after:h-4 after:w-4 
                                after:rounded-full after:border-none after:bg-neutral-100 
                                after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)]
                                after:transition-[background-color_0.2s,transform_0.2s,transform_0.2s] 
                                after:content-[''] checked:bg-primary checked:after:absolute 
                                checked:after:z-[2] checked:after:-mt-[2px] 
                                checked:after:ml-[0.875rem] checked:after:h-4 
                                checked:after:w-4 checked:after:rounded-full 
                                checked:after:border-none checked:after:bg-primary 
                                checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] 
                                checked:after:transition-[background-color_0.2s,transform_0.2s] 
                                checked:after:content-[''] hover:cursor-pointer 
                                focus:outline-none focus:ring-0 focus:before:scale-100 
                                focus:before:opacity-[0.12] 
                                focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] 
                                focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                                focus:after:absolute focus:after:z-[1] focus:after:block 
                                focus:after:h-4 focus:after:w-4 focus:after:rounded-full 
                                focus:after:content-[''] checked:focus:border-primary 
                                checked:focus:bg-primary checked:focus:before:ml-[0.875rem] 
                                checked:focus:before:scale-100 
                                checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] 
                                checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                                dark:bg-neutral-600 dark:after:bg-neutral-400 
                                dark:checked:bg-primary dark:checked:after:bg-primary 
                                dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] 
                                dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                      :class="{'bg-emerald-400':parcel_click,
                      'bg-red-400':!parcel_click}"
                      type="checkbox"
                      role="switch"
                      @change="updateParcelClick"
                      v-model="parcel_click"
                      id="setting_parcel-click"
                  />
              </div>
          </div>
      </div>
      <!-- END PARCEL BUTTONS -->

      <!-- START RESET BUTTON -->
      <div class="sticky mr-5 top-10 h-0 z-50 opacity-50 hover:opacity-100">
          <div class="inline float-right">
              <div class="flex justify-center">
                  <div class="inline text-sm ml-1 mt-4 font-semibold 
                              text-gray-600
                              ">
                      <input  class="px-1 pb-1 bg-white rounded-[0.4375rem]
                                    cursor-pointer font-Open+Sans
                                    border-2 border-black border-opacity-5"
                          type="button"
                          @click="reset = !reset"
                          value="Reset"
                      >
                  </div>
              </div>
          </div>
      </div>
      <!-- END RESET BUTTON -->
      
      
      
      <div class="text-base pt-2 mr-2 bg-ref_comp_bg rounded-[0.4375rem] px-1 pb-1 mt-2">
          <!-- START BOUNDARIES SECTION -->
          <div class="text-base md:text-lg xl:text-2xl 2xl:text-3xl text-white
                              text-left font-semibold ml-3">
              <span>Boundaries</span>
          
          </div>

          <div>
              <feature-borders class="pl-1 my-1"
                  v-bind:show_features = "show_features"
                  v-bind:label_layer_id = "label_layer_id"
                  v-bind:layers_to_load = "layers_to_load"
                  v-bind:reset = "reset"
                  v-bind:map = "map"
                  v-bind:map_loaded = "map_loaded"
                  v-bind:move_border_layer = "move_border_layer"
                  v-bind:style_change = "style_change"
                  v-bind:zoom = "zoom"
                  @add = "addOverlay"
                  @mounted = "updateMountedOverlayCount"
                  @remove = "removeOverlay">
              </feature-borders>
              <municipal-borders class="pl-2 my-1"
                  v-bind:show_features = "show_features"
                  v-bind:label_layer_id = "label_layer_id"
                  v-bind:layers_to_load = "layers_to_load"
                  v-bind:reset = "reset"
                  v-bind:map = "map"
                  v-bind:map_loaded = "map_loaded"
                  v-bind:move_border_layer = "move_border_layer"
                  v-bind:style_change = "style_change"
                  v-bind:zoom = "zoom"
                  @add = "addOverlay"
                  @mounted = "updateMountedOverlayCount"
                  @remove = "removeOverlay">
              </municipal-borders>
              <county-borders class="pl-2 my-1"
                  v-bind:show_features = "show_features"
                  v-bind:label_layer_id = "label_layer_id"
                  v-bind:layers_to_load = "layers_to_load"
                  v-bind:reset = "reset"
                  v-bind:map = "map"
                  v-bind:map_loaded = "map_loaded"
                  v-bind:move_border_layer = "move_border_layer"
                  v-bind:style_change = "style_change"
                  v-bind:zoom = "zoom"
                  @add = "addOverlay"
                  @mounted = "updateMountedOverlayCount"
                  @remove = "removeOverlay">
              </county-borders>
          
          </div>
          <!-- END BOUNDARIES SECTION -->


         </div>



      <!-- START IMAGERY SECTION -->
      <div class="text-base mt-3 pt-2 mr-2 bg-ref_comp_bg rounded-[0.4375rem] px-1 pb-1 mb-1">

          <div class="text-base pb-1 md:text-lg xl:text-2xl 2xl:text-3xl text-white
                              text-left font-semibold ml-3">
              <span>Imagery</span>
              <a href="https://njgin.nj.gov/njgin/edata/imagery/index.html#!/" 
             target="_blank"
             title="New Jersey Geographic Information Network"
             class="text-xs xl:text-sm 2xl:text-lg text-hyper_link
                    hover:underline pl-2 cursor-pointer">(NJGIN)</a>
          
          </div>
              <imagery 
                  v-bind:basemap_changed = "basemap_changed"
                  v-bind:show_features = "show_features"
                  v-bind:layers_to_load = "layers_to_load"
                  v-bind:map = "map"
                  v-bind:map_loaded = "map_loaded"
                  v-bind:reset = "reset"
                  v-bind:style_change = "style_change"
                  v-bind:zoom = "zoom"
                  @add = "addOverlay"
                  @mounted = "updateMountedOverlayCount"
                  @remove = "removeOverlay">
              </imagery>

      </div>
      <!-- ENDS IMAGERY SECTION -->

      <!-- START PRESERVED LAND SECTION -->
      <div class="text-base mt-3 pt-2 mr-2 bg-ref_comp_bg rounded-[0.4375rem] px-1 pb-1 mb-1">

          <div class="text-base md:text-lg xl:text-2xl 2xl:text-3xl text-white
                              text-left font-semibold ml-3">
              <span>Preserved Land</span>
          
          </div>
          <div>
              <openspace-index class="pl-2 my-1"
                  v-bind:show_features = "show_features"
                  v-bind:label_layer_id = "label_layer_id"
                  v-bind:layers_to_load = "layers_to_load"
                  v-bind:map = "map"
                  v-bind:map_loaded = "map_loaded"
                  v-bind:reset = "reset"
                  v-bind:style_change = "style_change"
                  v-bind:zoom = "zoom"
                  @add = "addOverlay"
                  @mounted = "updateMountedOverlayCount"
                  @remove = "removeOverlay"
                  @popup = "handlePopup">
              </openspace-index>
          </div>

          
      </div>
      <!-- END PRESERVED LAND SECTION -->

      <!-- START LAND USE SECTION -->

      <div class="text-base mt-3 pt-2 mr-2 bg-ref_comp_bg rounded-[0.4375rem] px-1 pb-1 mb-1">

          <div class="text-base md:text-lg xl:text-2xl 2xl:text-3xl text-white
                              text-left font-semibold ml-3">
              <span>Land Use</span>
          
          </div>
          <lulc-2015 class="pl-2 my-1"
            v-bind:show_features = "show_features"
            v-bind:label_layer_id = "label_layer_id"
            v-bind:layers_to_load = "layers_to_load"
            v-bind:map = "map"
            v-bind:map_loaded = "map_loaded"
            v-bind:reset = "reset"
            v-bind:style_change = "style_change"
            v-bind:zoom = "zoom"
            @add = "addOverlay"
            @mounted = "updateMountedOverlayCount"
            @remove = "removeOverlay"
            @popup = "handlePopup">
          </lulc-2015>
      </div>
      <!-- END LAND USE SECTION -->

      <!-- START PLANNING SECTION -->
      <div class="text-base mt-3 pt-2 mr-2 bg-ref_comp_bg rounded-[0.4375rem] px-1 pb-1 mb-1">

          <div class="text-base md:text-lg xl:text-2xl 2xl:text-3xl text-white
                              text-left font-semibold ml-3">
              <span>Planning</span>
          
          </div>

          <njcf-regions class="pl-2 my-1"
            v-bind:show_features = "show_features"
            v-bind:label_layer_id = "label_layer_id"
            v-bind:layers_to_load = "layers_to_load"
            v-bind:map = "map"
            v-bind:map_loaded = "map_loaded"
            v-bind:reset = "reset"
            v-bind:style_change = "style_change"
            v-bind:zoom = "zoom"
            @add = "addOverlay"
            @mounted = "updateMountedOverlayCount"
            @remove = "removeOverlay"
            @popup = "handlePopup">
          </njcf-regions>

          <dvwi-clusters class="pl-2 my-1"
            v-bind:show_features = "show_features"
            v-bind:label_layer_id = "label_layer_id"
            v-bind:layers_to_load = "layers_to_load"
            v-bind:map = "map"
            v-bind:map_loaded = "map_loaded"
            v-bind:reset = "reset"
            v-bind:style_change = "style_change"
            v-bind:zoom = "zoom"
            @add = "addOverlay"
            @mounted = "updateMountedOverlayCount"
            @remove = "removeOverlay"
            @popup = "handlePopup">
          </dvwi-clusters>
          <dvwi-focus-areas class="pl-2 my-1"
            v-bind:show_features = "show_features"
            v-bind:label_layer_id = "label_layer_id"
            v-bind:layers_to_load = "layers_to_load"
            v-bind:map = "map"
            v-bind:map_loaded = "map_loaded"
            v-bind:reset = "reset"
            v-bind:style_change = "style_change"
            v-bind:zoom = "zoom"
            @add = "addOverlay"
            @mounted = "updateMountedOverlayCount"
            @remove = "removeOverlay"
            @popup = "handlePopup">
          </dvwi-focus-areas>

          <ag-development-areas class="pl-2 my-1"
            v-bind:show_features = "show_features"
            v-bind:label_layer_id = "label_layer_id"
            v-bind:layers_to_load = "layers_to_load"
            v-bind:map = "map"
            v-bind:map_loaded = "map_loaded"
            v-bind:reset = "reset"
            v-bind:style_change = "style_change"
            v-bind:zoom = "zoom"
            @add = "addOverlay"
            @mounted = "updateMountedOverlayCount"
            @remove = "removeOverlay"
            @popup = "handlePopup">
          </ag-development-areas>

          <sewer-service-area class="pl-2 my-1"
            v-bind:show_features = "show_features"
            v-bind:label_layer_id = "label_layer_id"
            v-bind:layers_to_load = "layers_to_load"
            v-bind:map = "map"
            v-bind:map_loaded = "map_loaded"
            v-bind:reset = "reset"
            v-bind:style_change = "style_change"
            v-bind:zoom = "zoom"
            @add = "addOverlay"
            @mounted = "updateMountedOverlayCount"
            @remove = "removeOverlay"
            @popup = "handlePopup">
          </sewer-service-area>
      </div>
        <!-- END PLANNING SECTION -->


        <!-- START WATER SECTION -->
      <div class="text-base mt-3 pt-2 mr-2 bg-ref_comp_bg rounded-[0.4375rem] px-1 pb-1 mb-1">

          <div class="text-base md:text-lg xl:text-2xl 2xl:text-3xl text-white
                              text-left font-semibold ml-3">
              <span>Water</span>
          </div>
          <huc14-watersheds class="pl-2 my-1"
            v-bind:show_features = "show_features"
            v-bind:label_layer_id = "label_layer_id"
            v-bind:layers_to_load = "layers_to_load"
            v-bind:map = "map"
            v-bind:map_loaded = "map_loaded"
            v-bind:reset = "reset"
            v-bind:style_change = "style_change"
            v-bind:zoom = "zoom"
            @add = "addOverlay"
            @mounted = "updateMountedOverlayCount"
            @remove = "removeOverlay"
            @popup = "handlePopup">
          </huc14-watersheds>
      </div>
      <!-- END WATER SECTION -->
      
      <!-- START BLUEPRINT MODELS SECTION -->
      <div class="text-base mt-3 pt-2 mr-2 bg-ref_comp_bg rounded-[0.4375rem] px-1 pb-1 mb-1">

          <div class="text-base md:text-lg xl:text-2xl 2xl:text-3xl text-white
                              text-left font-semibold ml-3">
              <span>Blueprint Models</span><br>
              <span class="text-xs lg:text-sm xl:text-base 2xl:text-xl
                   text-white">&laquo; Non-parcel based | Imagery only &raquo;</span>
          </div>
          <blueprint-rasters class="pl-2 my-1"
            v-bind:basemap_changed = "basemap_changed"
            v-bind:label_layer_id = "label_layer_id"
            v-bind:layers_to_load = "layers_to_load"
            v-bind:map = "map"
            v-bind:map_loaded = "map_loaded"
            v-bind:reset = "reset"
            v-bind:style_change = "style_change"
            v-bind:symbology = "symbology"
            v-bind:zoom = "zoom"
            @add = "addOverlay"
            @mounted = "updateMountedOverlayCount"
            @remove = "removeOverlay">
          </blueprint-rasters>

      </div>
      <!-- END BLUEPRINT MODELS SECTION -->





      <div class="pt-4 bg-white text-center">
        <span class="text-base text-cbp opacity-20">
          engage. protect. restore.
        </span>
        <img :src="blueprint_logo_footer_svg"
               alt="Conservation Blueprint footer logo"  
              class="pt-3 opacity-10 w-7/12 block mx-auto">
      </div>
      <div class="h-9 text-center bg-white flex content-center items-end"
          v-if="small_screen"
          v-show="panel_open"
        @click="closePanel">
        <div class="h-5 py-2 w-12 inline align-middle my-auto mx-auto
                    bg-gray-300 hover:bg-blue-400  rounded-full">
            <img :src="down_svg" class="inline align-top w-5 h-5 -mt-2"
            alt="Hides Conservation Blueprint side panel">
        </div>
      </div>
    </div>

</template>

<script>
    import AgriculturalDevelopmentAreas 
                      from "./overlays/AgriculturalDevelopmentAreas"
    import blueprint_logo_footer_svg 
                      from "../assets/img/logos/blueprint_logo_footer.svg"
    import BlueprintRasters from "./overlays/BlueprintRasters"
    import CountyBorders from "./overlays/CountyBorders"
    import down_svg from "../assets/img/icons/down_arrow.svg"
    import DvwiClusters from "./overlays/DvwiClusters"
    import DvwiFocusAreas from "./overlays/DvwiFocusAreas"
    import FeatureBorders from "./overlays/FeatureBorders"
    import HUC14Watersheds from "./overlays/HUC14Watersheds"
    import ImagerySelection from "./overlays/ImagerySelection"
    import LULC2015 from "./overlays/LULC2015_level_1"
    import mapboxgl from 'mapbox-gl'
    import MunicipalBorders from "./overlays/MunicipalBorders"
    import NjcfRegions from "./overlays/NjcfRegions"
    import OpenspaceIndex from "./overlays/OpenspaceIndex"
    import SewerServiceArea from './overlays/SewerServiceArea'

    export default {
                
        name: 'ReferenceOverlays',

        components: {
          "ag-development-areas": AgriculturalDevelopmentAreas,
          "blueprint-rasters": BlueprintRasters,
          "county-borders": CountyBorders,
          "dvwi-clusters": DvwiClusters,
          'dvwi-focus-areas': DvwiFocusAreas,
          "feature-borders": FeatureBorders,
          "huc14-watersheds": HUC14Watersheds,
          "imagery": ImagerySelection,
          "lulc-2015": LULC2015,
          "municipal-borders": MunicipalBorders,
          "njcf-regions": NjcfRegions,
          "openspace-index": OpenspaceIndex,
          "sewer-service-area": SewerServiceArea
        },

        props:[
          'basemap_changed',
          'show_features',
          'label_layer_id',
          'layers_to_load',
          'map',
          'mapbox_token',
          'map_loaded',
          'panel_height',
          'panel_open',
          'parcel_on_click',
          'selected',
          'small_screen',
          'style_change',
          'symbology',
          'zoom'
        ],

        emits: ['all_reference_layers_loaded', 'close_panel', 'overlays',
                'parcel_on_click', 'popup'],

        data() {
          return {
            blueprint_logo_footer_svg,
            down_svg,
            labels: true,
            layers:[],
            move_border_layer: false,
            overlay_count: 6,
            overlays_mounted: 0,
            parcel_click: this.parcel_on_click || false,
            parcel_select_text: {
              true: 'Map Click Selects New Parcel',
              false: 'Map Click Selects New Parcel',
            },
            popup: null,
            popup_coords: {'lng': 0, 'lat': 0},
            popup_html: '',
            reset: false
          }
        },
        watch:{
          // This updates parcel_click when the prop parcel_on_click is passed down and is a different value. Causes parcel_click to be the same value as parcel_on_click from the parent
          parcel_on_click: function(val){
            this.parcel_click = val;

          },
        },

        methods:{
          addOverlay(id){
            this.layers.push(id);
            this.$emit('overlays', this.layers);
            this.move_border_layer = !this.move_border_layer;
            this.pushToAnalytics(id);
          },

          closePanel(){
            this.$emit('close_panel')
          },

          handlePopup(obj){
            if(this.popup_coords.lat != obj.coords.lat || 
               this.popup_coords.lng != obj.coords.lng){         
              this.openPopup(obj)
            }else{
              this.updatePopup(obj);   
            }
          },

          openPopup(obj){
            if(this.popup){
              this.popup.remove();
            }

            const el = document.createElement('div');

            el.setAttribute("id", obj.id);
            el.innerHTML = obj.html;
        
            this.popup = new mapboxgl.Popup({anchor: "top",'offset': 5})
                .setLngLat(obj.coords)
                .setHTML(el.outerHTML)
                .addTo(this.map);

            this.popup_coords = obj.coords;
            this.popup_html = el.outerHTML;
            this.popup.on('close', () => {
              this.$emit('popup');
            });
            this.$emit('popup', this.popup);

          },

          pushToAnalytics(id){
            dataLayer.push({
              'event': 'layer_view',
              'layer_id': id
            });
          },

          removeLayerFromPopup(id){
            const el = document.getElementById(id);

            if(!el){ return; }
            el.remove();
            const cl = document
                          .getElementsByClassName('mapboxgl-popup-content')[0];
            if(cl.childElementCount < 2){
              this.popup.remove();
            }
          },

          removeOverlay(id){
            const idx = this.layers.indexOf(id);

            if (idx > -1) {
              this.layers.splice(idx, 1);
              this.$emit('overlays', this.layers);
            }

            this.removeLayerFromPopup(id);
          },
          toggleParcelStatus(){
              this.parcel_click = !this.parcel_click;
              this.updateParcelClick();
          },

          updateMountedOverlayCount(id){
            this.overlays_mounted += 1;
            if(this.overlays_mounted == this.overlay_count){
              this.$emit('all_reference_layers_loaded', 'reference');
            }
          },

          updateParcelClick(){
            this.$emit('parcel_on_click', this.parcel_click);
          },

          updatePopup(obj){
            const el = document.createElement('div');
            const id = obj.hasOwnProperty('id')?obj.id:'unknown-popup-content';

            el.setAttribute("id", id);
            el.classList.add('mt-6');
            el.innerHTML = obj.html;
            this.popup_html = this.popup_html + el.outerHTML
            this.popup.setHTML(this.popup_html);
          }
        },
      
        mounted(){
          this.$emit('overlays', this.layers);
        }
    }

</script>
