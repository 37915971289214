<template>

    <!-- WORKING[LOADING] PANEL -->

    <div class="text-center w-full min-h-screen"
          v-show="!blueprint_status && !modiv_in && working==true ">
        <span class=" py-16 text-2xl inline-block align-middle text-stone-500">
          WORKING
        </span>
    </div>

    <!-- END WORKING[LOADING] PANEL -->

    <!-- START PARCEL INFO PANEL -->
    <div class="max-h-min bg-white md:pt-0 border-t-2 overflow-y-scroll 
                font-Open+Sans"
        :style="{'height': `${panel_height}px`}">

        <!-- MESSAGE PRIOR TO SELECTING PARCEL -->
        <div class="w-full text-center py-6 text-2xl text-slate-500"
              v-if="feature_id <= 0">
            <span>No parcel selected</span>
        </div>

        <div class="bg-white relative"
              v-if="feature.message">
            <span class="pl-2 text-gray-400">
                Error!  Server said: 
            </span>
            <span class="italic">
                {{feature.message}}
            </span>
        </div>
      

        <!-- START DATA AREA -->
        <div class="bg-white relative" 
              v-if="!feature.message && 
              feature_id != '0' && 
              modiv_in">

            <!-- START TOP DATA SECTION -->
            <div class="w-full h-auto px-4 pb-4 mr-2 font-normal text-lg xl:text-2xl
                    shadow-sm border-t border-gray-200
                    clear-both grid grid-cols-9">

                <!-- START TOP LEFT DATA SECTION -->
                <div class="text-sm xl:text-xl text-left pb-4 col-span-5">

                    <!-- START PARCEL SELECT SLIDER -->
                    <div class="flex flex-row items-center text-sm ml-1 mt-2 pb-1 px-1 font-semibold 
                              text-gray-600 cursor-pointer  
                              flex justify-center bg-white bg-opacity-50 
                              rounded-[0.4375rem] font-Open+Sans"
												:class="{'font-semibold':parcel_on_click,
																'font-normal':!parcel_on_click}">
                        <label  
                                for="parcel-click"
                                @click="toggleParcelStatus">
                                {{parcel_select_text[parcel_click]}}
                        </label>
                        <input class="opacity-75 hover:opacity-100
                                        ml-1 h-3 w-6 appearance-none rounded-[0.375rem]
                                        before:pointer-events-none before:absolute 
                                        before:h-2.5 before:w-2.5 before:rounded-full 
                                        before:bg-transparent before:content-[''] after:absolute 
                                        after:z-[2] after:-mt-[0.0625rem] after:h-3.5 after:w-3.5 
                                        after:rounded-full after:border-none after:bg-neutral-100 
                                        after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)]
                                        after:transition-[background-color_0.2s,transform_0.2s] 
                                        after:content-[''] checked:bg-primary checked:after:absolute 
                                        checked:after:z-[2] checked:after:-mt-[0.0625rem] 
                                        checked:after:ml-[0.625rem] checked:after:h-3.5 
                                        checked:after:w-3.5 checked:after:rounded-full 
                                        checked:after:border-none checked:after:bg-primary 
                                        checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] 
                                        checked:after:transition-[background-color_0.2s,transform_0.2s] 
                                        checked:after:content-[''] hover:cursor-pointer 
                                        focus:outline-none focus:ring-0 focus:before:scale-100 
                                        focus:before:opacity-[0.12] 
                                        focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] 
                                        focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                                        focus:after:absolute focus:after:z-[1] focus:after:block 
                                        focus:after:h-3.5 focus:after:w-3.5 focus:after:rounded-full 
                                        focus:after:content-[''] checked:focus:border-primary 
                                        checked:focus:bg-primary checked:focus:before:ml-[0.625rem] 
                                        checked:focus:before:scale-100 
                                        checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] 
                                        checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                                        dark:bg-neutral-600 dark:after:bg-neutral-400 
                                        dark:checked:bg-primary dark:checked:after:bg-primary 
                                        dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] 
                                        dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                          :class="{'bg-emerald-400':parcel_click,
                          'bg-red-400':!parcel_click}"
                          type="checkbox"
                          role="switch"
                          @change="updateParcelClick"
                          v-model="parcel_click"
                          id="setting_parcel-click"
                        />
                    </div>
                    <!-- END PARCEL SELECT SLIDER -->
                    <hr class="my-1.5">

                    <div class="px-1 pb-1"
                          v-show="feature.muni">
                        <span>{{feature.muni}}</span>
                        
                    </div>

                    <div class="px-1 grid grid-cols-2"
                          v-show="feature.block">
                        <div class="text-gray-500 text-right mr-4">
                            Block:
                        </div>
                        <div>
                            {{feature.block}}
                        </div>
                    </div>
                    <div class="px-1 grid grid-cols-2"
                          v-show="feature.lot">
                        <div class="text-gray-500 text-right mr-4">
                            Lot:
                        </div>
                        <div>
                            {{feature.lot}}
                        </div>
                    </div>


                    <div class="px-1 grid grid-cols-2"
                        v-if="feature.qualifier">
                        <div class="text-gray-500 text-right mr-4">
                            Qualifier:
                        </div>
                        <div>
                            {{feature.qualifier}}
                        </div>
                    </div>
                    <hr class="my-1.5">
                    <div class="px-1 grid grid-cols-2 mt-2">
                        <div class="text-gray-500 text-right mr-4"
                              v-if="feature.acreage">
                            Area:
                        </div>
                        <div v-if="feature.acreage">
                            {{feature.acreage == 'NaN'?'N/A':
                            (Math.round(10 * feature.acreage)/10).toLocaleString()+' acres'}}
                        </div>
                        <div class="text-gray-500 text-right mr-4">
                            Preserved:
                        </div>
                        <div>
                            {{feature.response_data.os==0?'No':feature.response_data.os==1?
                              'Yes - Open Space':feature.response_data.os==2?
                              'Yes - Farmland':feature.response_data.os==3?
                              'Yes - Open Space (Military)':
                              'Yes - Farmland (Military)'}}
                        </div>
                        <div class="text-gray-500 text-right mr-4">
                            Sewer Service:
                        </div>
                        <div>
                            {{ feature.response_data.sewers.length==0?'No':'Yes' }}
                        </div>
                    </div>
                </div>
                <!-- END TOP LEFT DATA SECTION -->

                <!-- START ECOLOGICAL SCORE SECTION[TOP RIGHT] -->
                <div class="w-full h-full align-middle pt-4 pl-2 col-span-4 font-bold
                            text-blue-500 text-center border-l 
                            border-gray-200">
                    <div>
                        {{titles[selected]}}
                    </div>
                    <div class="pt-6 text-2xl">
                        {{(Math.round(feature[score_ids[selected]] * 10) / 10)
                        .toFixed(1)}}
                    </div>
                    <div class="pt-3 text-2xl">
                        <span v-if="selected==3">{{relative_ag_priority}}</span>
                        <span v-if="selected==4">{{relative_eco_priority}}</span>
                        <span v-if="selected==5">{{relative_wa_priority}}</span>
                    </div>
                </div>
                <!-- END ECOLOGICAL SCORE SECTION[TOP RIGHT]-->
            </div>
            <!-- END TOP DATA SECTION -->

            <!-- START PRINT/SAVE/CLOSE PANEL -->
            <div id="info-actions-container"
                  class="grid grid-cols-5 h-auto bg-blue-50 mb-1 align-bottom 
                          border-t border-gray-200 pt-2 xl:py-3 rounded-sm mt-1 text-sm 
                          md:text-base xl:text-2xl">

                <div class="col-span-1 col-start-2 cursor-pointer mx-auto"
                      @click="printFeature($event)">
                    <div class="cursor-pointer" title="Print Report">
                        <img :src="print_svg"
                              alt="Prints a Conservation Blueprint parcel report"  
                              class="h-6 md:h-7 xl:h-8  w-6 md:w-7 xl:w-8 mx-auto">
                    </div> 
                    <div class="h-full text-blue-400 mx-auto
                                hover:text-blue-600" >
                                <span>Print</span>
                    </div>
                </div>
                <div class="col-span-1 cursor-pointer mx-auto"
                      @click="saveFeature($event)">
                    <div class="cursor-pointer" title="Save">
                        <img class="h-6 md:h-7 xl:h-8  w-6 md:w-7 xl:w-8 mx-auto"
                        alt="Removes a Conservation Blueprint saved parcel from the 
                            saved parcel list" 
                            :src="saved?bookmark_remove_svg:bookmark_add_svg">
                    </div> 
                    <div class="h-full text-blue-400 mx-auto
                                hover:text-blue-600"
                          v-show="!saved">
                          <span>Save</span>
                    </div>
                    <div class="h-full text-blue-400 mx-auto
                                hover:text-blue-600"
                          v-show="saved" >
                          <span>Saved</span>
                    </div>
                </div>
                                        
                <div class="col-span-1 cursor-pointer mx-auto"
                      @click="removeTarget($event)">
                    <div class="cursor-pointer" title="Remove">
                        <img :src="delete_svg"
                            alt="Deselects the selected Conservation Blueprint parcel." 
                            class="h-6 md:h-7 xl:h-8  w-6 md:w-7 xl:w-8 mx-auto">
                    </div> 
                    <div class="h-full text-blue-400 mx-auto
                                hover:text-blue-600">
                        <span>Close</span>
                    </div>
                </div>
            </div>
            <!-- END PRINT/SAVE/CLOSE PANEL -->

            <!-- START PARCEL DATA SECTION[BOTTOM] -->
            <div class="w-full">
                <div id="single-model-data" 
                      class="overflow-y-auto"
                      v-if="parseInt(this.selected)!=6">
                    <table  class="table-fixed w-full
                              font-normal text-sm mb-4 mt-0.5 md:ml-2">
                        <tbody>
                            <tr class="h-auto xl:my-1 font-semibold text-gray-500">
                                <td colspan="9" 
                                      class="pb-1 xl:py-3 text-sm md:text-lg xl:text-2xl 
                                              text-bold text-center">
                                    <span>Component Scores</span>
                                </td>
                            </tr>
                            <tr class="h-auto text-xs md:text-sm xl:text-base" 
                                v-for="(value, key) in 
                                    this.filtered_component_data[
                                    this.ids[
                                    this.selected]]" 
                                :key="key">
                                <td colspan="6" class="pr-1 text-right text-gray-500">
                                    {{prettifyString(key)}} 
                                    <span class="text-gray-300"
                                            v-show="selected!=3">({{ max_scores[key] }})
                                    </span>
                                </td>
                                <td colspan="3" class="pl-1 text-left">
                                    {{prettifyString(value)}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- END PARCEL DATA SECTION[BOTTOM] -->
        </div>
        <!-- END DATA AREA -->

        <!-- START MODIV DATA[BOTTOM OF PAGE] -->
        <div class="clear-all">
            <modiv
                v-bind:feature_id = "real_id"
                @data_in="updateModivStatus"
                @modiv_data="updateModivData">
            </modiv>
        </div>

        <div class="pt-4 bg-white text-center">
            <span class="text-base text-cbp opacity-20">
              engage. protect. restore.
            </span>
            <img :src="blueprint_logo_footer_svg"
                  alt="Conservation Blueprint footer logo" 
                  class="pt-3 opacity-10 w-7/12 block mx-auto">
        </div>
        <!-- END MODIV DATA[BOTTOM OF PAGE] -->

    </div>
    <!-- END PARCEL INFO PANEL -->

</template>

<script>

    import bookmark_add_svg from "../assets/img/icons/bookmark_add.svg"
    import bookmark_add_hover_svg from 
                                  "../assets/img/icons/bookmark_add_hover.svg"
    import blueprint_logo_footer_svg from 
                                "../assets/img/logos/blueprint_logo_footer.svg";
    import bookmark_remove_svg from "../assets/img/icons/bookmark_remove.svg"
    import bookmark_remove_hover_svg from 
                                "../assets/img/icons/bookmark_remove_hover.svg"
    import delete_svg from "../assets/img/icons/delete.svg"
    import delete_hover_svg from "../assets/img/icons/delete_hover.svg"
    import {FeatureGetter} from "../assets/js/FeatureGetterClass"
    import Modiv from './ModivComponent.vue';
    import print_svg from "../assets/img/icons/print.svg"
    import print_hover_svg from "../assets/img/icons/print_hover.svg"

    export default {  

        name: 'InfoPanel',
    
        components: {
          "modiv": Modiv
        },

        emits: ['close', 'current_feature', 'data_in','data_restored',
                'parcel_on_click', 'print', 'save_feature','unsave_feature'],

        props:[ 'blueprint_status','feature_id', 'info_marker_coords', 
                'panel_height', 'parcel_on_click', 'restore_feature_data', 'selected'],

        watch:{

            feature_id: function(newVal, oldVal){ 
                if(this.feature_id == '0'){
                    this.reset();
                    return;
                }

                if(oldVal == -1 && newVal != -1){
                  return;
                }
                
                if(this.restore_feature_data){
                    this.restoreFeatureData();
                    return;
                }
                
                this.getData();

            },

            modiv_data: function(){
                if(this.feature){
                    this.appendModivData();
                }
            },
            // This updates parcel_click when the prop parcel_on_click is passed down and is a different value. Causes parcel_click to be the same value as parcel_on_click from the parent
            parcel_on_click: function(val){
              this.parcel_click = val;

            },

            selected: function(){
                if(this.feature_id == '0'){
                    return;
                }
            }
        },

        computed: {

          real_id: function(){

            if(!this.feature_id){
              return null;
            }else if (this.feature){
              if(this.feature.hasOwnProperty('feature_id')){
                return this.feature.feature_id;
              }
            }
            
            return null;
          },

          /**
           * Computed variable.  Returns relative agricultural model ranking.
           * @returns {text}
           */
          relative_ag_priority: function(){

            if(this.feature.ag_score >= 4 && 
              this.feature.ag_score <= 5){
              return 'LOW';
            }else  if(this.feature.ag_score > 5 && 
                      this.feature.ag_score <= 7){
              return 'MEDIUM';
            }else  if(this.feature.ag_score > 7){
              return 'HIGH';
            }else{
              return 'NO';
            }
          },

          /**
           * Computed variable.  Returns relative ecological model ranking.
           * @returns {text}
           */
          relative_eco_priority: function(){

            if(this.feature.eco_score <= 2 ){
              return 'LOW';
            }else  if(this.feature.eco_score <= 4 ){
              return 'MEDIUM-LOW';
            }else  if(this.feature.eco_score <= 6 ){
              return 'MEDIUM';
            }else  if(this.feature.eco_score <= 8 ){
              return 'MEDIUM-HIGH';
            }else  if(this.feature.eco_score <= 10 ){
              return 'HIGH';
            }else{
              return '';
            }
          },

          /**
           * Computed variable.  Returns relative water model ranking.
           * @returns {text}
           */
          relative_wa_priority: function(){

            if(this.feature.wa_score <= 2 ){
              return 'LOW';
            }else  if(this.feature.wa_score <= 4 ){
              return 'MEDIUM-LOW';
            }else  if(this.feature.wa_score <= 6 ){
              return 'MEDIUM';
            }else  if(this.feature.wa_score <= 8 ){
              return 'MEDIUM-HIGH';
            }else  if(this.feature.wa_score <= 10 ){
              return 'HIGH';
            }else{
              return '';
            }
          },
        },
        
        data(){
            return{
                bookmark_add_svg,
                bookmark_add_hover_svg,
                blueprint_logo_footer_svg,
                bookmark_remove_svg,
                bookmark_remove_hover_svg,
                data_ready: false,
                delete_svg,
                delete_hover_svg,
                feature: {},
                filtered_component_data:{
                    'ag': {},
                    'eco': {},
                    'wa': {}
                },
                ids: {
                    3: 'ag',
                    4: 'eco',
                    5: 'wa'
                },
                include_ag_threshold_footnote: false,
                link: '',
                max_scores:{
                  'chanj_core_score': 10,
                  'chanj_corridors_score': 10,
                  'chanj_stepping_stones_score': 10,
                  'coastal_resilience_score': 5,
                  'combined_resilience_score':0,
                  'final_chanj_score': 10,
                  'floodplain_score': 2,
                  'forest_core_score': 3,
                  'groundwater_recharge_score': 1,
                  'habitat_conditions_score': 1,
                  'habitat connectivity': 10,
                  'headwaters_score': 2,
                  'impervious_surface_score': 1,
                  'landscape_rank_score': 5,
                  'marsh_migration_score': 2,
                  'normalized_connectivity_score': 10,
                  'normalized_resilience_score': 10,
                  'normalized_rsnc_score': 10,
                  'normalized_water_score': 10,
                  'priority_sites_score': 1,
                  'species_score': 1,
                  'terrestrial_connectivity_score': 2,
                  'terrestrial_core_connectors_score': 5,
                  'terrestrial_resilience_score': 5,
                  'vernal_habitat_score': 1,
                  'wetlands_score': 1
                },
                modiv_data: {},
                modiv_in: false,
                parcel_click: this.parcel_on_click || false,
                parcel_select_text: {
                  true: 'Map Click Selects New Parcel',
                  false: 'Map Click Selects New Parcel',
                },
                print_svg,
                print_hover_svg,
                savable: false,
                saved: false,
                score_ids: {
                    3: 'ag_score',
                    4: 'eco_score',
                    5: 'wa_score'
                },
                titles: {
                    3: 'Agriculture Score',
                    4: 'Ecological Score',
                    5: 'Water Score',
                    6: 'Combined Score'
                }, 
                working:false
            }
        },

        methods:{

            appendModivData(){
                if (this.modiv_data){// && !this.feature.hasOwnProperty('modiv')){
                        this.feature['modiv'] = this.modiv_data;
                }
            },

            changeDataDsiplay(e){
                const idx = e.target.attributes["data-idx"].value
                const blocks = document
                                .getElementsByClassName("combined-data-block");
                const menus = document
                                .getElementsByClassName("combined-menu-item");

                for(let i=0; i<blocks.length;i++){
                    if(i == idx-1){
                        blocks[i].classList.remove('hidden');
                        menus[i].classList.add('font-bold');
                        menus[i].classList.add('underline');
                    }else{
                        blocks[i].classList.add('hidden');
                        menus[i].classList.remove('font-bold');
                        menus[i].classList.remove('underline');
                    }
                }
            },
            
            emitCurrentFeature(){
                if(this.feature){
                    if(this.feature.hasOwnProperty('modiv')){
                        this.$emit('data_in', true);
                        this.$emit('current_feature', this.feature);
                    }
                }
            },

            getData(){
                this.reset();
                
                if (this.feature_id == '0') { 
                    return;
                }

                this.working = true;

                const fg = new FeatureGetter();
                const request_obj = {'feature_id': this.feature_id,
                             'centroid': null,
                             'coords': this.info_marker_coords};

                fg.getFeature(request_obj)
                    .then((data)=>{
                        this.feature = data;
                        this.setFeatureCoordinates();
                        this.filterComponentData();
                        this.appendModivData();
                        this.emitCurrentFeature();
                        this.working = false;
                    });
            },
            
            filterComponentData() {
                const removes = ['score', 'total_parcel_acreage'];
                const model_ids = Object.values(this.ids);

                let data =  Object.assign({}, this.feature.response_data);

                for(const id of model_ids){
                    if(id === 'com'){
                        continue;
                    }
                    const id_data = data[id];

                    for (const item in id_data) {
                        if(removes.includes(item) || item.includes('raw')){
                            delete id_data[item];
                        }
                        if(item.includes('threshold')){
                          if(id_data[item].includes('qualified')){
                            id_data[item] = 'Yes*';
                            this.include_ag_threshold_footnote = true;
                          }
                        }
                    }
                    this.filtered_component_data[id] = id_data;
                }
            },

            printFeature(){
                this.$emit('print', this.feature);
            },

            prettifyString(str, keep_score=false){
                let s =  str;
                
                if(isNaN(s)){
                    s = s.toLowerCase()
                    .split('_')
                    .map(word => word.charAt(0).toUpperCase() + word.substring(1))
                    .join(' ')
                    .replace('Rsnc', 'RSNC')
                    .replace('Chanj', 'CHANJ');

                    if(!keep_score){
                        s.replace('Score','')
                    }
                }else{
                    s = Number(s).toLocaleString();
                }
                return s;    
            },

            removeTarget(){
                this.$emit('close');
                this.reset();
            },

            reset(){
                this.feature = {},
                this.filtered_component_data = {
                    'ag': {},
                    'eco': {},
                    'wa': {}
                },
                this.modiv_data = {};
                this.modiv_in = false;
                this.data_ready = false;
                this.link = '';
                this.savable = false;
                this.saved = false;
            },

            restoreFeatureData(){
                this.reset();
                this.feature = this.restore_feature_data;
                this.setFeatureCoordinates();
                this.filterComponentData();
                this.saved = true;
                this.$emit('data_restored', null);
                this.$emit('data_in', true);
            },

            saveFeature(){
                this.saved = !this.saved;
                if(this.saved){
                    this.$emit('save_feature', this.feature);
                }else{
                    this.$emit('unsave_feature', this.feature);
                }
            },

            setFeatureCoordinates(){
                if(this.feature.coordinates){
                    return;
                }
                this.feature.coordinates = this.info_marker_coords;
            },

            setSavability(){
                this.savable = !this.saved;
            },

            swapImage(element,src){
              element.src = src;
            },
            toggleParcelStatus(){
              this.parcel_click = !this.parcel_click;
              this.updateParcelClick();
            },
            
            updateModivData(data){
                this.modiv_data = data
            },
            
            updateModivStatus(status){
                this.modiv_in = status;
            },

            updateParcelClick(){
            this.$emit('parcel_on_click', this.parcel_click);
            },
        },

        mounted(){
            this.getData();
        },
    }

</script>